import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '@mui/material';

import { useIsAuth } from '../../core/hooks/useIsAuth';

import { IconApp } from '../../shared/components/IconApp/IconApp';
import { getPlayerAsync } from '../../core/store/slices/players/getPlayerAsync';

import styles from './Home.module.scss';
import {
    getIsPlayerLoadingSelector,
    getPlayerSelector,
} from '../../core/store/slices/players/selectors';
import { getSettingsAsync } from '../../core/store/slices/settings/getSettingsAsync';
import { Loader } from '../../shared/components/loader/Loader';
import { isSuperAdminSelector } from '../../core/store/slices/auth/selectors';
import ReceiptIcon from '@mui/icons-material/Receipt';

export const Home = () => {
    const { isAuth } = useIsAuth();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const isPlayerLoading = useSelector(getIsPlayerLoadingSelector);
    const currentPlayer = useSelector(getPlayerSelector);
    const isSuperAdmin = useSelector(isSuperAdminSelector);

    useEffect(() => {
        dispatch(getSettingsAsync());
    }, [dispatch]);

    useEffect(() => {
        if (isAuth) {
            dispatch(getPlayerAsync());
        }
    }, [isAuth, dispatch]);

    return isPlayerLoading ? (
        <Loader></Loader>
    ) : (
        <section className={styles.Home}>
            <h1 className={styles.title}>BIENVENIDO</h1>

            {isAuth && currentPlayer && (
                <h2 className={styles.player}>{currentPlayer[0].firstName}</h2>
            )}

            <h3 className={styles.subtitle}>LISTO PARA JUGAR?</h3>

            <div className={styles.menu}>
                {!isAuth && (
                    <Button
                        className={styles.registerMobile}
                        color='secondary'
                        variant='contained'
                        onClick={() => navigate('/auth')}
                    >
                        REGISTRARSE - INICIAR SESION
                    </Button>
                )}

                <Button
                    className='primary-button-gradient'
                    variant='contained'
                    onClick={() => navigate('/partidos')}
                >
                    PARTIDOS UN TOQUE
                    <IconApp
                        className={styles.iconButton}
                        iconName='partidosuntoque'
                        width='25'
                        height='25'
                        alt='Icono de partidos un toque'
                    />
                </Button>

                <Button
                    className='primary-button-gradient'
                    variant='contained'
                    onClick={() => navigate('/amigos')}
                    disabled={!isAuth}
                >
                    AMIGOS
                    <IconApp
                        className={styles.iconButton}
                        iconName='amigos'
                        width='25'
                        height='25'
                        alt='Icono de amigos'
                    />
                </Button>

                <Button
                    className='primary-button-gradient'
                    variant='contained'
                    onClick={() => navigate('/mis-partidos')}
                    disabled={!isAuth}
                >
                    MIS PARTIDOS
                    <IconApp
                        className={styles.iconButton}
                        iconName='mispartidos'
                        width='25'
                        height='25'
                        alt='Icono de mis partidos'
                    />
                </Button>

                <Button
                    className='primary-button-gradient'
                    variant='contained'
                    onClick={() => navigate('/sedes')}
                >
                    INSTALACIONES
                    <IconApp
                        className={styles.iconButton}
                        iconName='sedes'
                        width='25'
                        height='25'
                        alt='Icono de sedes'
                    />
                </Button>

                {isSuperAdmin && (
                    <Button
                        className='primary-button-gradient'
                        variant='contained'
                        onClick={() => navigate('/comprobantes')}
                    >
                        Comprobantes
                        <ReceiptIcon sx={{ fontSize: 18 }} />
                    </Button>
                )}
            </div>

            {!isAuth && (
                <Button
                    className={styles.registerButton}
                    color='secondary'
                    variant='contained'
                    onClick={() => navigate('/auth')}
                >
                    REGISTRARSE - INICIAR SESION
                </Button>
            )}
        </section>
    );
};
