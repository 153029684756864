import { Dialog } from '@mui/material';
import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { resetUpdateStatsStatus } from '../../../core/store/slices/matches';
import { getMatchStatsByPlayerAsync } from '../../../core/store/slices/matches/getMatchStatsByPlayerAsyc';
import { getMyMatchesAsync } from '../../../core/store/slices/matches/getMyMatchesAsync';
import {
    isLoadingMatchesSelector,
    isLoadingStatsSelector,
    matchStatsSelector,
    myMatchesSelector,
    totalMyMatchesSelector,
    updateStatsStatusSelector,
} from '../../../core/store/slices/matches/selectors';
import { InfoModal } from '../../../shared/components/InfoModal/InfoModal';
import { Loader } from '../../../shared/components/loader/Loader';
import { PagesHeader } from '../../../shared/components/PagesHeader/PagesHeader';
import { MyMatch } from '../MyMatch/MyMatch';
import styles from './ListMyMatches.module.scss';

export const ListMyMatches = () => {
    const dispatch = useDispatch();

    const isLoading = useSelector(isLoadingMatchesSelector);
    const matches = useSelector(myMatchesSelector);
    const totalMatches = useSelector(totalMyMatchesSelector);
    const updateStatsStatus = useSelector(updateStatsStatusSelector);
    const isLoadingStats = useSelector(isLoadingStatsSelector);
    const stats = useSelector(matchStatsSelector);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isError, setIsError] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize] = useState(5);

    useEffect(() => {
        dispatch(getMyMatchesAsync({ pageSize, currentPage }));
    }, [dispatch, pageSize, currentPage]);

    useEffect(() => {
        dispatch(getMatchStatsByPlayerAsync());
    }, [dispatch]);

    useEffect(() => {
        if (updateStatsStatus === 200) {
            dispatch(getMatchStatsByPlayerAsync());
            setIsModalOpen(true);
            return;
        }

        if (
            updateStatsStatus &&
            updateStatsStatus !== 200 &&
            updateStatsStatus !== 401
        ) {
            setIsError(true);
            setIsModalOpen(true);
            return;
        }
    }, [updateStatsStatus, setIsModalOpen, dispatch]);

    const handleLoadMore = () => {
        setCurrentPage(currentPage + 1);
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
        dispatch(resetUpdateStatsStatus());
    };

    return isLoadingStats ? (
        <Loader></Loader>
    ) : (
        <section className={styles.ListMyMatches}>
            <h2 className={styles.subtitle}>MIS</h2>
            <PagesHeader>
                <h1>Partidos</h1>
            </PagesHeader>

            <section className={styles.container}>
                <div id='myMatchesList' className={styles.matchesContainer}>
                    <InfiniteScroll
                        dataLength={matches.length}
                        next={handleLoadMore}
                        hasMore={matches.length < totalMatches}
                        scrollableTarget='myMatchesList'
                        loader={
                            <div className={styles.loadingSmall}>
                                <Loader size='small'/>
                            </div>
                        }
                    >
                        {isLoading && !matches.length && (
                            <div className={styles.loading}>
                                <Loader />
                            </div>
                        )}

                        {!isLoading && !matches.length && (
                            <div className={styles.emptyData}>
                                <h3>No se encontraron partidos</h3>
                            </div>
                        )}

                        {matches.map((match, index) => (
                            <MyMatch
                                key={`${match.matchId}/${index}`}
                                id={match.matchId}
                                locationImg={match.locationImage}
                                locationName={match.locationName}
                                score={match.matchResult}
                                date={match.matchDate}
                                stats={stats?.find(
                                    (s) => s.matchId === match.matchId
                                )}
                            ></MyMatch>
                        ))}
                    </InfiniteScroll>
                </div>
            </section>

            <Dialog fullScreen open={isModalOpen} onClose={handleModalClose}>
                {isError && (
                    <InfoModal
                        title='Oh no!'
                        description='Hubo un error, por favor intenta mas tarde'
                        closeModal={handleModalClose}
                    />
                )}

                {!isError && (
                    <InfoModal
                        title='LISTO!'
                        description='Tus estadísticas se actualizaron correctamente'
                        closeModal={handleModalClose}
                    />
                )}
            </Dialog>
        </section>
    );
};
