import { createAsyncThunk } from '@reduxjs/toolkit';
import { deleteField } from '../../../services/fieldService';

export const deleteFieldAsync = createAsyncThunk(
    'fields/delete',
    async (payload) => {
        try {
            const { fieldId } = payload;
            const res = await deleteField(fieldId);

            return res.status;
        } catch (error) {
            return error?.response?.status || 500;
        }
    }
);
