import { axiosClient } from './axiosInstance';

export const loginUser = ({ email, password }) => {
    const url = `/Login/LogInUser?email=${email}&password=${password}`;

    return axiosClient.post(url);
};

export const getExternalLoginData = () => {
    const url = `/ExternalLogin/GetInfo`;

    return axiosClient.get(url);
};

export const facebookLogin = (token) => {
    const url = `/ExternalLogin/Facebook/${token}`;

    return axiosClient.get(url);
};

export const googleLogin = (code, redirectUrl) => {
    const url = `/ExternalLogin/Google?code=${code}&redirectUrl=${redirectUrl}`;

    return axiosClient.get(url);
};
