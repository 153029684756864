import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { PagesHeader } from '../../../shared/components/PagesHeader/PagesHeader';

import {
    isLoadingMatchesSelector,
    matchesSelector,
    totalMatchesSelector,
    updateScoreStatusSelector,
    cancelStatusSelector,
} from '../../../core/store/slices/matches/selectors';
import { getMatchesAsync } from '../../../core/store/slices/matches/getMatchesAsync';

import styles from './ListMatches.module.scss';
import {
    Button,
    Dialog,
    FormControl,
    FormControlLabel,
    MenuItem,
    Select,
    Switch,
} from '@mui/material';
import Match from '../Match/Match';
import {
    getMatchGendersSettingsAsync,
    getMatchTypesSettingsAsync,
} from '../../../core/store/slices/settings/getSettingsAsync';
import {
    isLoadingSettingsSelector,
    matchGendersSelector,
    matchTypesSelector,
} from '../../../core/store/slices/settings/selectors';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
    resetCancelStatus,
    resetCurrentMatch,
    resetMatches,
    resetUpdateScoreStatus,
} from '../../../core/store/slices/matches';
import { Loader } from '../../../shared/components/loader/Loader';
import { isSuperAdminSelector } from '../../../core/store/slices/auth/selectors';
import { InfoModal } from '../../../shared/components/InfoModal/InfoModal';

const getValue = (value) =>
    value && value !== 'displayLabel' ? value : undefined;

export const ListMatches = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const isLoadingSettings = useSelector(isLoadingSettingsSelector);
    const isLoadingMatches = useSelector(isLoadingMatchesSelector);
    const matches = useSelector(matchesSelector);
    const totalMatches = useSelector(totalMatchesSelector);
    const matchGenders = useSelector(matchGendersSelector);
    const matchTypes = useSelector(matchTypesSelector);
    const isSuperAdmin = useSelector(isSuperAdminSelector);
    const updateScoreStatus = useSelector(updateScoreStatusSelector);
    const cancelStatus = useSelector(cancelStatusSelector);

    const [genderSelected, setGenderSelected] = useState('displayLabel');
    const [typeSelected, setTypeSelected] = useState('displayLabel');
    const [currentPage, setCurrentPage] = useState(1);
    const [pendingResult, setPendingResult] = useState(false);
    const [pageSize] = useState(10);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isCancel, setIsCancel] = useState(false);

    useEffect(() => {
        const genderId = getValue(genderSelected);
        const matchTypeId = getValue(typeSelected);

        if (currentPage === 1) {
            dispatch(resetMatches());
        }

        dispatch(
            getMatchesAsync({
                pageSize,
                currentPage,
                genderId,
                matchTypeId,
                onlyWithoutResult: pendingResult,
            })
        );
    }, [
        dispatch,
        pageSize,
        currentPage,
        genderSelected,
        typeSelected,
        pendingResult,
    ]);

    useEffect(() => {
        dispatch(resetCurrentMatch());
    }, [dispatch]);

    useEffect(() => {
        if (!matchGenders) {
            dispatch(getMatchGendersSettingsAsync());
        }
    }, [dispatch, matchGenders]);

    useEffect(() => {
        if (!matchTypes) {
            dispatch(getMatchTypesSettingsAsync());
        }
    }, [dispatch, matchTypes]);

    useEffect(() => {
        if (updateScoreStatus === 200) {
            const genderId = getValue(genderSelected);
            const matchTypeId = getValue(typeSelected);

            if (currentPage > 1) {
                setCurrentPage(1);
            } else {
                dispatch(
                    getMatchesAsync({
                        pageSize,
                        currentPage,
                        genderId,
                        matchTypeId,
                        onlyWithoutResult: pendingResult,
                    })
                );
            }

            setIsModalOpen(true);
        }

        if (
            updateScoreStatus &&
            updateScoreStatus !== 200 &&
            updateScoreStatus !== 401
        ) {
            setIsError(true);
            setIsModalOpen(true);
        }
    }, [
        updateScoreStatus,
        genderSelected,
        typeSelected,
        pageSize,
        currentPage,
        pendingResult,
        dispatch,
    ]);

    useEffect(() => {
        if (cancelStatus && cancelStatus === 200) {
            const genderId = getValue(genderSelected);
            const matchTypeId = getValue(typeSelected);

            if (currentPage > 1) {
                setCurrentPage(1);
            } else {
                dispatch(
                    getMatchesAsync({
                        pageSize,
                        currentPage,
                        genderId,
                        matchTypeId,
                        onlyWithoutResult: pendingResult,
                    })
                );
            }

            setIsCancel(true);
            setIsModalOpen(true);
        }

        if (cancelStatus && cancelStatus !== 200 && cancelStatus !== 401) {
            setIsError(true);
            setIsModalOpen(true);
        }
    }, [
        cancelStatus,
        genderSelected,
        typeSelected,
        pageSize,
        currentPage,
        pendingResult,
        dispatch,
    ]);

    const handleGenderChange = (event) => {
        const value = getValue(event.target.value);

        if (!value) {
            setGenderSelected('displayLabel');
        } else {
            setGenderSelected(value);
        }

        setCurrentPage(1);
    };

    const handleTypeChange = (event) => {
        const value = getValue(event.target.value);

        if (!value) {
            setTypeSelected('displayLabel');
        } else {
            setTypeSelected(value);
        }

        setCurrentPage(1);
    };

    const handleLoadMore = () => {
        setCurrentPage(currentPage + 1);
    };

    const handleModalClose = () => {
        setIsError(false);
        setIsCancel(false);
        setIsModalOpen(false);

        if (updateScoreStatus) {
            dispatch(resetUpdateScoreStatus());
        }

        if (cancelStatus) {
            dispatch(resetCancelStatus());
        }
    };

    const handlePendingResultChange = (event) => {
        setCurrentPage(1);
        setPendingResult(event?.target?.checked);
    };

    return isLoadingSettings ? (
        <Loader></Loader>
    ) : (
        <section className={styles.ListMatches}>
            <p>Partidos</p>

            <PagesHeader>
                <h1>UN TOQUE</h1>
            </PagesHeader>

            <section className={styles.container}>
                {isSuperAdmin && (
                    <div className={styles.fieldGroup}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={pendingResult}
                                    onChange={handlePendingResultChange}
                                />
                            }
                            label='Pendientes por resultado'
                            labelPlacement='start'
                        />
                    </div>
                )}

                <div className={styles.fieldGroup}>
                    <FormControl className={styles.item}>
                        <Select
                            onChange={handleGenderChange}
                            value={genderSelected}
                        >
                            <MenuItem
                                value='displayLabel'
                                sx={{ display: 'none' }}
                            >
                                Genero
                            </MenuItem>
                            {matchGenders &&
                                matchGenders.map((gender, index) => (
                                    <MenuItem
                                        key={`${gender.id}/${index}`}
                                        value={gender.id}
                                    >
                                        {gender.name}
                                    </MenuItem>
                                ))}
                            <MenuItem value=''>Todos</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl className={styles.item}>
                        <Select
                            onChange={handleTypeChange}
                            value={typeSelected}
                        >
                            <MenuItem
                                value='displayLabel'
                                sx={{ display: 'none' }}
                            >
                                Tipo
                            </MenuItem>
                            {matchTypes &&
                                matchTypes.map((type, index) => (
                                    <MenuItem
                                        key={`${type.id}/${index}`}
                                        value={type.id}
                                    >
                                        {type.name}
                                    </MenuItem>
                                ))}
                            <MenuItem value=''>Todos</MenuItem>
                        </Select>
                    </FormControl>
                </div>

                <div className={styles.matchesHeader}>
                    <span>Lugar</span>
                    <span>Fecha y hora</span>
                    <span>jugadores</span>
                </div>

                <div
                    id='matchListScroll'
                    className={`${styles.matchesContainer} ${
                        isSuperAdmin ? styles.matchesContainerAdmin : ''
                    }`}
                >
                    <InfiniteScroll
                        dataLength={matches.length}
                        next={handleLoadMore}
                        hasMore={matches.length < totalMatches}
                        scrollableTarget='matchListScroll'
                        loader={
                            matches.length && (
                                <div className={styles.loadingSmall}>
                                    <Loader size='small' />
                                </div>
                            )
                        }
                    >
                        {isLoadingMatches && !matches.length && (
                            <div className={styles.loading}>
                                <Loader />
                            </div>
                        )}

                        {!matches.length && !isLoadingMatches && (
                            <div className={styles.notMatchesFound}>
                                <h3>No se encontraron partidos</h3>
                            </div>
                        )}

                        {matches.map((match, index) => (
                            <Match
                                key={`${match.matchId}/${index}`}
                                data={match}
                                isAdminMatch={isSuperAdmin}
                            ></Match>
                        ))}
                    </InfiniteScroll>
                </div>

                {isSuperAdmin && (
                    <div className={styles.actions}>
                        <Button
                            variant='contained'
                            className='primary-button-gradient'
                            fullWidth
                            onClick={() => navigate('/partidos/crear')}
                        >
                            Nuevo Partido
                        </Button>
                    </div>
                )}
            </section>

            {isSuperAdmin && (
                <Dialog
                    fullScreen
                    open={isModalOpen}
                    onClose={handleModalClose}
                >
                    {isError && (
                        <InfoModal
                            title='Oh no!'
                            description='Hubo un error, por favor intenta mas tarde'
                            closeModal={handleModalClose}
                        />
                    )}

                    {!isError && isCancel && (
                        <InfoModal
                            title='LISTO!'
                            description='El partido fue cancelado correctamente'
                            closeModal={handleModalClose}
                        />
                    )}

                    {!isError && !isCancel && (
                        <InfoModal
                            title='LISTO!'
                            description='El marcador se actualizó correctamente'
                            closeModal={handleModalClose}
                        />
                    )}
                </Dialog>
            )}
        </section>
    );
};
