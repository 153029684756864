import { createAsyncThunk } from '@reduxjs/toolkit';
import { getFields, getFieldsList } from '../../../services/fieldService';

export const getFieldsAsync = createAsyncThunk(
    'fields/getFields',
    async (payload) => {
        const { locationId, currentPage, pageSize } = payload;
        const res = await getFields(locationId, currentPage, pageSize);
        const data = await res.data;

        return data;
    }
);

export const getFieldsListAsync = createAsyncThunk(
    'fields/getFieldsList',
    async (locationId) => {
        const res = await getFieldsList(locationId);
        const data = await res.data;

        return data;
    }
);
