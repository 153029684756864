import { createAsyncThunk } from "@reduxjs/toolkit";

import { getSettingById } from "../../../services/settingsService";
import { registerUser } from "../../../services/registerService";

export const registerUserAsync = createAsyncThunk(
    "auth/registerUser",
    async (payload) => {
        const res = await registerUser(payload);

        return res.status;
    }
);

export const getRegisterSettings = createAsyncThunk(
    "auth/registerSettings",
    async () => {
        const settings = await Promise.all([getSettingById(1), getSettingById(2)]);

        return {
            genders: settings[0].data,
            positions: settings[1].data,
        };
    }
);
