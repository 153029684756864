export const initialState = {
    allMatches: [],
    myMatches: [],
    totalMatches: null,
    totalMyMatches: null,
    currentMatch: null,
    isLoading: false,
    isLoadingStats: false,
    joinStatus: null,
    exitStatus: null,
    updateStatsStatus: null,
    updateScoreStatus: null,
    cancelStatus: null,
    createStatus: null,
    updateStatus: null,
    currentStats: null,
};
