import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Counter } from '../../../shared/components/counter/Counter';

export const UpdateStatsDialog = ({ isOpen, onClose, onSave, stats }) => {
    const [goals, setGoals] = useState(0);
    const [assists, setAssists] = useState(0);

    useEffect(() => {
        if (stats && stats.goals !== null) {
            setGoals(stats.goals);
        }

        if (stats && stats.assists !== null) {
            setAssists(stats.assists);
        }
    }, [stats]);

    const handleClose = () => {
        if (onClose) onClose();
    };
    const handleSave = () => {
        if (onSave) onSave(goals, assists);
    };

    return (
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>Mis estadisticas del partido</DialogTitle>
            <DialogContent>
                <Counter
                    label='Goles'
                    value={goals}
                    readonly={stats?.goals !== null}
                    onChange={(value) => setGoals(value)}
                ></Counter>
                <Counter
                    label='Asistencias'
                    value={assists}
                    readonly={stats?.assists !== null}
                    onChange={(value) => setAssists(value)}
                ></Counter>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cerrar</Button>
                {stats?.goals === null && stats?.assists === null && (
                    <Button onClick={handleSave} autoFocus>
                        Guardar
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};
