import { createSlice } from "@reduxjs/toolkit";

import { initialState } from "./initialState";
import { getHeadquartersAsync } from "./getHeadquartersAsync";
import { getHeadquartersDetailAsync } from "./getHeadquartersDetailAsync";
import { createHeadquarterAsync } from './createHeadquarterAsync';
import { updateHeadquarterAsync } from './updateHeadquarterAsync';

export const headquartersSlice = createSlice({
    name: "headquarters",
    initialState,
    reducers: {
        resetCreateStatus: (state) => {
            state.createStatus = null;
        },
        resetUpdateStatus: (state) => {
            state.updateStatus = null;
        },
        resetCurrentHeadquarter: (state) => {
            state.currentHeaquarter = null;
        }
    },
    extraReducers: {
        [getHeadquartersAsync.pending]: (state) => {
            state.isLoading = true;
        },
        [getHeadquartersAsync.fulfilled]: (state, action) => {
            state.allHeadquarters = action.payload;
            state.isLoading = false;
        },
        [getHeadquartersAsync.rejected]: (state) => {
            state.isLoading = false;
        },
        //Detail
        [getHeadquartersDetailAsync.pending]: (state) => {
            state.isLoading = true;
        },
        [getHeadquartersDetailAsync.fulfilled]: (state, action) => {
            state.currentHeaquarter = action.payload;
            state.isLoading = false;
        },
        [getHeadquartersDetailAsync.rejected]: (state) => {
            state.isLoading = false;
        },
        // Create
        [createHeadquarterAsync.pending]: (state) => {
            state.isCreating = true;
        },
        [createHeadquarterAsync.fulfilled]: (state, action) => {
            state.createStatus = action.payload;
            state.isCreating = false;
        },
        [createHeadquarterAsync.rejected]: (state, action) => {
            state.createStatus = action.payload;
            state.isCreating = false;
        },
        // Upodate
        [updateHeadquarterAsync.pending]: (state) => {
            state.isUpdating = true;
        },
        [updateHeadquarterAsync.fulfilled]: (state, action) => {
            state.updateStatus = action.payload;
            state.isUpdating = false;
        },
        [updateHeadquarterAsync.rejected]: (state, action) => {
            state.updateStatus = action.payload;
            state.isUpdating = false;
        },
    },
});

export const { resetCreateStatus, resetUpdateStatus, resetCurrentHeadquarter } = headquartersSlice.actions;

export default headquartersSlice.reducer;
