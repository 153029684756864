import { createSlice } from '@reduxjs/toolkit';

import { initialState } from './initialState';
import { addFavoriteFriendAsync, getFriendsAsync, removeFavoriteFriendAsync } from './friendsAsync';

export const friendsSlice = createSlice({
    name: 'friends',
    initialState,
    reducers: {
        resetFriends: (state) => {
            state.allFriends = [];
        }
    },
    extraReducers: {
        [getFriendsAsync.pending]: (state) => {
            state.isLoading = true;
        },
        [getFriendsAsync.fulfilled]: (state, action) => {
            if (action.payload.currentPage === 1) {
                state.allFriends = action.payload.items;
            } else {
                state.allFriends = [...state.allFriends, ...action.payload.items];
            }
            state.totalFriends = action.payload.totalItems;
            state.isLoading = false;
        },
        [getFriendsAsync.rejected]: (state) => {
            state.isLoading = false;
        },
        // Add Favorite
        [addFavoriteFriendAsync.pending]: (state) => {
            state.isUpdatingFavorite = true;
        },
        [addFavoriteFriendAsync.fulfilled]: (state, action) => {
            const { playerId } = action.payload;

            state.allFriends.find((f) => f.playerId === playerId).isFavoriteFriend = true;
            state.isUpdatingFavorite = false;
        },
        [addFavoriteFriendAsync.rejected]: (state, action) => {
            state.isUpdatingFavorite = false;
        },
        // Remove Favorite
        [removeFavoriteFriendAsync.pending]: (state) => {
            state.isUpdatingFavorite = true;
        },
        [removeFavoriteFriendAsync.fulfilled]: (state, action) => {
            const { playerId } = action.payload;

            state.allFriends.find((f) => f.playerId === playerId).isFavoriteFriend = false;
            state.isUpdatingFavorite = false;
        },
        [removeFavoriteFriendAsync.rejected]: (state, action) => {
            state.isUpdatingFavorite = false;
        },
    },
});

export const { resetFriends } = friendsSlice.actions;

export default friendsSlice.reducer;
