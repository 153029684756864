import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { PagesHeader } from '../../../shared/components/PagesHeader/PagesHeader';
import styles from './Payment.module.scss';
import { exitMatchAsync } from '../../../core/store/slices/matches/exitMatchAsync';
import { useSelector } from 'react-redux';
import {
    currentMatchSelector,
    exitStatusSelector,
    isLoadingMatchesSelector,
    joinStatusSelector,
} from '../../../core/store/slices/matches/selectors';
import {
    resetExitStatus,
    resetJoinStatus,
} from '../../../core/store/slices/matches';
import { InfoModal } from '../../../shared/components/InfoModal/InfoModal';
import { currencyFormat } from '../../../shared/utils/utils';
import { ImageUpload } from '../../../shared/components/ImageUpload/ImageUpload';
import { uploadReceiptAsync } from '../../../core/store/slices/receipts/uploadReceiptAsync';
import { uploadStatusSelector } from '../../../core/store/slices/receipts/selectors';
import { joinMatchAsync } from '../../../core/store/slices/matches/joinMatchAsync';
import { resetUploadStatus } from '../../../core/store/slices/receipts';
import { getMatchDetailsAsync } from '../../../core/store/slices/matches/getMatchDetailAsync';
import { Loader } from '../../../shared/components/loader/Loader';

export function Payment() {
    const { id } = useParams();
    const { state } = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const imageUploadRef = useRef(null);

    const [receiptFile, setReceiptFile] = useState(null);
    const [isPaymentView, setIsPaymentView] = useState(true);
    const [isError, setIsError] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isReceiptModalOpen, setReceiptModalOpen] = useState(false);

    const isLoadingMatch = useSelector(isLoadingMatchesSelector);
    const exitStatus = useSelector(exitStatusSelector);
    const currentMatch = useSelector(currentMatchSelector);
    const uploadStatus = useSelector(uploadStatusSelector);
    const joinStatus = useSelector(joinStatusSelector);

    useEffect(() => {
        dispatch(getMatchDetailsAsync(id));
    }, [dispatch, id]);

    useEffect(() => {
        const { isCancel } = state;

        setIsPaymentView(!isCancel);
    }, [state]);

    useEffect(() => {
        const { isCancel, matchId } = state;

        if (exitStatus) {
            dispatch(resetExitStatus());
        }

        if (isCancel && exitStatus === 200) {
            navigate('/partidos/detalles/' + matchId, {
                state: { blockReturn: true },
            });
        }

        if (isCancel && exitStatus !== null && exitStatus !== 200) {
            setIsError(true);
            setIsModalOpen(true);
        }
    }, [exitStatus, state, navigate, dispatch]);

    useEffect(() => {
        if (uploadStatus) {
            dispatch(resetUploadStatus());
        }

        if (uploadStatus === 200) {
            const { matchId, isOpenMatch, selectedTeam } = state;

            dispatch(
                joinMatchAsync({
                    matchId,
                    team: isOpenMatch ? 0 : selectedTeam,
                })
            );
        }

        if (uploadStatus !== null && uploadStatus !== 200) {
            setIsError(true);
            setIsModalOpen(true);
        }
    }, [uploadStatus, state, dispatch]);

    useEffect(() => {
        if (joinStatus) {
            dispatch(resetJoinStatus());
        }

        if (joinStatus === 200) {
            setIsModalOpen(true);
        }

        if (joinStatus !== null && joinStatus !== 200) {
            setIsError(true);
            setIsModalOpen(true);
        }
    }, [dispatch, joinStatus]);

    const handleSendReceiptClick = ([file]) => {
        setReceiptFile(file);
        setReceiptModalOpen(true);
    };

    const handleCancelClick = (event) => {
        const { matchId } = state;

        event.preventDefault();
        dispatch(exitMatchAsync({ matchId }));
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
    };

    const handleReceiptModalClose = () => {
        imageUploadRef.current.value = null;
        setReceiptModalOpen(false);
    };

    const handleSendReceipt = () => {
        dispatch(
            uploadReceiptAsync({
                matchId: currentMatch.matchId,
                locationId: currentMatch.locationId,
                dateReceipt: new Date().toISOString(),
                file: receiptFile,
            })
        );
    };

    return isLoadingMatch ? (
        <Loader></Loader>
    ) : (
        <section className={styles.payment}>
            <PagesHeader className={styles.header}>
                <h1 className={styles.title}>
                    {isPaymentView ? 'PAGAR PARTIDO' : 'cancelar partido'}
                </h1>
            </PagesHeader>

            {isPaymentView ? (
                <div className={styles.content}>
                    <img
                        className={styles.qrImage}
                        src='/assets/images/qr.png'
                        alt='Codigo QR'
                    />

                    <div className={styles.bankInfo}>
                        <span>CUENTA AHORROS BANCOLOMBIA</span>
                        <span>26728844166</span>
                        <span>CC 1037642382</span>
                    </div>

                    <div className={styles.box}>
                        <span>Valor:</span>
                        <span>
                            {currentMatch?.costPerPerson &&
                                currencyFormat(currentMatch?.costPerPerson)}
                        </span>
                    </div>
                </div>
            ) : (
                <div className={styles.content}>
                    <p>Seguro que deseas salir de este partido?</p>
                    <p>
                        Esto puede llevar un costo,{' '}
                        <a
                            href='https://www.google.com'
                            target='_blank'
                            rel='noreferrer'
                        >
                            Lee nuestros terminos y condiciones
                        </a>
                    </p>
                </div>
            )}

            {isPaymentView && (
                <ImageUpload
                    ref={imageUploadRef}
                    customButton
                    handleImagesChange={handleSendReceiptClick}
                    className={styles.button}
                >
                    Subir comprobante
                </ImageUpload>
            )}

            {!isPaymentView && (
                <Button
                    color='primary'
                    variant='contained'
                    fullWidth
                    className={styles.button}
                    onClick={handleCancelClick}
                >
                    Salir del partido
                </Button>
            )}

            <Dialog fullScreen open={isModalOpen} onClose={handleModalClose}>
                {isError && (
                    <InfoModal
                        title='Oh no!'
                        description='Hubo un error, por favor intenta mas tarde'
                        closeModal={handleModalClose}
                    />
                )}
                {!isError && (
                    <InfoModal
                        title='Listo!'
                        description='Tu reserva se realizó correctamente'
                        closeModal={() =>
                            navigate(
                                `/partidos/detalles/${currentMatch.matchId}`,
                                { state: { blockReturn: true } }
                            )
                        }
                    />
                )}
            </Dialog>

            <Dialog open={isReceiptModalOpen}>
                <DialogTitle sx={{textAlign: 'center'}}>Subir Comprobante</DialogTitle>
                <DialogContent>
                    <img
                        className={styles.receiptImage}
                        src={
                            receiptFile ? URL.createObjectURL(receiptFile) : ''
                        }
                        alt=''
                    ></img>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleReceiptModalClose}>Cancelar</Button>
                    <Button autoFocus onClick={handleSendReceipt}>
                        Enviar
                    </Button>
                </DialogActions>
            </Dialog>
        </section>
    );
}
