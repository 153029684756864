import { createAsyncThunk } from '@reduxjs/toolkit';
import { approveReceipt } from '../../../services/receiptsService';

export const approveReceiptAsync = createAsyncThunk(
    'receipt/Approve',
    async (payload) => {
        const { receiptId } = payload;
        const res = await approveReceipt(receiptId);

        return res.status;
    }
);
