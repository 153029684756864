import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';

import { ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';

import { AppRouter } from './app/App.router';
import { store } from './app/core/store';
import { theme } from './app/core/material-theme';
import { setupAxiosInterceptors } from './app/core/services/axiosInstance';

import './index.scss';

setupAxiosInterceptors(store);

ReactDOM.render(
    <React.StrictMode>
        {/*  CssBaseline is coming from MUI And it's used to do css resets, something like normilize.css */}
        <CssBaseline />

        {/* Redux provider for the entire app */}
        <Provider store={store}>
            {/* Theme provider for MUI */}
            <ThemeProvider theme={theme}>
                <AppRouter />
            </ThemeProvider>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);
