import { createAsyncThunk } from '@reduxjs/toolkit';
import { uploadReceipt } from '../../../services/receiptsService';

export const uploadReceiptAsync = createAsyncThunk(
    'receipts/upload',
    async (payload) => {
        const { matchId, locationId, dateReceipt, file } = payload;
        const res = await uploadReceipt(matchId, locationId, dateReceipt, file);

        return res.status;
    }
);
