import { createSlice } from '@reduxjs/toolkit';

import { initialState } from './initialState';
import { getMatchesAsync } from './getMatchesAsync';
import { getMatchDetailsAsync } from './getMatchDetailAsync';
import { joinMatchAsync } from './joinMatchAsync';
import { exitMatchAsync } from './exitMatchAsync';
import { getMyMatchesAsync } from './getMyMatchesAsync';
import { updateMatchStatsAsync } from './updateMatchStatsAsync';
import { updateMatchScoreAsync } from './updateMatchScoreAsync';
import { cancelMatchAsync } from './cancelMatchAsync';
import { createMatchAsync } from './createMatchAsync';
import { updateMatchAsync } from './updateMatchAsync';
import { getMatchByIdAsync } from './getMatchByIdAsync';
import { getMatchStatsByPlayerAsync } from './getMatchStatsByPlayerAsyc';

export const matchesSlice = createSlice({
    name: 'matches',
    initialState,
    reducers: {
        resetMatches: (state) => {
            state.allMatches = [];
        },
        resetCurrentMatch: (state) => {
            state.currentMatch = null;
        },
        resetMyMatches: (state) => {
            state.myMatches = [];
        },
        resetJoinStatus: (state) => {
            state.joinStatus = null;
        },
        resetExitStatus: (state) => {
            state.exitStatus = null;
        },
        resetUpdateStatsStatus: (state) => {
            state.updateStatsStatus = null;
        },
        resetUpdateScoreStatus: (state) => {
            state.updateScoreStatus = null;
        },
        resetCancelStatus: (state) => {
            state.cancelStatus = null;
        },
        resetCreateStatus: (state) => {
            state.createStatus = null;
        },
        resetUpdateStatus: (state) => {
            state.updateStatus = null;
        },
        resetCurrentStats: (state) => {
            state.currentStats = null
        },
    },
    extraReducers: {
        // get matches
        [getMatchesAsync.pending]: (state) => {
            state.isLoading = true;
        },
        [getMatchesAsync.fulfilled]: (state, action) => {
            if (action.payload.currentPage === 1) {
                state.allMatches = action.payload.items;
            } else {
                state.allMatches = [
                    ...state.allMatches,
                    ...action.payload.items,
                ];
            }
            state.totalMatches = action.payload.totalItems;
            state.isLoading = false;
        },
        [getMatchesAsync.rejected]: (state) => {
            state.isLoading = false;
        },
        // get match details
        [getMatchDetailsAsync.pending]: (state) => {
            state.isLoading = true;
        },
        [getMatchDetailsAsync.fulfilled]: (state, action) => {
            state.currentMatch = action.payload;
            state.isLoading = false;
        },
        [getMatchDetailsAsync.rejected]: (state) => {
            state.isLoading = false;
        },
        // get match by id
        [getMatchByIdAsync.pending]: (state) => {
            state.isLoading = true;
        },
        [getMatchByIdAsync.fulfilled]: (state, action) => {
            state.currentMatch = action.payload;
            state.isLoading = false;
        },
        [getMatchByIdAsync.rejected]: (state) => {
            state.isLoading = false;
        },
        // join match
        [joinMatchAsync.pending]: (state) => {
            state.isLoading = true;
        },
        [joinMatchAsync.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.joinStatus = action.payload;
        },
        [joinMatchAsync.rejected]: (state, action) => {
            state.isLoading = false;
            state.joinStatus = action.payload;
        },
        // exit match
        [exitMatchAsync.pending]: (state) => {
            state.isLoading = true;
        },
        [exitMatchAsync.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.exitStatus = action.payload
        },
        [exitMatchAsync.rejected]: (state, action) => {
            state.isLoading = false;
            state.exitStatus = action.payload
        },
        // get my matches
        [getMyMatchesAsync.pending]: (state) => {
            state.isLoading = true;
        },
        [getMyMatchesAsync.fulfilled]: (state, action) => {
            if (action.payload.currentPage === 1) {
                state.myMatches = action.payload.items;
            } else {
                state.myMatches = [
                    ...state.myMatches,
                    ...action.payload.items,
                ];
            }
            state.totalMyMatches = action.payload.totalItems;
            state.isLoading = false;
        },
        [getMyMatchesAsync.rejected]: (state) => {
            state.isLoading = false;
        },
        // update match stats
        [updateMatchStatsAsync.pending]: (state) => {
            state.isLoading = true;
        },
        [updateMatchStatsAsync.fulfilled]: (state, action) => {
            state.updateStatsStatus = action.payload;
            state.isLoading = false;
        },
        [updateMatchStatsAsync.rejected]: (state, action) => {
            state.updateStatsStatus = action.payload;
            state.isLoading = false;
        },
        // update match score
        [updateMatchScoreAsync.pending]: (state) => {
            state.isLoading = true;
        },
        [updateMatchScoreAsync.fulfilled]: (state, action) => {
            state.updateScoreStatus = action.payload;
            state.isLoading = false;
        },
        [updateMatchScoreAsync.rejected]: (state, action) => {
            state.updateScoreStatus = action.payload;
            state.isLoading = false;
        },
        // Cancel match
        [cancelMatchAsync.pending]: (state) => {
            state.isLoading = true;
        },
        [cancelMatchAsync.fulfilled]: (state, action) => {
            state.cancelStatus = action.payload;
            state.isLoading = false;
        },
        [cancelMatchAsync.rejected]: (state, action) => {
            state.cancelStatus = action.payload;
            state.isLoading = false;
        },
        // create match
        [createMatchAsync.pending]: (state) => {
            state.isLoading = true;
        },
        [createMatchAsync.fulfilled]: (state, action) => {
            state.createStatus = action.payload;
            state.isLoading = false;
        },
        [createMatchAsync.rejected]: (state) => {
            state.isLoading = false;
        },
        // update match
        [updateMatchAsync.pending]: (state) => {
            state.isLoading = true;
        },
        [updateMatchAsync.fulfilled]: (state, action) => {
            state.updateStatus = action.payload;
            state.isLoading = false;
        },
        [updateMatchAsync.rejected]: (state) => {
            state.isLoading = false;
        },
        // get match stats
        [getMatchStatsByPlayerAsync.pending]: (state) => {
            state.isLoadingStats = true;
        },
        [getMatchStatsByPlayerAsync.fulfilled]: (state, action) => {
            state.currentStats = action.payload;
            state.isLoadingStats = false;
        },
        [getMatchStatsByPlayerAsync.rejected]: (state) => {
            state.isLoadingStats = false;
        },
    },
});

export const {
    resetMatches,
    resetCurrentMatch,
    resetMyMatches,
    resetJoinStatus,
    resetExitStatus,
    resetUpdateStatsStatus,
    resetUpdateScoreStatus,
    resetCancelStatus,
    resetCreateStatus,
    resetUpdateStatus,
    resetCurrentStats,
} = matchesSlice.actions;

export default matchesSlice.reducer;
