import { axiosClient } from './axiosInstance'

export const getFields = (locationId, currentPage, pageSize) => {
    const url = `/Fields/GetFields`;
    const params = getParams({ locationId, currentPage, pageSize });

    return axiosClient.get(url, { params });
}

export const getFieldsList = (locationId) => {
    const url = `/Fields/GetFieldsList?locationId=${locationId}`;

    return axiosClient.get(url);
}

export const getFieldById = (fieldId) => {
    const url = `/Fields/GetFieldById?fieldId=${fieldId}`;

    return axiosClient.get(url);
}

export const createField = (data) => {
    const url = `/Fields/CreateField`;

    return axiosClient.post(url, data);
}

export const updateField = (data) => {
    const url = `/Fields/UpdateField`;

    return axiosClient.put(url, data);
}

export const deleteField = (fieldId) => {
    const url = `/Fields/DeleteField?fieldId=${fieldId}`;

    return axiosClient.delete(url);
}

export const uploadFieldImage = (fieldId, images) => {
    const url = `/Fields/UploadFieldImagesToContainer?field=${fieldId}`;
    const formData = new FormData();

    images.forEach(image => {
        formData.append('imagesPath', image);
    });

    return axiosClient.post(url, formData);
}

export const deleteFieldImage = (images) => {
    const url = `/Fields/DeleteImageToField`;

    return axiosClient.delete(url, { data: images });
}

function getParams({ pageSize, currentPage, locationId}) {
    const params = {};

    if (pageSize !== undefined) {
        params.pageSize = pageSize;
    }

    if (currentPage !== undefined) {
        params.curPage = currentPage;
    }

    if (locationId !== undefined) {
        params.locationId = locationId;
    }

    return params;
}

