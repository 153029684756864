import { useEffect } from "react";

import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import {
    changeBackground,
    changeToqueMobileDisplayed,
} from "../store/slices/ui";
import {
    getCurrentBackground,
    getToqueMobDisplayed,
} from "../store/slices/ui/selectors";
import globals from "../../shared/constants/global.constant";

export const useUI = () => {
    const background = useSelector(getCurrentBackground);
    const isToqueMobile = useSelector(getToqueMobDisplayed);
    const dispatch = useDispatch();
    const location = useLocation();

    const bgImages = {
        desktop: `url(${globals.imageBasePath}/${background.desktop})`,
        mobile: `url(${globals.imageBasePath}/${background.mobile})`,
    };

    useEffect(() => {
        if (location?.pathname === "/") {
            dispatch(
                changeBackground({
                    desktop: "bg2-desktop.png",
                    mobile: "bg2-mobile.png",
                })
            );

            dispatch(changeToqueMobileDisplayed(true));
        }
    }, [location, dispatch]);

    return { bgImages, isToqueMobile };
};
