import { createSlice } from "@reduxjs/toolkit";

import { initialState } from "./initialState";
import { getSettingByIdAsync, getSettingsAsync } from "./getSettingsAsync";
import globals from "../../../../shared/constants/global.constant";

export const settingsSlice = createSlice({
    name: "settings",
    initialState,
    reducers: {},
    extraReducers: {
        [getSettingsAsync.pending]: (state) => {
            state.isLoading = true;
        },
        [getSettingsAsync.fulfilled]: (state, action) => {
            state.allSettings = action.payload;
            state.isLoading = false;
        },
        [getSettingsAsync.rejected]: (state) => {
            state.isLoading = false;
        },
        [getSettingByIdAsync.pending]: (state) => {
            state.isLoading = true;
        },
        [getSettingByIdAsync().fulfilled]: (state, action) => {
            switch (action.payload.id) {
                case globals.settingsIds.GenderType:
                    state.genders = action.payload.values;
                    break;
                case globals.settingsIds.PositionType:
                    state.positions = action.payload.values;
                    break;
                case globals.settingsIds.RecordType:
                    state.recordTypes = action.payload.values;
                    break;
                case globals.settingsIds.MatchType:
                    state.matchTypes = action.payload.values;
                    break;
                case globals.settingsIds.MatchGender:
                    state.matchGenders = action.payload.values;
                    break;
                default:
                    break;
            }

            state.isLoading = false;
        },
        [getSettingByIdAsync.rejected]: (state) => {
            state.isLoading = false;
        },
    },
});

export default settingsSlice.reducer;
