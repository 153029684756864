export function generateWhatsAppURL(number, text) {
    let url = 'https://api.whatsapp.com/send?';

    if (number) {
        url += `phone=${number}&`;
    }

    if (text) {
        url += `text=${text}`;
    }

    return url;
}

export function capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function arraysEqual(array1, array2) {
    return JSON.stringify(array1) === JSON.stringify(array2);
}

export function currencyFormat(num) {
    return '$' + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}
