import { createAsyncThunk } from '@reduxjs/toolkit';
import { addFavoriteFriend, getFriends, removeFavoriteFriend } from '../../../services/friendsService';

export const getFriendsAsync = createAsyncThunk(
    'friends/getFriends',
    async (payload, { getState }) => {
        // const { friends } = getState();
        const { name, pageSize, currentPage } = payload;

        const res = await getFriends(name, pageSize, currentPage);
        const data = await res.data;

        return data;
    }
);

export const addFavoriteFriendAsync = createAsyncThunk(
    'friends/addFavorite',
    async (payload) => {
        const { playerId } = payload;
        const res = await addFavoriteFriend(playerId);
        
        return { status: res.status, playerId };
    }
);

export const removeFavoriteFriendAsync = createAsyncThunk(
    'friends/removeFavorite',
    async (payload) => {
        const { playerId } = payload;
        const res = await removeFavoriteFriend(playerId);
        
        return { status: res.status, playerId };
    }
);
