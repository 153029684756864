import { Outlet } from "react-router-dom";

import { Navigation } from "../../shared/components/navigation/Navigation";
import { TopNavigation } from '../../shared/components/TopNavigation/TopNavigation';

import styles from "./MyMatches.module.scss";

export const MyMatches = () => {
    return (
        <section className={styles.MyMatches}>
            <TopNavigation />
            <Outlet />
            <Navigation />
        </section>
    );
};
