import { useNavigate } from "react-router-dom";

import { BackButton } from "../BackButton/BackButton";
import { IconApp } from "../IconApp/IconApp";
import styles from "./AuthHeader.module.scss";

export const AuthHeader = ({ headingText, navigateTo, hideLogo }) => {
    const navigate = useNavigate();
    const back = navigateTo ? navigateTo : -1;

    return (
        <header className={styles.AuthHeader}>
            <div className={styles.backIcon}>
                <BackButton onClick={() => navigate(back)} />
            </div>

            <IconApp
                style={{
                    cursor: "pointer",
                    display: hideLogo ? "none" : "block",
                }}
                onClick={() => navigate("/")}
                iconName="untoquecolor"
                width="137px"
                height="97px"
                alt="Logo a color de un toque"
            />

            {headingText && <h1>{headingText}</h1>}
        </header>
    );
};
