import { createAsyncThunk } from '@reduxjs/toolkit';
import { updateMatchStats } from '../../../services/matchesService';

export const updateMatchStatsAsync = createAsyncThunk(
    'matches/updateStats',
    async (payload) => {
        const { matchId, goals, assists } = payload;

        try {
            const res = await updateMatchStats(matchId, goals, assists);

            return res.status;
        } catch (error) {
            return error?.response?.status || 500;
        }
    }
);
