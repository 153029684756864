import { Outlet, useNavigate } from 'react-router-dom';

import { useUI } from './core/hooks/useUI';
import { useAuth } from './core/hooks/useAuth';

import { Footer } from './shared/components/Footer/Footer';
import { IconApp } from './shared/components/IconApp/IconApp';

import styles from './App.module.scss';
import { useTokenExpired } from './core/hooks/useTokenExpired';

const App = () => {
    const { bgImages, isToqueMobile } = useUI();
    const navigate = useNavigate();
    const { isAuth } = useAuth();
    
    useTokenExpired();

    return (
        <section
            // TODO: create device deteccion service to set this class
            className={`${styles.App} windows`}
            style={{
                '--bgMobile': bgImages.mobile,
                '--bgDesktop': bgImages.desktop,
            }}
        >
            <IconApp
                style={{
                    cursor: 'pointer',
                }}
                onClick={() => navigate('/')}
                className={styles.toqueLogo}
                iconName='untoquelogo'
                alt='Logo de un toque'
            />

            {isAuth && (
                <IconApp
                    onClick={() => navigate('/perfil')}
                    className={styles.profileLogo}
                    iconName='profile'
                    alt='Logo de mi perfil'
                />
            )}

            <main className={styles.main}>
                <IconApp
                    style={{
                        visibility: !!isToqueMobile ? 'visible' : 'hidden',
                    }}
                    onClick={() => navigate('/')}
                    className={styles.toqueLogo}
                    iconName='untoquelogo'
                    alt='Logo de un toque'
                />

                {isAuth && (
                    <IconApp
                        onClick={() => navigate('/perfil')}
                        className={styles.profileLogo}
                        iconName='profile'
                        alt='Logo de mi perfil'
                    />
                )}
                <Outlet />
            </main>

            <Footer />
        </section>
    );
};

export { App };
