import { createAsyncThunk } from '@reduxjs/toolkit';
import { updateMatchScore } from '../../../services/matchesService';

export const updateMatchScoreAsync = createAsyncThunk(
    'matches/updateScore',
    async (payload) => {
        const { matchId, scoreTeam1, scoreTeam2 } = payload;

        try {
            const res = await updateMatchScore(matchId, scoreTeam1, scoreTeam2);

            return res.status;
        } catch (error) {
            return error?.response?.status || 500;
        }
    }
);
