import { createAsyncThunk } from '@reduxjs/toolkit';
import { getMatchById } from '../../../services/matchesService';

export const getMatchByIdAsync = createAsyncThunk(
    'matches/getMatchById',
    async (id) => {
        const res = await getMatchById(id);
        const data = await res.data;

        return data;
    }
);
