import { Outlet } from "react-router-dom";

import { Navigation } from "../../shared/components/navigation/Navigation";
import { TopNavigation } from '../../shared/components/TopNavigation/TopNavigation';

import styles from "./Headquarters.module.scss";

export const Headquarters = () => {
    return (
        <section className={styles.Headquarters}>
            <TopNavigation />
            <Outlet />
            <Navigation />
        </section>
    );
};
