import styles from './HeadquartersCreate.module.scss';
import { PagesHeader } from '../../../shared/components/PagesHeader/PagesHeader';
import {
    Button,
    Checkbox,
    Dialog,
    FormControlLabel,
    Radio,
    RadioGroup,
    TextField,
} from '@mui/material';
import { ImageUpload } from '../../../shared/components/ImageUpload/ImageUpload';
import { Controller, useForm } from 'react-hook-form';
import { useEffect, useRef, useState } from 'react';
import { IconApp } from '../../../shared/components/IconApp/IconApp';
import { useDispatch, useSelector } from 'react-redux';
import {
    createStatusSelector,
    updateStatusSelector,
} from '../../../core/store/slices/headquarters/selectors';
import { InfoModal } from '../../../shared/components/InfoModal/InfoModal';
import { createHeadquarterAsync } from '../../../core/store/slices/headquarters/createHeadquarterAsync';
import {
    resetCreateStatus,
    resetCurrentHeadquarter,
    resetUpdateStatus,
} from '../../../core/store/slices/headquarters';
import {
    removeHeadquarterImagesAsync,
    uploadHeadquarterImagesAsync,
} from '../../../core/store/slices/headquarters/headquarterImagesAsync';
import { updateHeadquarterAsync } from '../../../core/store/slices/headquarters/updateHeadquarterAsync';
import { arraysEqual } from '../../../shared/utils/utils';
import { useNavigate } from 'react-router-dom';
import { urlPattern } from '../../../shared/constants/patterns';
import Compressor from 'compressorjs';

export function HeadquartersCreate({
    isEditing = false,
    isViewMode = false,
    currentHeadquarter,
}) {
    const [isError, setIsError] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isImagesDirty, setIsImagesDirty] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const createStatus = useSelector(createStatusSelector);
    const updateStatus = useSelector(updateStatusSelector);
    const imageUploadRef = useRef(null);

    const getDefaultValues = () => {
        if (isEditing) {
            return {
                name: currentHeadquarter.name,
                address: currentHeadquarter.address,
                fieldsAccount: currentHeadquarter.fieldsAccount,
                hasParking: currentHeadquarter.hasParking ? '1' : '0',
                hasChangingRooms: currentHeadquarter.hasChangingRooms
                    ? '1'
                    : '0',
                hasStore: currentHeadquarter.hasStore ? '1' : '0',
                hasDiscount: currentHeadquarter.hasDiscount ? '1' : '0',
                isPrivate: currentHeadquarter.isPrivate,
                discount: currentHeadquarter.hasDiscount
                    ? currentHeadquarter.discount
                    : '',
                parkingCost: currentHeadquarter.hasParking
                    ? currentHeadquarter.parkingCost
                    : '',
            };
        }

        return {
            hasParking: '',
            hasChangingRooms: '',
            hasStore: '',
            hasDiscount: '',
        };
    };

    const {
        register,
        handleSubmit,
        formState,
        control,
        watch,
        reset,
        setError,
    } = useForm({
        mode: 'onChange',
        defaultValues: getDefaultValues(),
    });

    const watchFields = watch(['hasParking', 'hasDiscount']);

    const { isDirty, isValid, errors } = formState;

    const [imagesFiles, setImagesFiles] = useState([]);
    const [displayedImages, setDisplayedImages] = useState([]);

    useEffect(() => {
        if (createStatus && createStatus.status === 200) {
            const locationId = createStatus.data;

            if (imagesFiles.length) {
                const compressPromises = imagesFiles.map(
                    (file) =>
                        new Promise((resolve, reject) => {
                            new Compressor(file, {
                                quality: 0.5,
                                success: (result) => resolve(result),
                                error: (error) => reject(error),
                            });
                        })
                );

                Promise.all(compressPromises).then((results) => {
                    results = results.map(result => new File([result], result.name));

                    dispatch(
                        uploadHeadquarterImagesAsync({
                            locationId,
                            images: results,
                        })
                    );
                });
            }

            setIsModalOpen(true);
        }

        if (createStatus && createStatus.status === 500) {
            setIsError(true);
            setIsModalOpen(true);
        }

        if (createStatus && createStatus.status === 400) {
            setError(
                'name',
                { type: 'manual', message: createStatus.message },
                { shouldFocus: true }
            );
        }
    }, [dispatch, createStatus, imagesFiles, setError]);

    useEffect(() => {
        if (updateStatus && updateStatus.status === 200) {
            const locationId = currentHeadquarter.id;

            if (imagesFiles.length) {
                const compressPromises = imagesFiles.map(
                    (file) =>
                        new Promise((resolve, reject) => {
                            new Compressor(file, {
                                quality: 0.5,
                                success: (result) => resolve(result),
                                error: (error) => reject(error),
                            });
                        })
                );

                Promise.all(compressPromises).then((results) => {
                    results = results.map(result => new File([result], result.name));

                    dispatch(
                        uploadHeadquarterImagesAsync({
                            locationId,
                            images: results,
                        })
                    );
                });
            }

            let imagesToDelete = [];

            currentHeadquarter.images.forEach((url) => {
                if (displayedImages.indexOf(url) === -1) {
                    imagesToDelete.push(url);
                }
            });

            if (imagesToDelete.length) {
                dispatch(
                    removeHeadquarterImagesAsync({ images: imagesToDelete })
                );
            }

            setIsModalOpen(true);
        }

        if (updateStatus && updateStatus.status === 500) {
            setIsError(true);
            setIsModalOpen(true);
        }

        if (updateStatus && updateStatus.status === 400) {
            setError(
                'name',
                { type: 'manual', message: updateStatus.message },
                { shouldFocus: true }
            );
        }
    }, [
        dispatch,
        currentHeadquarter,
        updateStatus,
        imagesFiles,
        displayedImages,
        setError,
    ]);

    useEffect(() => {
        if (isEditing && currentHeadquarter) {
            setDisplayedImages(currentHeadquarter.images);
        }
    }, [currentHeadquarter, isEditing]);

    useEffect(() => {
        if (isEditing) {
            if (arraysEqual(currentHeadquarter.images, displayedImages)) {
                setIsImagesDirty(false);
            } else {
                setIsImagesDirty(true);
            }
        }
    }, [currentHeadquarter, isEditing, displayedImages]);

    const onFileChange = (files) => {
        const imagesUrl = files.map((file) => URL.createObjectURL(file));

        if (isEditing) {
            setDisplayedImages((images) => [...images, ...imagesUrl]);
        } else {
            setDisplayedImages((images) => [...images, ...imagesUrl]);
        }

        setImagesFiles((imgfiles) => [...imgfiles, ...files]);
    };

    const onRemoveImage = (event, removeIndex) => {
        event.preventDefault();
        event.stopPropagation();
        imageUploadRef.current.value = null;

        if (!isEditing) {
            setImagesFiles(
                imagesFiles.filter((image, index) => index !== removeIndex)
            );
        }

        setDisplayedImages(
            displayedImages.filter((image, index) => index !== removeIndex)
        );
    };

    const onModalClose = () => {
        setIsError(false);
        setIsModalOpen(false);

        if (isEditing) {
            dispatch(resetUpdateStatus());
            dispatch(resetCurrentHeadquarter());
        } else {
            dispatch(resetCreateStatus());
            setImagesFiles([]);
            setDisplayedImages([]);
            reset();
        }
    };

    const onSubmit = (payload) => {
        const data = {
            ...payload,
            fieldsAccount: parseInt(payload.fieldsAccount),
            hasParking: Boolean(parseInt(payload.hasParking)),
            hasChangingRooms: Boolean(parseInt(payload.hasChangingRooms)),
            hasStore: Boolean(parseInt(payload.hasStore)),
            hasDiscount: Boolean(parseInt(payload.hasDiscount)),
        };

        data.parkingCost = data.hasParking ? parseInt(data.parkingCost) : null;
        data.discount = data.hasDiscount ? parseInt(data.discount) : null;

        if (isEditing) {
            data.id = currentHeadquarter.id;
            dispatch(updateHeadquarterAsync(data));
        } else {
            dispatch(createHeadquarterAsync(data));
        }
    };

    const isFormDirty = () => {
        if (isEditing) {
            return isImagesDirty || isDirty;
        }

        return isDirty;
    };

    return (
        <section className={styles.HeadquartersCreate}>
            <PagesHeader>
                {isEditing && isViewMode && <h1>Instalaciones</h1>}
                {isEditing && !isViewMode && <h2>Editar Instalación</h2>}
                {!isEditing && <h2>Nueva Instalación</h2>}
            </PagesHeader>

            <form onSubmit={handleSubmit(onSubmit)}>
                <div
                    className={`${styles.container} ${
                        isViewMode ? styles.containerViewMode : ''
                    }`}
                >
                    {!!displayedImages.length && (
                        <div className={styles.imagesWrapper}>
                            <div className={styles.images}>
                                {displayedImages.map((image, index) => (
                                    <div
                                        className={styles.imageContainer}
                                        key={index}
                                    >
                                        <img
                                            className={styles.image}
                                            src={image}
                                            alt=''
                                        ></img>
                                        {!isViewMode && (
                                            <button
                                                className={styles.removeBtn}
                                                onClick={(event) =>
                                                    onRemoveImage(event, index)
                                                }
                                            >
                                                <IconApp iconName='close' />
                                            </button>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}

                    <div className={styles.formContent}>
                        <div className={styles.formRow}>
                            {!isViewMode && (
                                <ImageUpload
                                    ref={imageUploadRef}
                                    handleImagesChange={onFileChange}
                                >
                                    <IconApp
                                        iconName='image'
                                        className={styles.imageIcon}
                                    />
                                    <IconApp iconName='plus' />
                                </ImageUpload>
                            )}
                            <Controller
                                control={control}
                                name='isPrivate'
                                render={({ field }) => (
                                    <FormControlLabel
                                        className={styles.checkbox}
                                        control={
                                            <Checkbox
                                                checked={field.value}
                                                disabled={isViewMode}
                                                onChange={(e) =>
                                                    field.onChange(
                                                        e?.target?.checked
                                                    )
                                                }
                                            />
                                        }
                                        labelPlacement='start'
                                        label='Instalación privada'
                                    />
                                )}
                            ></Controller>
                        </div>

                        <TextField
                            className={styles.textField}
                            placeholder='Nombre'
                            variant='filled'
                            size='small'
                            disabled={isViewMode}
                            fullWidth
                            {...register('name', {
                                required: true,
                                readOnly: isViewMode,
                            })}
                        />

                        <div className={styles.textField}>
                            <TextField
                                placeholder='Direccion (URL)'
                                variant='filled'
                                size='small'
                                disabled={isViewMode}
                                fullWidth
                                {...register('address', {
                                    required: true,
                                    pattern: urlPattern,
                                })}
                            />

                            {errors?.address &&
                                errors?.address?.type === 'pattern' && (
                                    <p className='fieldError'>
                                        Debe ingresar una URL valida
                                    </p>
                                )}
                        </div>

                        <TextField
                            className={styles.textField}
                            placeholder='# de canchas'
                            variant='filled'
                            size='small'
                            type='number'
                            disabled={isViewMode}
                            fullWidth
                            {...register('fieldsAccount', { required: true })}
                        />

                        <div className={styles.formRow}>
                            <span className={styles.label}>Parqueadero: </span>
                            <Controller
                                rules={{ required: true }}
                                control={control}
                                name='hasParking'
                                render={({ field }) => (
                                    <RadioGroup
                                        {...field}
                                        className={styles.radioGroup}
                                    >
                                        <FormControlLabel
                                            className={styles.checkbox}
                                            control={<Radio />}
                                            labelPlacement='start'
                                            label='Si'
                                            disabled={isViewMode}
                                            value={1}
                                        />
                                        <FormControlLabel
                                            className={styles.checkbox}
                                            control={<Radio />}
                                            labelPlacement='start'
                                            label='No'
                                            disabled={isViewMode}
                                            value={0}
                                        />
                                    </RadioGroup>
                                )}
                            ></Controller>
                            <TextField
                                placeholder='costo'
                                variant='filled'
                                size='small'
                                fullWidth
                                type='number'
                                {...register('parkingCost', {
                                    required: watchFields[0] === '1',
                                    disabled:
                                        watchFields[0] === '0' || isViewMode,
                                })}
                            />
                        </div>
                        <div className={styles.formRow}>
                            <span className={styles.label}>Camerinos: </span>
                            <Controller
                                rules={{ required: true }}
                                control={control}
                                name='hasChangingRooms'
                                render={({ field }) => (
                                    <RadioGroup
                                        {...field}
                                        className={styles.radioGroup}
                                    >
                                        <FormControlLabel
                                            className={styles.checkbox}
                                            control={<Radio />}
                                            labelPlacement='start'
                                            label='Si'
                                            disabled={isViewMode}
                                            value={1}
                                        />
                                        <FormControlLabel
                                            className={styles.checkbox}
                                            control={<Radio />}
                                            labelPlacement='start'
                                            label='No'
                                            disabled={isViewMode}
                                            value={0}
                                        />
                                    </RadioGroup>
                                )}
                            ></Controller>
                        </div>
                        <div className={styles.formRow}>
                            <span className={styles.label}>Tienda: </span>
                            <Controller
                                rules={{ required: true }}
                                control={control}
                                name='hasStore'
                                render={({ field }) => (
                                    <RadioGroup
                                        {...field}
                                        className={styles.radioGroup}
                                    >
                                        <FormControlLabel
                                            className={styles.checkbox}
                                            control={<Radio />}
                                            labelPlacement='start'
                                            label='Si'
                                            disabled={isViewMode}
                                            value={1}
                                        />
                                        <FormControlLabel
                                            className={styles.checkbox}
                                            control={<Radio />}
                                            labelPlacement='start'
                                            label='No'
                                            disabled={isViewMode}
                                            value={0}
                                        />
                                    </RadioGroup>
                                )}
                            ></Controller>
                        </div>
                        <div className={styles.formRow}>
                            <span className={styles.label}>Convenio: </span>
                            <Controller
                                rules={{ required: true }}
                                control={control}
                                name='hasDiscount'
                                render={({ field }) => (
                                    <RadioGroup
                                        {...field}
                                        className={styles.radioGroup}
                                    >
                                        <FormControlLabel
                                            className={styles.checkbox}
                                            control={<Radio />}
                                            labelPlacement='start'
                                            label='Si'
                                            disabled={isViewMode}
                                            value={1}
                                        />
                                        <FormControlLabel
                                            className={styles.checkbox}
                                            control={<Radio />}
                                            labelPlacement='start'
                                            label='No'
                                            disabled={isViewMode}
                                            value={0}
                                        />
                                    </RadioGroup>
                                )}
                            ></Controller>
                            <TextField
                                placeholder='% DCTO'
                                variant='filled'
                                size='small'
                                fullWidth
                                type='number'
                                {...register('discount', {
                                    required: watchFields[1] === '1',
                                    disabled:
                                        watchFields[1] === '0' || isViewMode,
                                })}
                            />
                        </div>

                        {errors.name && (
                            <p className={styles.errorMessage}>
                                {errors.name.message}
                            </p>
                        )}
                    </div>
                </div>
                <div className={styles.formActions}>
                    {isEditing && isViewMode && (
                        <>
                            <Button
                                variant='contained'
                                className='primary-button-gradient'
                                fullWidth
                                onClick={() =>
                                    navigate(
                                        `/canchas/sede/${currentHeadquarter.id}`
                                    )
                                }
                            >
                                Canchas
                            </Button>
                            <Button
                                variant='contained'
                                className='primary-button-gradient'
                                fullWidth
                                onClick={() =>
                                    navigate(
                                        `/sedes/editar/${currentHeadquarter.name}`
                                    )
                                }
                            >
                                Editar Instalación
                            </Button>
                        </>
                    )}

                    {isEditing && !isViewMode && (
                        <Button
                            type='submit'
                            variant='contained'
                            className='primary-button-gradient'
                            fullWidth
                            disabled={!isFormDirty() || !isValid}
                        >
                            Guardar cambios
                        </Button>
                    )}

                    {!isEditing && (
                        <Button
                            type='submit'
                            variant='contained'
                            className='primary-button-gradient'
                            fullWidth
                            disabled={!isFormDirty() || !isValid}
                        >
                            Crear Instalación
                        </Button>
                    )}
                </div>
            </form>

            <Dialog fullScreen open={isModalOpen} onClose={onModalClose}>
                {isError && (
                    <InfoModal
                        title='Oh no!'
                        description='Hubo un error, por favor intenta mas tarde'
                        closeModal={onModalClose}
                    />
                )}

                {isEditing && !isError && (
                    <InfoModal
                        title='LISTO!'
                        description='La instalación se actualizo exitosamente'
                        closeModal={onModalClose}
                    />
                )}

                {!isEditing && !isError && (
                    <InfoModal
                        title='LISTO!'
                        description='La instalacion se creo exitosamente'
                        closeModal={onModalClose}
                    />
                )}
            </Dialog>
        </section>
    );
}
