import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

import { Button } from '@mui/material';

import { PagesHeader } from '../../../shared/components/PagesHeader/PagesHeader';
import globals from '../../../shared/constants/global.constant';
import { IconApp } from '../../../shared/components/IconApp/IconApp';

import { getPlayerAsync } from '../../../core/store/slices/players/getPlayerAsync';
import { logOut } from '../../../core/store/slices/auth';

import styles from './ProfileDetails.module.scss';
import { useNavigate } from 'react-router-dom';
import { getUserSelector } from '../../../core/store/slices/auth/selectors';
import { resetPlayer } from '../../../core/store/slices/players';
import { Loader } from '../../../shared/components/loader/Loader';

export const ProfileDetails = () => {
    const profileImage = `${globals.imageBasePath}/profile-avatar.svg`;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(getUserSelector);
    const { player } = useSelector((state) => state?.players);
    const currentPlayer = player && player[0];

    useEffect(() => {
        if (user && !player) {
            dispatch(getPlayerAsync());
        }
    }, [player, user, dispatch]);

    const handleLogOutClick = () => {
        dispatch(logOut());
        dispatch(resetPlayer());
        navigate('/');
    };

    const handleUpdateProfileClick = () => {
        navigate('/perfil/editar');
    };

    return !currentPlayer ? (
        <Loader></Loader>
    ) : (
        <section className={styles.ProfileDetails}>
            <PagesHeader navigateTo='/'>
                <h1 className={styles.playerName}>
                    {currentPlayer?.firstName} {currentPlayer?.lastName}
                </h1>
            </PagesHeader>

            <section className={styles.content}>
                <div>
                    <img
                        className={styles.avatarImage}
                        src={profileImage}
                        alt='Imagen de perfil'
                    />
                    <p>{currentPlayer?.position}</p>
                </div>

                <section>
                    <IconApp
                        className={styles.statIcon}
                        iconName='stats'
                        width='40'
                        height='40'
                        alt='Icono de stadisticas'
                    />
                    <div className={styles.stats}>
                        <div>
                            <p>PARTIDOS JUGADOS:</p>
                            <p>{currentPlayer?.totalGames}</p>
                        </div>

                        <div>
                            <p>GOLES:</p>
                            <p>{currentPlayer?.goals}</p>
                        </div>

                        <div>
                            <p>ASISTENCIAS:</p>
                            <p>{currentPlayer?.assists}</p>
                        </div>
                    </div>
                </section>
                <div className={styles.buttonsWrapper}>
                    <Button
                        onClick={handleUpdateProfileClick}
                        className='primary-button-gradient'
                        variant='contained'
                    >
                        Actualizar Perfil
                    </Button>
                    <Button
                        onClick={handleLogOutClick}
                        className='primary-button-gradient'
                        variant='contained'
                    >
                        CERRAR SESION
                    </Button>
                </div>
            </section>
        </section>
    );
};
