import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getReceiptsAsync } from '../../../core/store/slices/receipts/getReceiptsAsync';
import {
    isLoadingReceiptsSelector,
    receiptsSelector,
    totalReceiptsSelector,
    validateStatusSelector,
} from '../../../core/store/slices/receipts/selectors';
import { Loader } from '../../../shared/components/loader/Loader';
import { PagesHeader } from '../../../shared/components/PagesHeader/PagesHeader';
import styles from './ReceiptsList.module.scss';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
} from '@mui/material';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { approveReceiptAsync } from '../../../core/store/slices/receipts/approveReceiptAsync';
import { rejectReceiptAsync } from '../../../core/store/slices/receipts/rejectReceiptAsync';
import { InfoModal } from '../../../shared/components/InfoModal/InfoModal';
import { resetValidateStatus } from '../../../core/store/slices/receipts';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

export const ReceiptsList = () => {
    const dispatch = useDispatch();

    const isLoading = useSelector(isLoadingReceiptsSelector);
    const receipts = useSelector(receiptsSelector);
    const totalReceipts = useSelector(totalReceiptsSelector);
    const validateStatus = useSelector(validateStatusSelector);

    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize] = useState(5);
    const [selectedReceipt, setSelectedReceipt] = useState(null);
    const [isReceiptModalOpen, setReceiptModalOpen] = useState(false);
    const [isModalOpen, setModalOpen] = useState(false);
    const [isError, setIsError] = useState(false);
    const [startDateFilter, setStartDateFilter] = useState(null);
    const [endDateFilter, setEndDateFilter] = useState(null);

    useEffect(() => {
        dispatch(
            getReceiptsAsync({
                currentPage,
                pageSize,
                startDate: startDateFilter,
                endDate: endDateFilter,
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, currentPage, pageSize]);

    useEffect(() => {
        if (startDateFilter && endDateFilter) {
            if (currentPage === 1) {
                dispatch(
                    getReceiptsAsync({
                        currentPage,
                        pageSize,
                        startDate: startDateFilter,
                        endDate: endDateFilter,
                    })
                );
            } else {
                setCurrentPage(1);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, startDateFilter, endDateFilter]);

    useEffect(() => {
        if (validateStatus === 200) {
            setModalOpen(true);
            setReceiptModalOpen(false);

            if (currentPage === 1) {
                dispatch(getReceiptsAsync({ currentPage, pageSize }));
            } else {
                setCurrentPage(1);
            }

            return;
        }

        if (validateStatus !== null && validateStatus !== 200) {
            setIsError(true);
            setModalOpen(true);
        }
    }, [dispatch, validateStatus, currentPage, pageSize]);

    const handleLoadMore = () => {
        setCurrentPage(currentPage + 1);
    };

    const handleValidate = (receipt) => {
        setSelectedReceipt(receipt);
        setReceiptModalOpen(true);
    };

    const handleReceiptModalClose = () => {
        setReceiptModalOpen(false);
        setSelectedReceipt(null);
    };

    const handleModalClose = () => {
        setModalOpen(false);
        dispatch(resetValidateStatus());
    };

    const handleApproveReceipt = () => {
        dispatch(approveReceiptAsync({ receiptId: selectedReceipt.id }));
    };

    const handleRejectReceipt = () => {
        dispatch(rejectReceiptAsync({ receiptId: selectedReceipt.id }));
    };

    const handleStartDateChange = (value) => {
        setStartDateFilter(value);
    };

    const handleEndDateChange = (value) => {
        setEndDateFilter(value);
    };

    const handleCleanDateFilter = () => {
        setStartDateFilter(null);
        setEndDateFilter(null);

        if (currentPage === 1) {
            dispatch(
                getReceiptsAsync({
                    currentPage,
                    pageSize,
                })
            );
        } else {
            setCurrentPage(1);
        }
    };

    return (
        <section className={styles.ReceiptsList}>
            <PagesHeader>
                <h1>Comprobantes</h1>
            </PagesHeader>

            <section className={styles.content}>
                <div className={styles.receiptsFilters}>
                    <div className={styles.receiptsDates}>
                        <LocalizationProvider
                            dateAdapter={AdapterDateFns}
                            adapterLocale={es}
                        >
                            <div className={styles.dateFilter}>
                                <span>Fecha Inicial: </span>
                                <DatePicker
                                    closeOnSelect
                                    label='Seleccionar fecha inicial'
                                    value={startDateFilter}
                                    maxDate={
                                        endDateFilter
                                            ? endDateFilter
                                            : undefined
                                    }
                                    onChange={handleStartDateChange}
                                    renderInput={(params) => (
                                        <TextField
                                            variant='filled'
                                            size='small'
                                            type='text'
                                            fullWidth
                                            {...params}
                                        />
                                    )}
                                    DialogProps={{
                                        sx: {
                                            '& .MuiDialogActions-root': {
                                                display: 'none',
                                            },
                                        },
                                    }}
                                />
                            </div>
                            <div className={styles.dateFilter}>
                                <span>Fecha Final: </span>
                                <DatePicker
                                    closeOnSelect
                                    label='Seleccionar fecha final'
                                    value={endDateFilter}
                                    minDate={
                                        startDateFilter
                                            ? startDateFilter
                                            : undefined
                                    }
                                    onChange={handleEndDateChange}
                                    renderInput={(params) => (
                                        <TextField
                                            variant='filled'
                                            size='small'
                                            type='text'
                                            placeholder='Fecha fin'
                                            fullWidth
                                            {...params}
                                        />
                                    )}
                                    DialogProps={{
                                        sx: {
                                            '& .MuiDialogActions-root': {
                                                display: 'none',
                                            },
                                        },
                                    }}
                                />
                            </div>
                        </LocalizationProvider>
                    </div>

                    <Button
                        className='primary-button-gradient'
                        variant='contained'
                        size='small'
                        fullWidth
                        onClick={handleCleanDateFilter}
                        disabled={!startDateFilter || !endDateFilter}
                    >
                        Limpiar rango
                    </Button>
                </div>

                <div id='receiptsList' className={styles.receiptsContainer}>
                    <InfiniteScroll
                        className={styles.infiniteScroll}
                        dataLength={receipts.length}
                        next={handleLoadMore}
                        hasMore={receipts.length < totalReceipts}
                        scrollableTarget='receiptsList'
                        loader={
                            <div className={styles.loadingSmall}>
                                <Loader size='small' />
                            </div>
                        }
                    >
                        {isLoading && !receipts.length && (
                            <div className={styles.loading}>
                                <Loader />
                            </div>
                        )}

                        {!isLoading && !receipts.length && (
                            <div className={styles.emptyData}>
                                <h3>No se encontraron comprobantes</h3>
                            </div>
                        )}

                        {receipts.map((receipt) => (
                            <div key={receipt.id} className={styles.receipt} onClick={() => handleValidate(receipt)}>
                                <div className={styles.receiptHeader}>
                                    <h4>Comprobante #{receipt.id}</h4>
                                </div>
                                <div className={styles.receiptContent}>
                                    <div>
                                        <span>Fecha del comprobante: </span>
                                        <span>
                                            {format(
                                                new Date(receipt.date),
                                                'EEEEEE-dd/MM/yy-hh:mm',
                                                { locale: es }
                                            )}
                                        </span>
                                    </div>
                                    <div>
                                        <span>Fecha del partido: </span>
                                        <span>
                                            {format(
                                                new Date(receipt.matchDate),
                                                'EEEEEE-dd/MM/yy-hh:mm',
                                                { locale: es }
                                            )}
                                        </span>
                                    </div>
                                    <div>
                                        <span>
                                            Sede: {receipt.locationName}
                                        </span>
                                    </div>
                                    <div>
                                        <span>
                                            Jugador: {receipt.playerName}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </InfiniteScroll>
                </div>
            </section>

            <Dialog open={isReceiptModalOpen} onClose={handleReceiptModalClose}>
                <DialogTitle sx={{ textAlign: 'center' }}>
                    Validar Comprobante
                </DialogTitle>
                <DialogContent>
                    <img
                        className={styles.receiptImage}
                        src={selectedReceipt?.image}
                        alt=''
                    ></img>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleReceiptModalClose}>Cerrar</Button>
                    {!selectedReceipt?.isValidated && (
                        <>
                            <Button autoFocus onClick={handleRejectReceipt}>
                                Rechazar
                            </Button>
                            <Button autoFocus onClick={handleApproveReceipt}>
                                Aceptar
                            </Button>
                        </>
                    )}
                </DialogActions>
            </Dialog>

            <Dialog fullScreen open={isModalOpen} onClose={handleModalClose}>
                {isError && (
                    <InfoModal
                        title='Oh no!'
                        description='Hubo un error, por favor intenta mas tarde'
                        closeModal={handleModalClose}
                    />
                )}

                {!isError && (
                    <InfoModal
                        title='LISTO!'
                        description='La acción se realizó correctamente'
                        closeModal={handleModalClose}
                    />
                )}
            </Dialog>
        </section>
    );
};
