import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getIsLoginInSelector } from '../../../core/store/slices/auth/selectors';
import { IconApp } from '../../../shared/components/IconApp/IconApp';
import { Player } from '../Player/Player';
import styles from './Teams.module.scss';

export function Teams({
    isOpenMatch,
    players,
    totalPlayers,
    selectedTeam,
    onTeamChange,
    isPastMatch,
}) {
    const navigate = useNavigate();

    const isLogIn = useSelector(getIsLoginInSelector);

    const [emptyPlayers, setEmptyPlayers] = useState([]);

    useEffect(() => {
        if (players && players.length && players.length < totalPlayers) {
            if (isOpenMatch) {
                setEmptyPlayers([
                    ...Array(totalPlayers - players.length).fill({}),
                    ...players,
                ]);
            } else {
                let playersTeam1 = players.filter(
                    (player) => player.team === 1
                ).length;

                for (
                    let index = 0;
                    index < totalPlayers - players.length;
                    index++
                ) {
                    if (playersTeam1 < totalPlayers / 2) {
                        setEmptyPlayers((emptyPlayers) => [
                            { team: 1 },
                            ...emptyPlayers,
                        ]);
                        playersTeam1 += 1;
                    } else {
                        setEmptyPlayers((emptyPlayers) => [
                            { team: 2 },
                            ...emptyPlayers,
                        ]);
                    }
                }
            }
        } else if (totalPlayers) {
            const emptyTeam1 = Array(totalPlayers / 2).fill({ team: 1 });
            const emptyTeam2 = Array(totalPlayers / 2).fill({ team: 2 });
            setEmptyPlayers([...emptyTeam1, ...emptyTeam2]);
        }
    }, [isOpenMatch, players, totalPlayers]);

    const handleChangeTeamClick = (team) => {
        onTeamChange(team);
    };

    const handlePlayerClick = (event, id, team) => {
        if (isPastMatch || team) {
            event.preventDefault();
            event.stopPropagation();
            return;
        }

        if (isLogIn) {
            navigate(`/partidos/invitar/${id}`, { state: { isInviteMode: true } });
        }
    };

    return (
        <div className={styles.teams}>
            {isOpenMatch ? (
                <h2 className={styles.openMatchTitle}>JUGADORES</h2>
            ) : (
                <div className={styles.buttonsWrapper}>
                    <button
                        className={`${styles.button} ${
                            selectedTeam === 1 ? styles.active : ''
                        }`}
                        onClick={() => handleChangeTeamClick(1)}
                    >
                        <IconApp
                            width={15}
                            height={15}
                            iconName='fingerprint_red'
                            alt=''
                        />
                        <span>EQUIPO 1</span>
                    </button>
                    <button
                        className={`${styles.button} ${
                            selectedTeam === 2 ? styles.active : ''
                        }`}
                        onClick={() => handleChangeTeamClick(2)}
                    >
                        <IconApp
                            width={15}
                            height={15}
                            iconName='fingerprint_blue'
                            alt=''
                        />
                        <span>EQUIPO 2</span>
                    </button>
                </div>
            )}

            <div className={styles.players}>
                {isOpenMatch &&
                    selectedTeam === 1 &&
                    emptyPlayers
                        .slice(0, totalPlayers / 2)
                        .map((player, index) => (
                            <Player
                                key={index}
                                name={player.playerName}
                                team={player.playerId ? 1 : 0}
                                position={player.positionName}
                                hidePosition={isPastMatch && !player.playerName}
                                handleClick={handlePlayerClick}
                            ></Player>
                        ))}

                {isOpenMatch &&
                    selectedTeam === 2 &&
                    emptyPlayers
                        .slice(totalPlayers / 2, totalPlayers)
                        .map((player, index) => (
                            <Player
                                key={index}
                                team={player.playerId ? 1 : 0}
                                name={player.playerName}
                                position={player.positionName}
                                hidePosition={isPastMatch && !player.playerName}
                                handleClick={handlePlayerClick}
                            ></Player>
                        ))}

                {!isOpenMatch &&
                    emptyPlayers
                        .filter((player) => player.team === selectedTeam)
                        .map((player, index) => (
                            <Player
                                key={index}
                                hidePosition={isPastMatch}
                                handleClick={handlePlayerClick}
                            ></Player>
                        ))}
                {!isOpenMatch &&
                    players &&
                    players
                        .filter((player) => player.team === selectedTeam)
                        .map((player, index) => (
                            <Player
                                key={index}
                                team={selectedTeam}
                                name={player.playerName}
                                position={player.positionName}
                                hidePosition={isPastMatch && !player.playerName}
                                handleClick={handlePlayerClick}
                            ></Player>
                        ))}
            </div>

            {isOpenMatch && (
                <div className={styles.openMatchButtons}>
                    <button
                        className={selectedTeam === 1 ? styles.active : ''}
                        onClick={() => handleChangeTeamClick(1)}
                    ></button>
                    <button
                        className={selectedTeam === 2 ? styles.active : ''}
                        onClick={() => handleChangeTeamClick(2)}
                    ></button>
                </div>
            )}
        </div>
    );
}
