import { createAsyncThunk } from '@reduxjs/toolkit';
import { rejectReceipt } from '../../../services/receiptsService';

export const rejectReceiptAsync = createAsyncThunk(
    'receipt/Reject',
    async (payload) => {
        const { receiptId } = payload;
        const res = await rejectReceipt(receiptId);

        return res.status;
    }
);
