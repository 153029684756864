import { createSlice } from '@reduxjs/toolkit';
import { createFieldAsync } from './createFieldAsync';
import { deleteFieldAsync } from './deleteFieldAsync';
import { getFieldByIdAsync } from './getFieldByIdAsync';
import { getFieldsAsync, getFieldsListAsync } from './getFieldsAsync';
import { updateFieldAsync } from './updateFieldAsync';
import { initialState } from './initialState';

export const fieldsSlice = createSlice({
    name: 'fields',
    initialState,
    reducers: {
        resetFields: (state) => {
            state.allFields = []
        },
        resetCurrentField: (state) => {
            state.currentField = null;
        },
        resetCreateStatus: (state) => {
            state.createStatus = null;
        },
        resetUpdateStatus: (state) => {
            state.updateStatus = null;
        },
        resetDeleteStatus: (state) => {
            state.deleteStatus = null;
        },
    },
    extraReducers: {
        // get fields
        [getFieldsAsync.pending]: (state) => {
            state.isLoading = true;
        },
        [getFieldsAsync.fulfilled]: (state, action) => {
            if (action.payload.currentPage === 1) {
                state.allFields = action.payload.items;
            } else {
                state.allFields = [...state.allFriends, ...action.payload.items];
            }
            state.totalFields = action.payload.totalItems;
            state.isLoading = false;
        },
        [getFieldsAsync.rejected]: (state) => {
            state.isLoading = false;
        },
        // get fields list
        [getFieldsListAsync.pending]: (state) => {
            state.isLoading = true;
        },
        [getFieldsListAsync.fulfilled]: (state, action) => {
            state.fieldsList = action.payload;
            state.isLoading = false;
        },
        [getFieldsListAsync.rejected]: (state) => {
            state.isLoading = false;
        },
        // get field by id
        [getFieldByIdAsync.pending]: (state) => {
            state.isLoading = true;
        },
        [getFieldByIdAsync.fulfilled]: (state, action) => {
            state.currentField = action.payload;
            state.isLoading = false;
        },
        [getFieldByIdAsync.rejected]: (state) => {
            state.isLoading = false;
        },
        // create
        [createFieldAsync.pending]: (state) => {
            state.isLoading = true;
        },
        [createFieldAsync.fulfilled]: (state, action) => {
            state.createStatus = action.payload;
            state.isLoading = false;
        },
        [createFieldAsync.rejected]: (state, action) => {
            state.createStatus = action.payload;
            state.isLoading = false;
        },
        // update
        [updateFieldAsync.pending]: (state) => {
            state.isLoading = true;
        },
        [updateFieldAsync.fulfilled]: (state, action) => {
            state.updateStatus = action.payload;
            state.isLoading = false;
        },
        [updateFieldAsync.rejected]: (state, action) => {
            state.updateStatus = action.payload;
            state.isLoading = false;
        },
        // delete
        [deleteFieldAsync.pending]: (state) => {
            state.isLoading = true;
        },
        [deleteFieldAsync.fulfilled]: (state, action) => {
            state.deleteStatus = action.payload;
            state.isLoading = false;
        },
        [deleteFieldAsync.rejected]: (state, action) => {
            state.deleteStatus = action.payload;
            state.isLoading = false;
        },
    },
});

export const {
    resetFields,
    resetCurrentField,
    resetCreateStatus,
    resetUpdateStatus,
    resetDeleteStatus,
} = fieldsSlice.actions;

export default fieldsSlice.reducer;
