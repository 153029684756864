import { createSlice } from "@reduxjs/toolkit";

import { initialState } from "./initialState";
import { getPlayerAsync } from "./getPlayerAsync";

export const playersSlice = createSlice({
    name: "players",
    initialState,
    reducers: {
        resetPlayer: (state) => {
            state.player = null;
        }
    },
    extraReducers: {
        [getPlayerAsync.pending]: (state) => {
            state.isPlayerLoading = true;
        },
        [getPlayerAsync.fulfilled]: (state, action) => {
            state.player = action.payload;
            state.isPlayerLoading = false;
        },
        [getPlayerAsync.rejected]: (state) => {
            state.isPlayerLoading = false;
        },
    },
});

export const { resetPlayer } = playersSlice.actions;

export default playersSlice.reducer;
