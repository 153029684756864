import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getIsLoginInSelector } from '../../../core/store/slices/auth/selectors';
import { IconApp } from '../../../shared/components/IconApp/IconApp';
import styles from './Player.module.scss';

export function Player({
    team,
    name,
    position,
    handleClick,
    hidePosition = false,
}) {
    const { id } = useParams();

    const isLogIn = useSelector(getIsLoginInSelector);

    const handlePlayerClick = (event) => {
        if (handleClick) {
            handleClick(event, id, team);
        }
    };

    return (
        <button className={styles.player} onClick={handlePlayerClick}>
            <IconApp
                className={styles.avatar}
                width={30}
                height={30}
                iconName={team ? `player_team_${team}` : 'player_empty'}
            />

            <span className={styles.name}>{name || 'DISPONIBLE'}</span>

            {!hidePosition && (
                <span className={styles.position}>{position ? position : isLogIn ? 'INVITAR' : ''}</span>
            )}
        </button>
    );
}
