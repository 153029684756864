import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserSelector } from '../../../core/store/slices/auth/selectors';
import { getPlayerAsync } from '../../../core/store/slices/players/getPlayerAsync';
import { getIsPlayerLoadingSelector } from '../../../core/store/slices/players/selectors';
import { Loader } from '../../../shared/components/loader/Loader';

import { AuthRegister } from '../../Auth/AuthRegister/AuthRegister';
import styles from './ProfileUpdate.module.scss';

export function ProfileUpdate() {
    const dispatch = useDispatch();
    const isLoadingPlayer = useSelector(getIsPlayerLoadingSelector);
    const user = useSelector(getUserSelector);
    const { player } = useSelector((state) => state?.players);

    useEffect(() => {
        if (user && !player) {
            dispatch(getPlayerAsync());
        }
    }, [user, player, dispatch]);

    return !player || isLoadingPlayer ? (
        <Loader></Loader>
    ) : (
        <section className={styles.ProfileUpdate}>
            <AuthRegister isEditing={true} user={player[0]}></AuthRegister>
        </section>
    );
}
