
export const translationKeys = {
  // Calendar navigation
  previousMonth: 'Mes anterior',
  nextMonth: 'Mes siguiente',

  // View navigation
  openPreviousView: 'Abrir vista anterior',
  openNextView: 'Abir vista siguiente',
  calendarViewSwitchingButtonAriaLabel: (view) =>
    view === 'Año'
      ? 'vista de año abierta, cambiar a vista de calendario'
      : 'vista de calendario esta abierta, cambiar a vista de año',

  // DateRange placeholders
  start: 'Comienzo',
  end: 'Fin',

  // Action bar
  cancelButtonLabel: 'Cancelar',
  clearButtonLabel: 'Limpiar',
  okButtonLabel: 'OK',
  todayButtonLabel: 'Hoy',

  // Clock labels
  clockLabelText: (view, time, adapter) =>
    `Seleccionar ${view}. ${
      time === null ? 'No se ha seleccionado hora' : `Hora seleccionada es ${adapter.format(time, 'fullTime')}`
    }`,
  hoursClockNumberText: (hours) => `${hours} horas`,
  minutesClockNumberText: (minutes) => `${minutes} minutos`,
  secondsClockNumberText: (seconds) => `${seconds} segundos`,

  // Open picker labels
  openDatePickerDialogue: (rawValue, utils) =>
    rawValue && utils.isValid(utils.date(rawValue))
      ? `Elegir fecha, fecha seleccionada es ${utils.format(utils.date(rawValue), 'fullDate')}`
      : 'Elegir fecha',
  openTimePickerDialogue: (rawValue, utils) =>
    rawValue && utils.isValid(utils.date(rawValue))
      ? `Elegir hora, hora seleccionada es ${utils.format(utils.date(rawValue), 'fullTime')}`
      : 'Elegir hora',

  // Table labels
  dateTimeTableLabel: 'hola',
  timeTableLabel: 'Seleccionar hora',
  dateTableLabel: 'Seleccionar fecha',
};
