import { Button } from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getIsLoginInSelector } from '../../../core/store/slices/auth/selectors';
import { IconApp } from '../IconApp/IconApp';
import styles from './TopNavigation.module.scss';

export const TopNavigation = () => {
    const navigate = useNavigate();

    const isLogIn = useSelector(getIsLoginInSelector);

    return (
        <nav className={styles.TopNavigation}>
            <Button
                className='primary-button-gradient'
                variant='contained'
                onClick={() => navigate('/partidos')}
            >
                PARTIDOS UN TOQUE
                <IconApp
                    className={styles.iconButton}
                    iconName='partidosuntoque'
                    width='25'
                    height='25'
                    alt='Icono de partidos un toque'
                />
            </Button>

            <Button
                className='primary-button-gradient'
                variant='contained'
                onClick={() => navigate('/amigos')}
                disabled={!isLogIn}
            >
                AMIGOS
                <IconApp
                    className={styles.iconButton}
                    iconName='amigos'
                    width='25'
                    height='25'
                    alt='Icono de amigos'
                />
            </Button>

            <Button
                className='primary-button-gradient'
                variant='contained'
                onClick={() => navigate('/mis-partidos')}
                disabled={!isLogIn}
            >
                MIS PARTIDOS
                <IconApp
                    className={styles.iconButton}
                    iconName='mispartidos'
                    width='25'
                    height='25'
                    alt='Icono de mis partidos'
                />
            </Button>

            <Button
                className='primary-button-gradient'
                variant='contained'
                onClick={() => navigate('/sedes')}
            >
                INSTALACIONES
                <IconApp
                    className={styles.iconButton}
                    iconName='sedes'
                    width='25'
                    height='25'
                    alt='Icono de sedes'
                />
            </Button>
        </nav>
    );
};
