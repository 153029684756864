import { createAsyncThunk } from '@reduxjs/toolkit';
import { getMatchStatsByPlayer } from '../../../services/matchesService';

export const getMatchStatsByPlayerAsync = createAsyncThunk(
    'matches/getStatsByPlayer',
    async () => {
        const res = await getMatchStatsByPlayer();
        const data = await res.data;

        return data;
    }
);
