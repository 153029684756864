import styles from './Fields.module.scss';
import { Outlet } from "react-router-dom";

import { Navigation } from "../../shared/components/navigation/Navigation";
import { TopNavigation } from '../../shared/components/TopNavigation/TopNavigation';

export const Fields = () => {
    return (
        <section className={styles.Fields}>
            <TopNavigation />
            <Outlet />
            <Navigation />
        </section>
    );
};
