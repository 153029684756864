import { axiosClient } from "./axiosInstance";

export const getSettings = () => {
    const url = `/Settings/GetGroupSettings`;

    return axiosClient.get(url);
};

export const getSettingById = (id) => {
    const url = `/Settings/GetSettingsByGroupSettingId?groupSettingId=${id}`;

    return axiosClient.get(url);
};
