import { createSlice } from '@reduxjs/toolkit';

import { initialState } from './initialState';

import { getRegisterSettings, registerUserAsync } from './registerAsync';
import { facebookLoginAsync, getExternalLoginAsync, googleLoginAsync, loginUserAsync } from './loginAsync';
import { updateUserAsyc } from './updateAsync';

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        resetRegisterStatus: (state) => {
            state.registerStatus = null;
        },
        resetUpdateStatus: (state) => {
            state.updateStatus = null;
        },
        setLoginUser: (state, action) => {
            state.user = action.payload;
            state.isLoginIn = true;
        },
        logOut: (state) => {
            localStorage.removeItem('user');
            state.user = null;
            state.isLoginIn = false;
        },
        setTokenExpired: (state, action) => {
            state.isTokenExpired = action.payload
        }
    },
    extraReducers: {
        // Register user
        [registerUserAsync.pending]: (state) => {
            state.isRegistering = true;
        },
        [registerUserAsync.fulfilled]: (state, action) => {
            state.registerStatus = action.payload;
            state.isRegistering = false;
        },
        [registerUserAsync.rejected]: (state, action) => {
            state.registerStatus = action.payload;
            state.isRegistering = false;
        },
        // Get Settings
        [getRegisterSettings.pending]: (state) => {
            state.isLoadingRegSettings = true;
        },
        [getRegisterSettings.fulfilled]: (state, action) => {
            state.settings = action.payload;
            state.isLoadingRegSettings = false;
        },
        [getRegisterSettings.rejected]: (state) => {
            state.isLoadingRegSettings = false;
        },
        // Login User
        [loginUserAsync.pending]: (state) => {
            state.isLoadingLogin = true;
        },
        [loginUserAsync.fulfilled]: (state, action) => {
            state.user = action.payload;
            state.isLoginIn = true;
            state.isLoadingLogin = false;
        },
        [loginUserAsync.rejected]: (state) => {
            state.isLoginIn = false;
            state.isLoadingLogin = true;
        },
        // Update user
        [updateUserAsyc.pending]: (state) => {
            state.isUpdating = true;
        },
        [updateUserAsyc.fulfilled]: (state, action) => {
            state.updateStatus = action.payload;
            state.isUpdating = false;
        },
        [updateUserAsyc.rejected]: (state, action) => {
            state.updateStatus = action.payload;
            state.isUpdating = false;
        },
        // get external login data
        [getExternalLoginAsync.pending]: (state) => {
            state.isLoadingLogin = true;
        },
        [getExternalLoginAsync.fulfilled]: (state, action) => {
            state.externalLoginData = action.payload;
            state.isLoadingLogin = false;
        },
        [getExternalLoginAsync.rejected]: (state) => {
            state.isLoadingLogin = false;
        },
        // Facebook Login
        [facebookLoginAsync.pending]: (state) => {
            state.isLoadingLogin = true;
        },
        [facebookLoginAsync.fulfilled]: (state, action) => {
            state.loginStatus = action.payload.status;
            state.user = action.payload.data
            state.isLoginIn = true;
            state.isLoadingLogin = false;
        },
        [facebookLoginAsync.rejected]: (state, action) => {
            state.loginStatus = action.payload.status;
            state.isLoadingLogin = false;
        },
        // Google Login
        [googleLoginAsync.pending]: (state) => {
            state.isLoadingLogin = true;
        },
        [googleLoginAsync.fulfilled]: (state, action) => {
            state.loginStatus = action.payload.status;
            state.user = action.payload.data
            state.isLoginIn = true;
            state.isLoadingLogin = false;
        },
        [googleLoginAsync.rejected]: (state, action) => {
            state.loginStatus = action.payload.status;
            state.isLoadingLogin = false;
        },
    },
});

export const { resetRegisterStatus, resetUpdateStatus, setLoginUser, logOut, setTokenExpired } = authSlice.actions;

export default authSlice.reducer;
