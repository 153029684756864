import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Controller, useForm } from 'react-hook-form';
import {
    Button,
    Dialog,
    FormControl,
    FormControlLabel,
    InputAdornment,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    TextField,
} from '@mui/material';

import { AuthHeader } from '../../../shared/components/AuthHeader/AuthHeader';
import {
    emailPattern,
    passPattern,
    phonePattern,
} from '../../../shared/constants/patterns';

import {
    getIsRegistering,
    getRegisterStatusSelector,
    getUpdateStatusSelector,
} from '../../../core/store/slices/auth/selectors';
import { InfoModal } from '../../../shared/components/InfoModal/InfoModal';
import { registerUserAsync } from '../../../core/store/slices/auth/registerAsync';

import styles from './AuthRegister.module.scss';
import {
    resetRegisterStatus,
    resetUpdateStatus,
} from '../../../core/store/slices/auth';
import { PagesHeader } from '../../../shared/components/PagesHeader/PagesHeader';
import { updateUserAsyc } from '../../../core/store/slices/auth/updateAsync';
import {
    gendersSelector,
    isLoadingSettingsSelector,
    positionsSelector,
} from '../../../core/store/slices/settings/selectors';
import {
    getGenderSettingsAsync,
    getPositionSettingsAsync,
} from '../../../core/store/slices/settings/getSettingsAsync';
import { resetPlayer } from '../../../core/store/slices/players';
import { Loader } from '../../../shared/components/loader/Loader';
import AddIcon from '@mui/icons-material/Add';

export const AuthRegister = ({ isEditing, user }) => {
    const getDefaultValues = () => {
        if (isEditing) {
            return {
                firstName: user.firstName,
                lastName: user.lastName,
                genderId: user.genderId,
                positionId: user.positionId,
                address: user.address,
                phonePrefix: user.phoneNumber?.split('-')[0],
                phoneNumber: user.phoneNumber?.split('-')[1],
            };
        }

        return {
            genderId: 1,
            positionId: 3,
        };
    };

    const { register, handleSubmit, formState, control } = useForm({
        mode: 'onChange',
        defaultValues: getDefaultValues(),
    });
    const navigate = useNavigate();

    const { isDirty, isValid, errors } = formState;
    const [isModalopen, setIsModalOpen] = useState(false);
    const [isError, setIsError] = useState(false);
    const dispatch = useDispatch();
    const isSettingsLoading = useSelector(isLoadingSettingsSelector);
    const genders = useSelector(gendersSelector);
    const positions = useSelector(positionsSelector);
    const isRegisteing = useSelector(getIsRegistering);
    const registerStatus = useSelector(getRegisterStatusSelector);
    const updateStatus = useSelector(getUpdateStatusSelector);

    const onSubmit = (payload) => {
        payload.phoneNumber = `${payload.phonePrefix}-${payload.phoneNumber}`;
        delete payload.phonePrefix;

        if (isEditing) {
            payload.email = user.email;

            if (!payload.password || !payload.password.trim()) {
                delete payload.password;
            }

            dispatch(updateUserAsyc(payload));
        } else {
            dispatch(registerUserAsync(payload));
        }
    };

    const handleClose = () => {
        setIsModalOpen(false);

        if (isEditing) {
            navigate('/perfil');
            dispatch(resetUpdateStatus());
            dispatch(resetPlayer());
        } else {
            navigate('/auth');
            dispatch(resetRegisterStatus());
        }
    };

    useEffect(() => {
        if (!genders) {
            dispatch(getGenderSettingsAsync());
        }
    }, [dispatch, genders]);

    useEffect(() => {
        if (!positions) {
            dispatch(getPositionSettingsAsync());
        }
    }, [dispatch, positions]);

    useEffect(() => {
        if (registerStatus === 201) {
            setIsModalOpen(true);
        }

        if (registerStatus === 500) {
            setIsError(true);
            setIsModalOpen(true);
        }
    }, [registerStatus]);

    useEffect(() => {
        if (updateStatus === 200) {
            setIsModalOpen(true);
        }

        if (updateStatus === 500) {
            setIsError(true);
            setIsModalOpen(true);
        }
    }, [updateStatus]);

    return isSettingsLoading || isRegisteing || (isEditing && !user) ? (
        <Loader></Loader>
    ) : (
        <section className={styles.AuthRegister}>
            {isEditing ? (
                <div className={styles.editHeader}>
                    <h2 className={styles.editTitle}>Editar</h2>
                    <PagesHeader navigateTo='/perfil'>
                        <h1>tu cuenta</h1>
                    </PagesHeader>
                </div>
            ) : (
                <AuthHeader navigateTo='/auth' />
            )}

            <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
                {isEditing && (
                    <span className={styles.userEmail}>{user.email}</span>
                )}

                <div className={styles.fieldGroup}>
                    <div className={styles.item}>
                        <TextField
                            placeholder='NOMBRE'
                            variant='filled'
                            size='small'
                            error={!!errors?.firstName}
                            fullWidth
                            {...register('firstName', { required: true })}
                        />
                        {errors?.firstName &&
                            errors?.firstName?.type === 'required' && (
                                <p className='fieldError'>
                                    Este campo es requerido
                                </p>
                            )}
                    </div>

                    <div className={styles.item}>
                        <TextField
                            placeholder='APELLIDO'
                            variant='filled'
                            size='small'
                            fullWidth
                            error={!!errors?.lastName}
                            {...register('lastName', { required: true })}
                        />
                        {errors?.lastName &&
                            errors?.lastName?.type === 'required' && (
                                <p className='fieldError'>
                                    Este campo es requerido
                                </p>
                            )}
                    </div>
                </div>

                <div className={styles.fieldGroup}>
                    <div className={`${styles.item} ${styles.phonePrefix}`}>
                        <TextField
                            placeholder='Prefijo'
                            variant='filled'
                            size='small'
                            type='number'
                            fullWidth
                            error={!!errors?.phonePrefix}
                            {...register('phonePrefix', {
                                required: true,
                            })}
                            sx={{
                                '& .MuiFilledInput-input': {
                                    paddingLeft: '0px !important',
                                },
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment
                                        position='start'
                                        sx={{
                                            '&': { color: 'white' },
                                            '& .MuiSvgIcon-root': {
                                                fontSize: '16px',
                                            },
                                        }}
                                    >
                                        <AddIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        {errors?.phonePrefix &&
                            errors?.phonePrefix?.type === 'required' && (
                                <p className='fieldError'>
                                    Este campo es requerido
                                </p>
                            )}
                    </div>

                    <div className={`${styles.item} ${styles.phoneNumber}`}>
                        <TextField
                            placeholder='WHATSAPP'
                            variant='filled'
                            size='small'
                            fullWidth
                            error={!!errors?.phoneNumber}
                            {...register('phoneNumber', {
                                required: true,
                                pattern: phonePattern,
                            })}
                        />
                        {errors?.phoneNumber &&
                            errors?.phoneNumber?.type === 'required' && (
                                <p className='fieldError'>
                                    Este campo es requerido
                                </p>
                            )}
                        {errors?.phoneNumber &&
                            errors?.phoneNumber?.type === 'pattern' && (
                                <p className='fieldError'>
                                    Debe ingresar un numero valido
                                </p>
                            )}
                    </div>
                </div>

                {!isEditing && (
                    <div className={styles.singleField}>
                        <TextField
                            placeholder='CORREO'
                            variant='filled'
                            size='small'
                            fullWidth
                            error={!!errors?.email}
                            {...register('email', {
                                required: true,
                                pattern: emailPattern,
                            })}
                        />
                        {errors?.email &&
                            errors?.email?.type === 'required' && (
                                <p className='fieldError'>
                                    Este campo es requerido
                                </p>
                            )}
                        {errors?.email && errors?.email?.type === 'pattern' && (
                            <p className='fieldError'>
                                Debe ingresar un email valido
                            </p>
                        )}
                    </div>
                )}

                <div className={styles.singleField}>
                    <TextField
                        placeholder='DIRECCION O BARRIO'
                        variant='filled'
                        size='small'
                        fullWidth
                        error={!!errors?.address}
                        {...register('address', { required: true })}
                    />
                    {errors?.address &&
                        errors?.address?.type === 'required' && (
                            <p className='fieldError'>
                                Este campo es requerido
                            </p>
                        )}
                </div>

                <div className={styles.singleField}>
                    <TextField
                        placeholder='CONTRASEÑA'
                        variant='filled'
                        size='small'
                        fullWidth
                        error={!!errors?.password}
                        type='password'
                        {...register('password', {
                            required: !isEditing,
                            pattern: passPattern,
                        })}
                    />
                    {errors?.password &&
                        errors?.password?.type === 'required' && (
                            <p className='fieldError'>
                                Este campo es requerido
                            </p>
                        )}
                    {errors?.password &&
                        errors?.password?.type === 'pattern' && (
                            <p className='fieldError'>
                                Debe tener entre 6 y 16 caracteres, una
                                mayuscula, una minuscula, un caracter especial y
                                un numero.
                            </p>
                        )}
                </div>

                <div className={styles.fieldGroup}>
                    <FormControl fullWidth className={styles.item}>
                        <Controller
                            rules={{ required: true }}
                            control={control}
                            name='positionId'
                            render={({ field }) => {
                                return (
                                    positions && (
                                        <Select {...field}>
                                            {positions.map((position) => (
                                                <MenuItem
                                                    key={position.id}
                                                    value={position.id}
                                                >
                                                    {position.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    )
                                );
                            }}
                        />
                    </FormControl>

                    <FormControl>
                        <Controller
                            rules={{ required: true }}
                            control={control}
                            name='genderId'
                            render={({ field }) => {
                                return (
                                    <RadioGroup {...field}>
                                        {genders?.map((gender) => (
                                            <FormControlLabel
                                                key={gender.id}
                                                value={gender.id}
                                                control={<Radio />}
                                                label={gender.name}
                                            />
                                        ))}
                                    </RadioGroup>
                                );
                            }}
                        />
                    </FormControl>
                </div>

                <Button
                    type='submit'
                    className='primary-button-gradient'
                    variant='contained'
                    disabled={!isDirty || !isValid || isRegisteing}
                >
                    {isEditing ? 'GUARDAR CAMBIOS' : 'REGISTRARME'}
                </Button>
            </form>

            <Dialog fullScreen open={isModalopen} onClose={handleClose}>
                {isError && (
                    <InfoModal
                        title='Oh no!'
                        description='Hubo un error, por favor intenta mas tarde'
                        closeModal={handleClose}
                    />
                )}

                {isEditing && !isError && (
                    <InfoModal
                        title='LISTO!'
                        description='Tus cambios se guardaron exitosamente'
                        closeModal={handleClose}
                    />
                )}

                {!isEditing && !isError && (
                    <InfoModal
                        title='LISTO!'
                        description='PRONTO RECIBIRAS UN CORREO DE CONFIRMACION, CONFIRMA TU EMAIL Y LUEGO INICIA SESION.'
                        closeModal={handleClose}
                    />
                )}
            </Dialog>
        </section>
    );
};
