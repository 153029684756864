import styles from './MyMatch.module.scss';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { useNavigate } from 'react-router-dom';
import { IconButton, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateMatchStatsAsync } from '../../../core/store/slices/matches/updateMatchStatsAsync';
import { UpdateStatsDialog } from '../UpdateStatsDialog/UpdateStatsDialog';

export function MyMatch({ id, locationImg, locationName, score, date, stats }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const isMenuOpen = Boolean(anchorEl);

    const handleViewDetails = () => {
        navigate(`/partidos/detalles/${id}`);
    };

    const handleUpdateStats = () => {
        setIsModalOpen(true);
        setAnchorEl(null);
    };

    const handleMenuOpenClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleSaveStats = (goals, assists) => {
        dispatch(updateMatchStatsAsync({ matchId: id, goals, assists }));
        setIsModalOpen(false);
    };

    return (
        <div className={styles.myMatch}>
            <div className={styles.content} onClick={handleViewDetails}>
                <img
                    className={styles.locationImg}
                    src={locationImg}
                    alt={locationName}
                ></img>
                <div className={styles.matchDetails}>
                    <span>{locationName}</span>
                    <span>{score}</span>
                    <div>
                        <p>{format(new Date(date), 'EEEE', { locale: es })}</p>
                        <span>{format(new Date(date), 'dd/MM/yy-hh:mm bbb')}</span>
                    </div>
                </div>
            </div>
            <IconButton
                className={styles.menuButton}
                onClick={handleMenuOpenClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                open={isMenuOpen}
                anchorEl={anchorEl}
                onClose={handleMenuClose}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
            >
                <MenuItem onClick={handleViewDetails}>Ver detalles</MenuItem>
                <MenuItem onClick={handleUpdateStats} disabled={!score.replace('-', '')}>Mis Estadisticas</MenuItem>
            </Menu>

            <UpdateStatsDialog
                isOpen={isModalOpen}
                stats={stats}
                onClose={() => setIsModalOpen(false)}
                onSave={handleSaveStats}
            ></UpdateStatsDialog>
        </div>
    );
}
