import { IconButton } from "@mui/material";

import { IconApp } from "../IconApp/IconApp";

export const BackButton = (props) => {
    return (
        <IconButton {...props} aria-label="ir atras">
            <IconApp
                iconName="back"
                width="40px"
                height="40px"
                alt="Icono para ir atras"
            />
        </IconButton>
    );
};
