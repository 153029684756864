import styles from './CreateMatch.module.scss';
import { PagesHeader } from '../../../shared/components/PagesHeader/PagesHeader';
import { Controller, useForm } from 'react-hook-form';
import {
    Button,
    Dialog,
    FormControl,
    FormControlLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    TextField,
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import { useDispatch, useSelector } from 'react-redux';
import {
    headquartersSelector,
    isLoadingSelector as isLoadingHeadquartersSelector,
} from '../../../core/store/slices/headquarters/selectors';
import { Loader } from '../../../shared/components/loader/Loader';
import { useEffect, useState } from 'react';
import { getHeadquartersAsync } from '../../../core/store/slices/headquarters/getHeadquartersAsync';
import constants from '../../../shared/constants/global.constant';
import { format } from 'date-fns';
import { InfoModal } from '../../../shared/components/InfoModal/InfoModal';
import { resetFields } from '../../../core/store/slices/fields';
import {
    fieldsListSelector,
    isLoadingSelector as isLoadingFieldsSelector,
} from '../../../core/store/slices/fields/selectors';
import { getFieldsListAsync } from '../../../core/store/slices/fields/getFieldsAsync';
import {
    isLoadingSettingsSelector,
    matchGendersSelector,
} from '../../../core/store/slices/settings/selectors';
import { getMatchGendersSettingsAsync } from '../../../core/store/slices/settings/getSettingsAsync';
import globals from '../../../shared/constants/global.constant';
import { createMatchAsync } from '../../../core/store/slices/matches/createMatchAsync';
import { updateMatchAsync } from '../../../core/store/slices/matches/updateMatchAsync';
import {
    createStatusSelector,
    udpateStatusSelector,
} from '../../../core/store/slices/matches/selectors';
import {
    resetCreateStatus,
    resetCurrentMatch,
    resetUpdateStatus,
} from '../../../core/store/slices/matches';
import { es } from 'date-fns/locale';

export const CreateMatch = ({ isEditing = false, match }) => {
    const dispatch = useDispatch();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isError, setIsError] = useState(false);
    const [matchDate, setMatchDate] = useState(null);

    const isLoadingLocations = useSelector(isLoadingHeadquartersSelector);
    const isLoadingFields = useSelector(isLoadingFieldsSelector);
    const isLoadingGenders = useSelector(isLoadingSettingsSelector);
    const locations = useSelector(headquartersSelector);
    const fields = useSelector(fieldsListSelector);
    const genders = useSelector(matchGendersSelector);

    const createStatus = useSelector(createStatusSelector);
    const updateStatus = useSelector(udpateStatusSelector);

    const getDefaultValues = () => {
        if (isEditing) {
            return {
                locationId: match?.locationId,
                fieldId: match?.fieldId,
                genderTypeId: match?.genderTypeId,
                totalPlayers: match?.totalPlayers,
                totalCost: match?.totalCost,
                costPerPerson: match?.costPerPerson,
                matchTypeId:
                    match?.matchTypeId === constants.matchTypesId.open ? 1 : 0,
                isPeriodical: match?.isPeriodical ? 1 : 0,
            };
        }

        return {
            locationId: '',
            fieldId: '',
            genderTypeId: '',
            matchTypeId: '',
            isPeriodical: '',
        };
    };

    const { register, handleSubmit, formState, control, watch, reset } =
        useForm({
            mode: 'onChange',
            defaultValues: getDefaultValues(),
        });

    const selectedLocation = watch('locationId');

    const { isDirty, isValid, errors } = formState;

    useEffect(() => {
        dispatch(resetFields());
    }, [dispatch]);

    useEffect(() => {
        dispatch(getHeadquartersAsync());
    }, [dispatch]);

    useEffect(() => {
        dispatch(getMatchGendersSettingsAsync());
    }, [dispatch]);

    useEffect(() => {
        if (selectedLocation !== '') {
            dispatch(getFieldsListAsync(selectedLocation));
        }
    }, [selectedLocation, dispatch]);

    useEffect(() => {
        if (match && match.matchDate) {
            setMatchDate(new Date(match.matchDate));
        }
    }, [match]);

    useEffect(() => {
        if (createStatus && createStatus.status === 200) {
            setIsModalOpen(true);
        }

        if (createStatus && createStatus.status !== 200) {
            setIsError(true);
            setIsModalOpen(true);
        }

        // if (createStatus?.status === 400) {
        //     setError(
        //         'fieldName',
        //         { type: 'manual', message: createStatus.message },
        //         { shouldFocus: true }
        //     );
        // }
    }, [createStatus]);

    useEffect(() => {
        if (updateStatus && updateStatus.status === 200) {
            setIsModalOpen(true);
        }

        if (updateStatus && updateStatus.status !== 200) {
            setIsError(true);
            setIsModalOpen(true);
        }
    }, [updateStatus]);

    const isFormDirty = () => {
        if (isEditing) {
            return (
                isDirty ||
                (matchDate &&
                    matchDate.toString() !==
                        new Date(match.matchDate).toString())
            );
        }

        return isDirty || matchDate !== null;
    };

    const onSubmit = (payload) => {
        const data = {
            ...payload,
            costPerPerson: parseInt(payload.costPerPerson),
            totalCost: parseInt(payload.totalCost),
            totalPlayers: parseInt(payload.totalPlayers),
            isPeriodical: Boolean(parseInt(payload.isPeriodical)),
            matchTypeId: Boolean(parseInt(payload.matchTypeId))
                ? globals.matchTypesId.open
                : globals.matchTypesId.teams,
            matchDate: `${format(matchDate, 'yyyy-MM-dd')}T${format(
                matchDate,
                'HH:mm:ss'
            )}`,
        };

        if (isEditing) {
            data.id = match.id;
            dispatch(updateMatchAsync(data));
        } else {
            dispatch(createMatchAsync(data));
        }
    };

    const onModalClose = () => {
        setIsError(false);
        setIsModalOpen(false);

        if (isEditing) {
            dispatch(resetUpdateStatus());
            dispatch(resetCurrentMatch());
        } else {
            dispatch(resetCreateStatus());
            setMatchDate(null);
            reset();
        }
    };

    return isLoadingLocations || isLoadingFields || isLoadingGenders ? (
        <Loader></Loader>
    ) : (
        <section className={styles.CreateMatch}>
            <PagesHeader>
                {isEditing ? <h1>Editar Partido</h1> : <h1>Nuevo Partido</h1>}
            </PagesHeader>

            <form onSubmit={handleSubmit(onSubmit)}>
                <div className={styles.content}>
                    <div className={styles.formRow}>
                        <span className={styles.label}>Instalación: </span>
                        <FormControl className={styles.textField}>
                            <Controller
                                rules={{ required: true }}
                                control={control}
                                name='locationId'
                                render={({ field }) => {
                                    return (
                                        <Select {...field}>
                                            {locations?.map(
                                                (location, index) => (
                                                    <MenuItem
                                                        key={location.id}
                                                        value={location.id}
                                                    >
                                                        {location.name}
                                                    </MenuItem>
                                                )
                                            )}
                                        </Select>
                                    );
                                }}
                            />
                        </FormControl>
                    </div>

                    <div className={styles.formRow}>
                        <span className={styles.label}>Cancha: </span>
                        <FormControl className={styles.textField}>
                            <Controller
                                rules={{ required: true }}
                                control={control}
                                name='fieldId'
                                render={({ field }) => {
                                    return (
                                        <Select {...field}>
                                            {fields?.map((f, index) => (
                                                <MenuItem
                                                    key={f.id}
                                                    value={f.id}
                                                >
                                                    {f.fieldName}
                                                </MenuItem>
                                            ))}
                                            {!fields?.length &&
                                                !selectedLocation && (
                                                    <MenuItem disabled>
                                                        Selecciona una
                                                        Instalación
                                                    </MenuItem>
                                                )}
                                            {!fields?.length &&
                                                selectedLocation && (
                                                    <MenuItem disabled>
                                                        No hay canchas
                                                    </MenuItem>
                                                )}
                                        </Select>
                                    );
                                }}
                            />
                        </FormControl>
                    </div>

                    <div className={styles.formRow}>
                        <span className={styles.label}>Genero: </span>
                        <FormControl className={styles.textField}>
                            <Controller
                                rules={{ required: true }}
                                control={control}
                                name='genderTypeId'
                                render={({ field }) => {
                                    return (
                                        <Select {...field}>
                                            {genders?.map((gender) => (
                                                <MenuItem
                                                    key={gender.id}
                                                    value={gender.id}
                                                >
                                                    {gender.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    );
                                }}
                            />
                        </FormControl>
                    </div>

                    <div className={styles.formRow}>
                        <span className={styles.label}>Fecha: </span>
                        <LocalizationProvider
                            dateAdapter={AdapterDateFns}
                            adapterLocale={es}
                        >
                            <DateTimePicker
                                label='Seleccionar fecha & hora'
                                renderInput={(props) => (
                                    <TextField
                                        variant='filled'
                                        size='small'
                                        type='text'
                                        className={styles.textField}
                                        {...props}
                                    />
                                )}
                                value={matchDate}
                                ampm={true}
                                onChange={(newValue) => {
                                    setMatchDate(new Date(newValue));
                                }}
                            />
                        </LocalizationProvider>
                    </div>

                    <div className={styles.formRow}>
                        <span className={styles.label}>Numero de cupos: </span>
                        <TextField
                            variant='filled'
                            size='small'
                            type='number'
                            className={styles.textField}
                            {...register('totalPlayers', {
                                required: true,
                                pattern: /^\d*[02468]$/,
                            })}
                        ></TextField>

                        {errors?.totalPlayers && errors?.totalPlayers?.type === 'pattern' && (
                            <p className={`fieldError ${styles.fieldError}`}>
                                El numero de cupos debe ser un numero par.
                            </p>
                        )}
                    </div>

                    <div className={styles.formRow}>
                        <span className={styles.label}>
                            Valor reserva completa:{' '}
                        </span>
                        <TextField
                            variant='filled'
                            size='small'
                            type='text'
                            className={styles.textField}
                            {...register('totalCost', {
                                required: true,
                            })}
                        ></TextField>
                    </div>

                    <div className={styles.formRow}>
                        <span className={styles.label}>
                            Valor por persona:{' '}
                        </span>
                        <TextField
                            variant='filled'
                            size='small'
                            type='text'
                            className={styles.textField}
                            {...register('costPerPerson', {
                                required: true,
                            })}
                        ></TextField>
                    </div>

                    <div className={styles.formRow}>
                        <span className={styles.label}>Partido Abierto: </span>
                        <Controller
                            rules={{ required: true }}
                            control={control}
                            name='matchTypeId'
                            render={({ field }) => (
                                <RadioGroup
                                    {...field}
                                    className={styles.radioGroup}
                                >
                                    <FormControlLabel
                                        className={styles.checkbox}
                                        control={<Radio />}
                                        labelPlacement='start'
                                        label='Si'
                                        value={1}
                                    />
                                    <FormControlLabel
                                        className={styles.checkbox}
                                        control={<Radio />}
                                        labelPlacement='start'
                                        label='No'
                                        value={0}
                                    />
                                </RadioGroup>
                            )}
                        ></Controller>
                    </div>

                    <div className={styles.formRow}>
                        <span className={styles.label}>Periodico: </span>
                        <Controller
                            rules={{ required: true }}
                            control={control}
                            name='isPeriodical'
                            render={({ field }) => (
                                <RadioGroup
                                    {...field}
                                    className={styles.radioGroup}
                                >
                                    <FormControlLabel
                                        className={styles.checkbox}
                                        control={<Radio />}
                                        labelPlacement='start'
                                        label='Si'
                                        value={1}
                                    />
                                    <FormControlLabel
                                        className={styles.checkbox}
                                        control={<Radio />}
                                        labelPlacement='start'
                                        label='No'
                                        value={0}
                                    />
                                </RadioGroup>
                            )}
                        ></Controller>
                    </div>
                </div>

                <div className={styles.actions}>
                    <Button
                        type='submit'
                        variant='contained'
                        className='primary-button-gradient'
                        fullWidth
                        disabled={!isFormDirty() || !isValid || !matchDate}
                    >
                        {isEditing ? 'Guardar Cambios' : 'Crear Partido'}
                    </Button>
                </div>
            </form>

            <Dialog fullScreen open={isModalOpen} onClose={onModalClose}>
                {isError && (
                    <InfoModal
                        title='Oh no!'
                        description='Hubo un error, por favor intenta mas tarde'
                        closeModal={onModalClose}
                    />
                )}

                {isEditing && !isError && (
                    <InfoModal
                        title='LISTO!'
                        description='El partido se actualizo exitosamente'
                        closeModal={onModalClose}
                    />
                )}

                {!isEditing && !isError && (
                    <InfoModal
                        title='LISTO!'
                        description='El partido se creó exitosamente'
                        closeModal={onModalClose}
                    />
                )}
            </Dialog>
        </section>
    );
};
