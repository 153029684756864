import { createAsyncThunk } from '@reduxjs/toolkit';
import { updateMatch } from '../../../services/matchesService';

export const updateMatchAsync = createAsyncThunk(
    'matches/update',
    async (payload) => {
        try {
            const res = await updateMatch(payload);
            const data = await res.data;

            return {
                status: res.status,
                data,
            };
        } catch (error) {
            const res = error.response;

            return {
                status: res.status,
                message: res.data,
            };
        }
    }
);
