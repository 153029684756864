import { Outlet } from 'react-router-dom';

import { Navigation } from '../../shared/components/navigation/Navigation';
import { TopNavigation } from '../../shared/components/TopNavigation/TopNavigation';

import styles from './Receipts.module.scss';

export const Receipts = () => {
    return (
        <section className={styles.Receipts}>
            <TopNavigation />
            <Outlet />
            <Navigation />
        </section>
    );
};
