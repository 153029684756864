import {
    InputAdornment,
    TextField,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
} from '@mui/material';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { IconApp } from '../../../shared/components/IconApp/IconApp';

import { PagesHeader } from '../../../shared/components/PagesHeader/PagesHeader';
import { Friend } from '../Friend/Friend';
import styles from './ListFriends.module.scss';
import {
    friendsSelector,
    isLoadingFriendsSelector,
    totalFriendsSelector,
} from '../../../core/store/slices/friends/selectors';
import { useEffect, useState } from 'react';
import { getFriendsAsync } from '../../../core/store/slices/friends/friendsAsync';
import { useLocation, useParams } from 'react-router-dom';
import { capitalize, generateWhatsAppURL } from '../../../shared/utils/utils';
import { resetFriends } from '../../../core/store/slices/friends';
import { Loader } from '../../../shared/components/loader/Loader';

let searchTimeout;

function getMessage(friendName, matchId) {
    if (!friendName) {
        return `Hola! Te quiero invitar a jugar un partido en UnToque, unete aqui: ${window.location.origin}/partidos/detalles/${matchId}`;
    }

    return `Hola ${capitalize(
        friendName
    )}!, un amigo te esta invitando a jugar un partido en UnToque. Unete aqui: ${
        window.location.origin
    }/partidos/detalles/${matchId}`;
}

export const ListFriends = () => {
    const isLoading = useSelector(isLoadingFriendsSelector);
    const totalFriends = useSelector(totalFriendsSelector);
    const friends = useSelector(friendsSelector);
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize] = useState(10);
    const [search, setSearch] = useState(null);
    const [isInviteMode, setIsInviteMode] = useState(false);
    const { state } = useLocation();
    const [selected, setSelected] = useState(null);
    const { matchId } = useParams();
    const [ísDialogOpen, setIsDialogOpen] = useState(false);

    useEffect(() => {
        if (state) {
            const { isInviteMode } = state;
            setIsInviteMode(isInviteMode);
        }
    }, [state]);

    useEffect(() => {
        if (currentPage === 1) {
            dispatch(resetFriends());
        }

        dispatch(getFriendsAsync({ name: search, pageSize, currentPage }));
    }, [dispatch, currentPage, pageSize, search]);

    const handleLoadMore = () => {
        setCurrentPage(currentPage + 1);
    };

    const handleSearchChange = (event) => {
        clearTimeout(searchTimeout);

        searchTimeout = setTimeout(() => {
            setSearch(event.target.value);
        }, 500);
    };

    const handleFriendClick = (event, id) => {
        if (!isInviteMode) {
            event.stopPropagation();
            return;
        }

        if (selected === id) {
            setSelected(null);
        } else {
            setSelected(id);
        }
    };

    const handleInviteFriendClick = () => {
        const friend = friends.find((f) => f.playerId === selected);
        const message = getMessage(friend.firstName, matchId);

        window.open(generateWhatsAppURL(friend.phoneNumber, message));
    };

    const handleInviteToUntoqueClick = () => {
        setIsDialogOpen(true);
        setSelected(null);
    };

    const handleCopyClipboard = () => {
        const message = getMessage(null, matchId);
        navigator.clipboard.writeText(message);
        setIsDialogOpen(false);
    };

    const handleSendByWhatsApp = () => {
        const message = getMessage(null, matchId);
        window.open(generateWhatsAppURL(undefined, message));
        setIsDialogOpen(false);
    };

    return (
        <section
            className={`
                ${styles.ListFriends} 
                ${isInviteMode ? styles.InviteMode : ''}
            `}
        >
            <PagesHeader>
                <TextField
                    className={styles.searchInput}
                    placeholder='Buscar en lista'
                    variant='filled'
                    size='small'
                    fullWidth
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position='start'>
                                <IconApp iconName='search'></IconApp>
                            </InputAdornment>
                        ),
                    }}
                    onChange={handleSearchChange}
                />
            </PagesHeader>

            <div className={styles.container}>
                <div id='friendsList' className={styles.list}>
                    <InfiniteScroll
                        dataLength={friends.length}
                        next={handleLoadMore}
                        hasMore={friends.length < totalFriends}
                        scrollableTarget='friendsList'
                        loader={
                            <div className={styles.loadingSmall}>
                                <Loader size='small' />
                            </div>
                        }
                    >
                        {isLoading && !friends.length && (
                            <div className={styles.loading}>
                                <Loader />
                            </div>
                        )}

                        {!isLoading && !friends.length && (
                            <div className={styles.emptyData}>
                                <h3>No se encontraron amigos</h3>
                            </div>
                        )}

                        {friends.map((friend) => (
                            <Friend
                                key={friend.playerId}
                                id={friend.playerId}
                                name={friend.fullName}
                                position={friend.position}
                                isFavorite={friend.isFavoriteFriend}
                                isActive={selected === friend.playerId}
                                hideBookmark={isInviteMode}
                                handleClick={handleFriendClick}
                            ></Friend>
                        ))}
                    </InfiniteScroll>
                </div>

                {/* {!isInviteMode && (
                    <Button color='primary' variant='contained' fullWidth>
                        Agregar nuevo amigo
                    </Button>
                )} */}

                {isInviteMode && (
                    <>
                        <div>
                            <Button
                                color='primary'
                                variant='contained'
                                fullWidth
                                className={styles.button}
                                disabled={selected === null}
                                onClick={handleInviteFriendClick}
                            >
                                Invitar amigo
                            </Button>
                            <Button
                                color='primary'
                                variant='contained'
                                fullWidth
                                className={styles.button}
                                onClick={handleInviteToUntoqueClick}
                            >
                                Invitar amigo a un Toque
                            </Button>
                        </div>

                        <Dialog
                            open={ísDialogOpen}
                            onClose={() => setIsDialogOpen(false)}
                        >
                            <DialogTitle>Invitar Amigo</DialogTitle>
                            <DialogContent>
                                <DialogContentText id='alert-dialog-description'>
                                    Hola! Te quiero invitar a jugar un partido
                                    en UnToque, unete aqui: &nbsp;
                                    {`${window.location.origin}/partidos/detalles/${matchId}`}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => setIsDialogOpen(false)}>
                                    Cerrar
                                </Button>
                                <Button onClick={handleCopyClipboard}>
                                    Copiar
                                </Button>
                                <Button
                                    onClick={handleSendByWhatsApp}
                                    autoFocus
                                >
                                    Enviar por WhatsApp
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </>
                )}
            </div>
        </section>
    );
};
