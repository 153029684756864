import {
    Button,
    Dialog,
    FormControlLabel,
    Radio,
    RadioGroup,
    TextField,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
    resetCreateStatus,
    resetCurrentField,
    resetUpdateStatus,
} from '../../../core/store/slices/fields';
import { createFieldAsync } from '../../../core/store/slices/fields/createFieldAsync';
import {
    createStatusSelector,
    updateStatusSelector,
} from '../../../core/store/slices/fields/selectors';
import { updateFieldAsync } from '../../../core/store/slices/fields/updateFieldAsync';
import { IconApp } from '../../../shared/components/IconApp/IconApp';
import { ImageUpload } from '../../../shared/components/ImageUpload/ImageUpload';
import { PagesHeader } from '../../../shared/components/PagesHeader/PagesHeader';
import { arraysEqual } from '../../../shared/utils/utils';
import styles from './FieldsCreate.module.scss';
import { useParams } from 'react-router-dom';
import {
    deleteFieldImagesAsync,
    uploadFieldImagesAsync,
} from '../../../core/store/slices/fields/fieldsImagesAsync';
import { InfoModal } from '../../../shared/components/InfoModal/InfoModal';
import Compressor from 'compressorjs';

export const FieldsCreate = ({ isEditing, field }) => {
    const dispatch = useDispatch();
    const imageUploadRef = useRef(null);
    const { locationId } = useParams();

    const [isError, setIsError] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isImagesDirty, setIsImagesDirty] = useState(false);
    const [imagesFiles, setImagesFiles] = useState([]);
    const [displayedImages, setDisplayedImages] = useState([]);

    const createStatus = useSelector(createStatusSelector);
    const updateStatus = useSelector(updateStatusSelector);

    const getDefaultValues = () => {
        if (isEditing) {
            return {
                id: field.id,
                fieldName: field.fieldName,
                minPlayers: field.minPlayers,
                maxPlayers: field.maxPlayers,
                fieldQuality: field.fieldQuality,
                area: field.area,
                costHourFirst: field.costHourFirst ?? '',
                costHourSecond: field.costHourSecond ?? '',
                costHourThird: field.costHourThird ?? '',
                comments: field.comments,
                isFixedPrice: field.isFixedPrice ? '1' : '0',
            };
        }

        return {
            isFixedPrice: '',
        };
    };

    const { register, handleSubmit, formState, control, reset, setError } =
        useForm({
            mode: 'onChange',
            defaultValues: getDefaultValues(),
        });

    const { isDirty, isValid, errors } = formState;

    useEffect(() => {
        if (createStatus && createStatus.status === 200) {
            const fieldId = createStatus.data;

            if (imagesFiles.length) {
                const compressPromises = imagesFiles.map(
                    (file) =>
                        new Promise((resolve, reject) => {
                            new Compressor(file, {
                                quality: 0.5,
                                success: (result) => resolve(result),
                                error: (error) => reject(error),
                            });
                        })
                );

                Promise.all(compressPromises).then((results) => {
                    results = results.map(result => new File([result], result.name));

                    dispatch(
                        uploadFieldImagesAsync({
                            fieldId,
                            images: results,
                        })
                    );
                });
            }

            setIsModalOpen(true);
        }

        if (createStatus && createStatus.status === 500) {
            setIsError(true);
            setIsModalOpen(true);
        }

        if (createStatus && createStatus.status === 400) {
            setError(
                'fieldName',
                { type: 'manual', message: createStatus.message },
                { shouldFocus: true }
            );
        }
    }, [createStatus, imagesFiles, dispatch, setError]);

    useEffect(() => {
        if (updateStatus && updateStatus.status === 200) {
            const fieldId = field.id;

            if (imagesFiles.length) {
                const compressPromises = imagesFiles.map(
                    (file) =>
                        new Promise((resolve, reject) => {
                            new Compressor(file, {
                                quality: 0.5,
                                success: (result) => resolve(result),
                                error: (error) => reject(error),
                            });
                        })
                );

                Promise.all(compressPromises).then((results) => {
                    results = results.map(result => new File([result], result.name));

                    dispatch(
                        uploadFieldImagesAsync({
                            fieldId,
                            images: results,
                        })
                    );
                });
            }

            let imagesToDelete = [];

            field.images.forEach((url) => {
                if (displayedImages.indexOf(url) === -1) {
                    imagesToDelete.push(url);
                }
            });

            if (imagesToDelete.length) {
                dispatch(deleteFieldImagesAsync({ images: imagesToDelete }));
            }

            setIsModalOpen(true);
        }

        if (updateStatus && updateStatus.status === 500) {
            setIsError(true);
            setIsModalOpen(true);
        }

        if (updateStatus && updateStatus.status === 400) {
            setError(
                'fieldName',
                { type: 'manual', message: updateStatus.message },
                { shouldFocus: true }
            );
        }
    }, [dispatch, field, updateStatus, displayedImages, imagesFiles, setError]);

    useEffect(() => {
        if (isEditing && field && field.images?.length) {
            setDisplayedImages(field.images);
        }
    }, [field, isEditing]);

    useEffect(() => {
        if (isEditing) {
            if (arraysEqual(field.images, displayedImages)) {
                setIsImagesDirty(false);
            } else {
                setIsImagesDirty(true);
            }
        }
    }, [field, isEditing, displayedImages]);

    const onFileChange = (files) => {
        const imagesUrl = files.map((file) => URL.createObjectURL(file));

        if (isEditing) {
            setDisplayedImages((images) => [...images, ...imagesUrl]);
        } else {
            setDisplayedImages((images) => [...images, ...imagesUrl]);
        }

        setImagesFiles((imgfiles) => [...imgfiles, ...files]);
    };

    const onRemoveImage = (event, removeIndex) => {
        event.preventDefault();
        event.stopPropagation();
        imageUploadRef.current.value = null;

        if (!isEditing) {
            setImagesFiles(
                imagesFiles.filter((image, index) => index !== removeIndex)
            );
        }

        setDisplayedImages(
            displayedImages.filter((image, index) => index !== removeIndex)
        );
    };

    const isFormDirty = () => {
        if (isEditing) {
            return isImagesDirty || isDirty;
        }

        return isDirty;
    };

    const onModalClose = () => {
        setIsError(false);
        setIsModalOpen(false);

        if (isEditing) {
            dispatch(resetUpdateStatus());
            dispatch(resetCurrentField());
        } else {
            dispatch(resetCreateStatus());
            setImagesFiles([]);
            setDisplayedImages([]);
            reset();
        }
    };

    const onSubmit = (payload) => {
        const data = {
            ...payload,
            minPlayers: parseInt(payload.minPlayers),
            maxPlayers: parseInt(payload.maxPlayers),
            fieldQuality: parseInt(payload.fieldQuality),
            costHourFirst: parseInt(payload.costHourFirst),
            costHourSecond: parseInt(payload.costHourSecond),
            costHourThird: parseInt(payload.costHourThird),
            isFixedPrice: Boolean(parseInt(payload.isFixedPrice)),
            area: parseInt(payload.area),
        };

        if (isEditing) {
            data.id = field.id;
            data.locationId = field.locationId;
            dispatch(updateFieldAsync(data));
        } else {
            data.locationId = parseInt(locationId);
            dispatch(createFieldAsync(data));
        }
    };

    return (
        <section className={styles.FieldsCreate}>
            <PagesHeader>
                {isEditing ? <h1>Editar Cancha</h1> : <h1>Nueva Cancha</h1>}
            </PagesHeader>

            {errors.fieldName && (
                <p className={styles.errorMessage}>
                    {errors.fieldName.message}
                </p>
            )}

            <form onSubmit={handleSubmit(onSubmit)}>
                <div className={styles.container}>
                    {!!displayedImages.length && (
                        <div className={styles.imagesWrapper}>
                            <div className={styles.images}>
                                {displayedImages.map((image, index) => (
                                    <div
                                        className={styles.imageContainer}
                                        key={index}
                                    >
                                        <img
                                            className={styles.image}
                                            src={image}
                                            alt=''
                                        ></img>
                                        <button
                                            className={styles.removeBtn}
                                            onClick={(event) =>
                                                onRemoveImage(event, index)
                                            }
                                        >
                                            <IconApp iconName='close' />
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}

                    <div className={styles.formContent}>
                        <div className={styles.formRow}>
                            <ImageUpload
                                ref={imageUploadRef}
                                handleImagesChange={onFileChange}
                            >
                                <IconApp
                                    iconName='image'
                                    className={styles.imageIcon}
                                />
                                <IconApp iconName='plus' />
                            </ImageUpload>

                            <TextField
                                className={styles.textField}
                                placeholder='Nombre'
                                variant='filled'
                                size='small'
                                fullWidth
                                {...register('fieldName', { required: !isEditing })}
                            />
                        </div>

                        <div className={styles.formRow}>
                            <label className={styles.inputLabel}>
                                Juadores x equipo:{' '}
                            </label>
                            <TextField
                                className={styles.textField}
                                placeholder='min'
                                variant='filled'
                                size='small'
                                fullWidth
                                {...register('minPlayers', {
                                    required: !isEditing,
                                })}
                            />
                            <TextField
                                className={styles.textField}
                                placeholder='max'
                                variant='filled'
                                size='small'
                                fullWidth
                                {...register('maxPlayers', {
                                    required: !isEditing,
                                })}
                            />
                        </div>

                        <div className={styles.formRow}>
                            <label className={styles.inputLabel}>
                                Area (A x L):{' '}
                            </label>
                            <TextField
                                className={styles.textField}
                                placeholder='m2'
                                variant='filled'
                                size='small'
                                type='number'
                                fullWidth
                                {...register('area', { required: !isEditing })}
                            />
                        </div>

                        <div className={styles.formRow}>
                            <label className={styles.inputLabel}>
                                Calidad de grama:{' '}
                            </label>
                            <TextField
                                className={styles.textField}
                                variant='filled'
                                size='small'
                                type='number'
                                fullWidth
                                {...register('fieldQuality', {
                                    required: !isEditing,
                                })}
                            />
                        </div>

                        <div className={styles.formRow}>
                            <label className={styles.label}>
                                Variacion de precio:{' '}
                            </label>
                            <Controller
                                rules={{ required: true }}
                                control={control}
                                name='isFixedPrice'
                                render={({ field }) => (
                                    <RadioGroup
                                        {...field}
                                        className={styles.radioGroup}
                                    >
                                        <FormControlLabel
                                            className={styles.checkbox}
                                            control={<Radio />}
                                            labelPlacement='start'
                                            label='Si'
                                            value={1}
                                        />
                                        <FormControlLabel
                                            className={styles.checkbox}
                                            control={<Radio />}
                                            labelPlacement='start'
                                            label='No'
                                            value={0}
                                        />
                                    </RadioGroup>
                                )}
                            ></Controller>
                        </div>

                        <div className={styles.formRow}>
                            <label className={styles.inputLabel}>
                                Precio horario 1:{' '}
                            </label>
                            <TextField
                                className={styles.textField}
                                placeholder='$/H'
                                variant='filled'
                                size='small'
                                type='number'
                                fullWidth
                                {...register('costHourFirst', {
                                    required: !isEditing,
                                })}
                            />
                        </div>

                        <div className={styles.formRow}>
                            <label className={styles.inputLabel}>
                                Precio horario 2:{' '}
                            </label>
                            <TextField
                                className={styles.textField}
                                placeholder='$/H'
                                variant='filled'
                                size='small'
                                type='number'
                                fullWidth
                                {...register('costHourSecond')}
                            />
                        </div>

                        <div className={styles.formRow}>
                            <label className={styles.inputLabel}>
                                Precio horario 3:{' '}
                            </label>
                            <TextField
                                className={styles.textField}
                                placeholder='$/H'
                                variant='filled'
                                size='small'
                                type='number'
                                fullWidth
                                {...register('costHourThird')}
                            />
                        </div>

                        <TextField
                            className={styles.textField}
                            placeholder='Comentarios'
                            variant='filled'
                            size='small'
                            type='number'
                            rows={4}
                            fullWidth
                            multiline
                            {...register('comments')}
                        />
                    </div>

                </div>
                <div className={styles.formActions}>
                    {isEditing ? (
                        <Button
                            type='submit'
                            variant='contained'
                            className='primary-button-gradient'
                            fullWidth
                            disabled={!isFormDirty() || !isValid}
                        >
                            Guardar cambios
                        </Button>
                    ) : (
                        <Button
                            type='submit'
                            variant='contained'
                            className='primary-button-gradient'
                            fullWidth
                            disabled={!isFormDirty() || !isValid}
                        >
                            Crear Cancha
                        </Button>
                    )}
                </div>
            </form>

            <Dialog fullScreen open={isModalOpen} onClose={onModalClose}>
                {isError && (
                    <InfoModal
                        title='Oh no!'
                        description='Hubo un error, por favor intenta mas tarde'
                        closeModal={onModalClose}
                    />
                )}

                {isEditing && !isError && (
                    <InfoModal
                        title='LISTO!'
                        description='La cancha se actualizo exitosamente'
                        closeModal={onModalClose}
                    />
                )}

                {!isEditing && !isError && (
                    <InfoModal
                        title='LISTO!'
                        description='La cancha se creo exitosamente'
                        closeModal={onModalClose}
                    />
                )}
            </Dialog>
        </section>
    );
};
