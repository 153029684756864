import React, { Fragment, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';

import styles from './Match.module.scss';
import {
    IconButton,
    Menu,
    MenuItem,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useDispatch } from 'react-redux';
import { updateMatchScoreAsync } from '../../../core/store/slices/matches/updateMatchScoreAsync';
import { UpdateScoreDialog } from '../UpdateScoreDialog/UpdateScoreDialog';
import { cancelMatchAsync } from '../../../core/store/slices/matches/cancelMatchAsync';
import { CancelMatchDialog } from '../CancelMatchDialog/CancelMatchDialog';

export default function Match({
    data,
    compact,
    clickable = true,
    isAdminMatch,
}) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = useState(null);
    const [isUpdateStatsOpen, setIsUpdateStatsOpen] = useState(false);
    const [isCancelOpen, setIsCancelOpen] = useState(false);

    const isMenuOpen = Boolean(anchorEl);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleViewDetails = () => {
        if (clickable) {
            navigate(`/partidos/detalles/${data.matchId}`);
        }
    };

    const handleMenuUpdateScore = () => {
        setAnchorEl(null);
        setIsUpdateStatsOpen(true);
    };

    const handleUpdateMatch = () => {
        navigate(`/partidos/editar/${data.matchId}`);
    }

    const handleMenuCancel = () => {
        setAnchorEl(null);
        setIsCancelOpen(true);
    };

    const handleSaveScore = (scoreTeam1, scoreTeam2) => {
        setIsUpdateStatsOpen(false);
        dispatch(updateMatchScoreAsync({ matchId: data.matchId, scoreTeam1, scoreTeam2}));
    };

    const handleCancel = () => {
        setIsCancelOpen(false);
        dispatch(cancelMatchAsync({matchId: data.matchId}));
    };

    return isAdminMatch ? (
        <div
            className={`${styles.match} ${clickable ? styles.clickable : ''}`}>
            <div className={styles.matchDetails} onClick={handleViewDetails}>
                <span>{data?.locationName}</span>
                <span>
                    {format(
                        new Date(data?.matchDate),
                        'EEEEEE-dd/MM/yy-hh:mm bbb',
                        { locale: es }
                    )}
                </span>
                <span>{data?.playersRegisters}</span>
                <span>{data?.matchTypeName}</span>
                <span>{data?.genderTypeName}</span>

                <span>{data?.playersNumber}</span>
            </div>
            <IconButton className={styles.menuButton} onClick={handleMenuOpen}>
                <MoreVertIcon />
            </IconButton>
            <Menu
                open={isMenuOpen}
                anchorEl={anchorEl}
                onClose={handleMenuClose}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
            >
                <MenuItem onClick={handleViewDetails}>Ver detalles</MenuItem>
                <MenuItem onClick={handleMenuUpdateScore}>{data?.matchResult === '-' ? 'Actualizar Marcador' : 'Ver Marcador'}</MenuItem>
                <MenuItem onClick={handleUpdateMatch}>Editar Partido</MenuItem>
                <MenuItem onClick={handleMenuCancel}>Cancelar partido</MenuItem>
            </Menu>

            <UpdateScoreDialog
                isOpen={isUpdateStatsOpen}
                onClose={() => setIsUpdateStatsOpen(false)}
                onSave={handleSaveScore}
                matchResult={data?.matchResult}
            ></UpdateScoreDialog>

            <CancelMatchDialog
                isOpen={isCancelOpen}
                onClose={() => setIsCancelOpen(false)}
                onCancel={handleCancel}
            ></CancelMatchDialog>
        </div>
    ) : (
        <div
            className={`${styles.match} ${clickable ? styles.clickable : ''}`}
            onClick={handleViewDetails}
        >
            <div className={styles.matchDetails}>
                <span>{data?.locationName}</span>
                <span>
                    {format(
                        new Date(data?.matchDate),
                        'EEEEEE-dd/MM/yy-hh:mm bbb',
                        { locale: es }
                    )}
                </span>
                <span>{data?.playersRegisters}</span>

                {!compact && (
                    <Fragment>
                        <span>{data?.matchTypeName}</span>
                        <span>{data?.genderTypeName}</span>
                        <span>{data?.playersNumber}</span>
                    </Fragment>
                )}
            </div>
        </div>
    );
}
