import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getIsLoginInSelector } from '../../../core/store/slices/auth/selectors';

import { IconApp } from '../IconApp/IconApp';

import styles from './Navigation.module.scss';

export const Navigation = () => {
    const navigate = useNavigate();

    const isLogIn = useSelector(getIsLoginInSelector);

    const items = [
        {
            route: '/partidos',
            iconName: 'partidosuntoque',
            alt: 'Partidos un toque',
            logIn: false,
        },
        {
            route: '/mis-partidos',
            iconName: 'mispartidos',
            alt: 'Mis Partidos',
            logIn: true,
        },
        {
            route: '/amigos',
            iconName: 'amigos',
            alt: 'Amigos',
            logIn: true,
        },
        {
            route: '/sedes',
            iconName: 'sedes',
            alt: 'Sedes',
            logIn: false,
        },
    ];

    const handleClick = (menu) => {
        if (!menu.logIn || isLogIn) {
            navigate(menu.route);
        }
    };

    return (
        <nav className={styles.Navigate}>
            {items.map((menu, index) => (
                <IconApp
                    key={index}
                    style={{
                        cursor: !menu.logIn
                            ? 'pointer'
                            : isLogIn
                            ? 'pointer'
                            : 'default',
                    }}
                    onClick={() => handleClick(menu)}
                    iconName={menu.iconName}
                    alt={menu.alt}
                />
            ))}
        </nav>
    );
};
