export const isLoadingMatchesSelector = (state) => state?.matches.isLoading;
export const isLoadingStatsSelector = (state) => state?.matches.isLoadingStats;
export const matchesSelector = (state) => state?.matches.allMatches;
export const totalMatchesSelector = (state) => state?.matches.totalMatches;
export const currentMatchSelector = (state) => state?.matches.currentMatch;
export const joinStatusSelector = (state) => state?.matches.joinStatus;
export const exitStatusSelector = (state) =>  state?.matches.exitStatus;
export const myMatchesSelector = (state) => state?.matches.myMatches;
export const totalMyMatchesSelector = (state) => state?.matches.totalMyMatches;
export const updateStatsStatusSelector = (state) => state?.matches.updateStatsStatus;
export const updateScoreStatusSelector = (state) => state?.matches.updateScoreStatus;
export const cancelStatusSelector = (state) => state?.matches.cancelStatus;
export const createStatusSelector = (state) => state?.matches.createStatus;
export const udpateStatusSelector = (state) => state?.matches.updateStatus;
export const matchStatsSelector = (state) => state?.matches.currentStats;