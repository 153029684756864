import { createTheme } from '@mui/material';
import { common } from '@mui/material/colors';
import { translationKeys } from '../shared/utils/datePickerTranslationKeys';

export const theme = createTheme({
    palette: {
        type: 'dark',
        primary: {
            main: '#E30A65',
        },
        secondary: {
            main: '#35DBFF',
        },
        background: {
            default: '#000000',
            paper: '#2D2D2D',
        },
        error: {
            main: '#f26f6f',
        },
    },
    typography: {
        fontFamily: 'Bebas Neue',
    },
    components: {
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundColor: '#eee'
                }
            },
        },
        MuiFormControlLabel: {
            styleOverrides: {
                label: {
                    '&.Mui-disabled': {
                        color: common.white,
                    },
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    color: common.white,
                    background: '#2d2d2d',
                    borderRadius: '8px',
                    '& input.Mui-disabled': {
                        WebkitTextFillColor: '#5c5c5c',
                    },
                    '& .MuiFilledInput-root': {
                        color: common.white,
                    },
                    '& .MuiInputLabel-root': {
                        display: 'none',
                    },
                },
            },
        },
        MuiInputAdornment: {
            styleOverrides: {
                root: {
                    marginTop: '0 !important',
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    background: '#2d2d2d',
                    color: common.white,
                    padding: '6px 16px',
                    borderRadius: '8px',
                },
                icon: {
                    fill: '#E30A65',
                },
            },
        },
        MuiMenu: {
            styleOverrides: {
                paper: {
                    backgroundColor: '#5c5c5c !important',
                    maxHeight: 'calc(100%  / 2) !important'
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    color: common.white,
                },
            },
        },
        MuiRadio: {
            styleOverrides: {
                root: {
                    color: common.white,
                    '&.Mui-disabled': {
                        color: 'gray',
                    },
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: common.white,
                    '&.Mui-disabled': {
                        color: 'gray',
                    },
                },
            },
        },
        MuiLocalizationProvider: {
            defaultProps: {
                localeText: { ...translationKeys },
            },
        },
        MuiCalendarPicker: {
            styleOverrides: {
                MuiPickersDay: {
                    backgroundColor: 'gray'
                }
            }
        },
        MuiPickersDay: {
            styleOverrides: {
                root: {
                    backgroundColor: 'rgba(0,0,0,0.07)'
                }
            }
        },
        MuiSwitch: {
            styleOverrides: {
                switchBase: {
                    '&.Mui-checked+.MuiSwitch-track': {
                        borderColor: 'transparent'
                    }
                },
                track: {
                    border: '1px solid',
                },
            }
        }
    },
});
