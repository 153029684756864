import { configureStore } from "@reduxjs/toolkit";

import authReducer from "./slices/auth";
import uiReducer from "./slices/ui";
import playersReducer from "./slices/players";
import headquartersReducer from "./slices/headquarters";
import matchesReducer from './slices/matches';
import settingsReducer from './slices/settings';
import friendsReducer from './slices/friends';
import fieldsReducer from './slices/fields';
import receiptsSlice from './slices/receipts';

export const store = configureStore({
    reducer: {
        auth: authReducer,
        headquarters: headquartersReducer,
        players: playersReducer,
        ui: uiReducer,
        matches: matchesReducer,
        settings: settingsReducer,
        friends: friendsReducer,
        fields: fieldsReducer,
        receipts: receiptsSlice
    },
});
