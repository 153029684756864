const globals = {
    imageBasePath: "/assets/images",
    logosBasePath: "/assets/icons",
    waUrl: "https://api.whatsapp.com/send?phone=573122673240",
    facebookRedirect: "/facebook_response",
    facebookLoginUrl:
        "https://www.facebook.com/v14.0/dialog/oauth?client_id={clientId}&redirect_uri={redirectUri}&response_type=token&scope=email",
    googleRedirect: "/google_response",
    googleLoginUrl:
        "https://accounts.google.com/o/oauth2/v2/auth?scope=profile+email&access_type=offline&include_granted_scopes=true&response_type=code&redirect_uri={redirectUri}&client_id={clientId}",
    matchTypesId: {
        open: 9,
        teams: 10,
    },
    settingsIds: {
        GenderType: 1,
        PositionType: 2,
        RecordType: 3,
        MatchType: 4,
        MatchGender: 5,
    },
    roles: {
        superAdmin: "SuperAdmin",
    },
};

export default globals;
