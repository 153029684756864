import { useNavigate } from 'react-router-dom';

import { Button } from '@mui/material';

import { AuthHeader } from '../../../shared/components/AuthHeader/AuthHeader';
import { IconApp } from '../../../shared/components/IconApp/IconApp';
import globals from '../../../shared/constants/global.constant';

import styles from './AuthSelection.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
    externalLoginDataSelector,
    isLoadingSelector,
} from '../../../core/store/slices/auth/selectors';
import { useEffect, useState } from 'react';
import { getExternalLoginAsync } from '../../../core/store/slices/auth/loginAsync';
import { Loader } from '../../../shared/components/loader/Loader';

export const AuthSelection = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [facebookUrl, setFacebookUrl] = useState('');
    const [googleUrl, setGoogleUrl] = useState('');

    const isLoading = useSelector(isLoadingSelector);
    const externalLoginData = useSelector(externalLoginDataSelector);

    useEffect(() => {
        dispatch(getExternalLoginAsync());
    }, [dispatch]);

    useEffect(() => {
        if (externalLoginData) {
            const { facebookInfo, googleInfo } = externalLoginData;

            setFacebookUrl(
                globals.facebookLoginUrl
                    .replace('{clientId}', facebookInfo.appId)
                    .replace(
                        '{redirectUri}',
                        window.location.origin + globals.facebookRedirect
                    )
            );

            setGoogleUrl(
                globals.googleLoginUrl
                    .replace('{clientId}', googleInfo.appId)
                    .replace(
                        '{redirectUri}',
                        window.location.origin + globals.googleRedirect
                    )
            );
        }
    }, [externalLoginData]);

    return isLoading ? (
        <Loader />
    ) : (
        <section className={styles.AuthSelection}>
            <AuthHeader
                navigateTo='/'
                headingText='Quieres jugar fútbol en Medellin?'
            />

            <div className={styles.body}>
                <p>
                    Únete a <span>UN TOQUE</span> y juega tanto como TU quieras.
                </p>
                <p>
                    Partidos todas las semanas para que solo tengas que unirte y
                    venir a jugar.
                </p>
                <p>
                    Porque si juegas a <span>UN TOQUE</span> todos jugamos más.
                </p>
            </div>

            <div className={styles.actions}>
                <Button
                    className='primary-button-gradient'
                    variant='contained'
                    onClick={() => navigate('login')}
                >
                    INICIAR SESIÓN
                </Button>

                <Button
                    className='primary-button-gradient'
                    variant='contained'
                    onClick={() => navigate('registro')}
                >
                    REGISTRARME
                </Button>
            </div>

            <section className={styles.socialNetworkWrapper}>
                <h2>O USA TU CUENTA DE:</h2>
                <div>
                    <a href={facebookUrl} className={styles.socialLink}>
                        <Button
                            type='button'
                            className='facebook-button'
                            variant='contained'
                        >
                            <IconApp
                                iconName='facebook'
                                width='25'
                                height='25'
                                alt='Icono de facebook'
                            />
                        </Button>
                    </a>

                    <a href={googleUrl} className={styles.socialLink}>
                        <Button
                            type='button'
                            className='google-button'
                            variant='contained'
                        >
                            <IconApp
                                iconName='google'
                                width='25'
                                height='25'
                                alt='Icono de google'
                            />
                        </Button>
                    </a>
                </div>
            </section>
        </section>
    );
};
