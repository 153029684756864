import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setLoginUser, logOut } from "../store/slices/auth";

import { getUserSelector, isSuperAdminSelector } from "../store/slices/auth/selectors";

export const useAuth = () => {
    const dispatch = useDispatch();
    const localStorageUser = localStorage.getItem("user");
    const user = useSelector(getUserSelector);
    const isSuperAdmin = useSelector(isSuperAdminSelector);
    const [isAuth, setIsAuth] = useState(!!user);

    useEffect(() => {
        if (localStorageUser && !user) {
            const parsedUser = JSON.parse(localStorageUser);

            dispatch(setLoginUser(parsedUser));
        }

        if (!localStorageUser) {
            dispatch(logOut());
            setIsAuth(false);
        }

        setIsAuth(!!user);
    }, [user, localStorageUser, dispatch]);

    return { isAuth, isSuperAdmin };
};
