import axios from 'axios';
import { setTokenExpired } from '../store/slices/auth';


const axiosClient = axios.create({
    baseURL: 'https://untoquebackapi.azurewebsites.net/api',
});

const refreshToken = () => {
    const user = getLocaluser();

    return axiosClient.post('/Login/RefreshToken', {
        ...user,
    });
};

const getLocaluser = () => {
    const user = JSON.parse(localStorage.getItem('user'));

    return user;
};

const deletelocalUser = () => {
    localStorage.removeItem('user');
};

const setLocalUser = (user) => {
    localStorage.setItem('user', JSON.stringify(user));
};

const isRefresTokenURL = (url) => {
    return url.includes('/RefreshToken');
};

const setupAxiosInterceptors = (store) => {
    axiosClient.interceptors.request.use(
        (config) => {
            const user = getLocaluser();
            const isRefreshTokenUrl = isRefresTokenURL(config?.url);
    
            if (user) {
                if (isRefreshTokenUrl) {
                    config.headers.Authorization = `Bearer ${user.refreshTokenValue}`;
                } else {
                    config.headers.Authorization = `Bearer ${user.tokenValue}`;
                }
            }
    
            return config;
        },
        (error) => Promise.reject(error)
    );
    
    axiosClient.interceptors.response.use(
        (response) => {
            return response;
        },
        async (error) => {
            const originalConfig = error.config;
    
            if (error?.response) {
                // Access Token was expired
                const isErrorFromRefresh = isRefresTokenURL(
                    error?.response?.request?.responseURL
                );
        
                if (
                    error?.response?.status === 401 &&
                    !originalConfig._retry &&
                    !isErrorFromRefresh
                ) {
                    originalConfig._retry = true;
    
                    try {
                        const response = await refreshToken();
                        const user = await response.data;
                        setLocalUser(user);
    
                        axiosClient.defaults.headers.common.Authorization = `Bearer ${user.tokenValue}`;
    
                        return axiosClient(originalConfig);
                    } catch (_error) {
                        deletelocalUser();
                        store.dispatch(setTokenExpired(true));
    
                        return Promise.reject(_error);
                    }
                }
            }
    
            return Promise.reject(error);
        }
    );
}

export { axiosClient, setupAxiosInterceptors };
