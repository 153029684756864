import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setTokenExpired } from '../store/slices/auth';
import { isTokenExpiredSelector } from '../store/slices/auth/selectors';

export const useTokenExpired = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isTokenExpired = useSelector(isTokenExpiredSelector);

    useEffect(() => {
        if (isTokenExpired) {
            dispatch(setTokenExpired(false));
            navigate('/');
        }
    }, [isTokenExpired, navigate, dispatch]);
};
