import { createAsyncThunk } from '@reduxjs/toolkit';

import {
    facebookLogin,
    getExternalLoginData,
    googleLogin,
    loginUser,
} from '../../../services/loginService';

export const loginUserAsync = createAsyncThunk(
    'auth/loginUser',
    async (payload) => {
        const res = await loginUser(payload);
        const data = await res.data;

        try {
            localStorage.setItem('user', JSON.stringify(data));
        } catch (e) {}

        return data;
    }
);

export const getExternalLoginAsync = createAsyncThunk(
    'auth/getExternalLoginData',
    async () => {
        const res = await getExternalLoginData();
        const data = await res.data;

        return data;
    }
);

export const facebookLoginAsync = createAsyncThunk(
    'auth/facebookLogin',
    async (payload) => {
        try {
            const res = await facebookLogin(payload);
            const data = await res.data;

            localStorage.setItem('user', JSON.stringify(data));

            return {
                status: res.status,
                data: data,
            };
        } catch (error) {
            const res = error.response;

            return {
                status: res?.status || 500,
                data: res?.data,
            };
        }
    }
);

export const googleLoginAsync = createAsyncThunk(
    'auth/googleLogin',
    async (payload) => {
        try {
            const { code, redirectUrl } = payload;
            const res = await googleLogin(code, redirectUrl);
            const data = await res.data;

            localStorage.setItem('user', JSON.stringify(data));

            return {
                status: res.status,
                data: data,
            };
        } catch (error) {
            const res = error.response;

            return {
                status: res?.status || 500,
                data: res?.data,
            };
        }
    }
);
