export const initialState = {
    isLoadingLogin: false,
    isLoadingRegSettings: true,
    isLoginIn: false,
    isRegistering: false,
    registerStatus: null,
    isUpdating: false,
    updateStatus: null,
    settings: {},
    user: null,
    loginStatus: null,
    externalLoginData: null,
    isTokenExpired: false
};
