import { createAsyncThunk } from '@reduxjs/toolkit';
import globals from '../../../../shared/constants/global.constant';
import { getSettingById, getSettings } from '../../../services/settingsService';

export const getSettingsAsync = createAsyncThunk('settings/getSettings', async () => {
    const res = await getSettings();
    const data = await res.data;

    return data;
});

export const getSettingByIdAsync = (id) => {
    return createAsyncThunk('settings/getSettingByIdAsync', async () => {
        const res = await getSettingById(id);
        const data = await res.data;
    
        return {
            id: id,
            values: data
        };
    });
};

export const getGenderSettingsAsync = getSettingByIdAsync(globals.settingsIds.GenderType);

export const getPositionSettingsAsync = getSettingByIdAsync(globals.settingsIds.PositionType);

export const getRecordTypesSettingsAsync = getSettingByIdAsync(globals.settingsIds.RecordType);

export const getMatchTypesSettingsAsync = getSettingByIdAsync(globals.settingsIds.MatchType);

export const getMatchGendersSettingsAsync = getSettingByIdAsync(globals.settingsIds.MatchGender);

