import { createAsyncThunk } from '@reduxjs/toolkit';
import { cancelMatch } from '../../../services/matchesService';

export const cancelMatchAsync = createAsyncThunk(
    'matches/cancel',
    async (payload) => {
        const { matchId } = payload;

        try {
            const res = await cancelMatch(matchId);

            return res.status;
        } catch (error) {
            return error?.response?.status || 500;
        }
    }
);
