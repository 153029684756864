import React, { useEffect, useState } from 'react';
import { PagesHeader } from '../../../shared/components/PagesHeader/PagesHeader';

import styles from './MatchDetails.module.scss';
import Match from '../Match/Match';
import {
    isLoadingMatchesSelector,
    currentMatchSelector,
} from '../../../core/store/slices/matches/selectors';
import { getMatchDetailsAsync } from '../../../core/store/slices/matches/getMatchDetailAsync';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IconApp } from '../../../shared/components/IconApp/IconApp';
import { Teams } from '../Teams/Teams';
import { Button, Dialog } from '@mui/material';
import { getIsLoginInSelector } from '../../../core/store/slices/auth/selectors';
import { InfoModal } from '../../../shared/components/InfoModal/InfoModal';
import { Loader } from '../../../shared/components/loader/Loader';
import constants from '../../../shared/constants/global.constant';

export function MatchDetails() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { state } = useLocation();
    const isLoading = useSelector(isLoadingMatchesSelector);
    const isLoginIn = useSelector(getIsLoginInSelector);
    const match = useSelector(currentMatchSelector);
    const { id } = useParams();
    const [selectedTeam, setSelectedTeam] = useState(1);
    const [isOpenMatch, setIsOpenMatch] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isPastMatch, setIsPastMatch] = useState(false);

    useEffect(() => {
        dispatch(getMatchDetailsAsync(id));
    }, [dispatch, id]);

    useEffect(() => {
        if (match) {
            setIsOpenMatch(match?.matchTypeId === constants.matchTypesId.open);
            setIsPastMatch(
                match.resultTeam1 !== null && match.resultTeam2 !== null
            );
        }
    }, [match]);

    const handleTeamChange = (team) => {
        setSelectedTeam(team);
    };

    const handleJoinClick = () => {
        if (!isLoginIn) {
            navigate('/auth');
            return;
        }

        if (match?.isUserInMatch) {
            navigate(`/partidos/cancelar/${match.matchId}`, {
                state: { isCancel: true, matchId: match.matchId },
            });
            return;
        }

        navigate(`/partidos/pagar/${match.matchId}`, {
            state: {
                isCancel: false,
                matchId: match.matchId,
                isOpenMatch,
                selectedTeam,
            },
        });
    };

    const onModalClose = () => {
        setIsModalOpen(false);
    };

    return isLoading ? (
        <Loader></Loader>
    ) : (
        <section className={styles.matchDetails}>
            <p>Partidos</p>

            <PagesHeader navigateTo={state?.blockReturn ? '/partidos' : -1}>
                <h1>UN TOQUE</h1>
            </PagesHeader>

            <section className={styles.container}>
                <div className={styles.header}>
                    <span>Lugar</span>
                    <span>Fecha y hora</span>
                    <span>jugadores</span>
                </div>
                <div className={styles.matchWrapper}>
                    {match && (
                        <Match data={match} compact clickable={false}></Match>
                    )}
                </div>

                <img
                    className={styles.locationImage}
                    src={match?.locationImage}
                    alt={match?.locationName}
                />

                <div className={styles.location}>
                    <IconApp
                        className={styles.locationIcon}
                        width={15}
                        height={15}
                        iconName='location'
                        alt='Logo de localización'
                    />

                    <a
                        href={match?.locationAddress}
                        className={styles.locationAddress}
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        {match?.locationAddress}
                    </a>
                </div>

                <Teams
                    isOpenMatch={isOpenMatch}
                    isPastMatch={isPastMatch}
                    players={match?.players}
                    totalPlayers={match?.quota}
                    selectedTeam={selectedTeam}
                    onTeamChange={handleTeamChange}
                ></Teams>

                {!isPastMatch && (
                    <Button
                        className={styles.joinButton}
                        color='primary'
                        variant='contained'
                        fullWidth
                        onClick={handleJoinClick}
                    >
                        {match?.isUserInMatch ? 'SALIR DEL PARTIDO' : 'UNIRSE'}
                    </Button>
                )}
            </section>

            <Dialog fullScreen open={isModalOpen} onClose={onModalClose}>
                <InfoModal
                    title='Oh no!'
                    description='Hubo un error, por favor intenta mas tarde'
                    closeModal={onModalClose}
                />
            </Dialog>
        </section>
    );
}
