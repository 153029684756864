import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom';
import { getHeadquartersDetailAsync } from '../../../core/store/slices/headquarters/getHeadquartersDetailAsync';
import { currentHeadquarterSelector, isLoadingSelector } from '../../../core/store/slices/headquarters/selectors';
import { Loader } from '../../../shared/components/loader/Loader';
import { HeadquartersCreate } from '../HeadquartersCreate/HeadquartersCreate';

export const HeadquarterUpdate = () => {
    const { name } = useParams();
    const dispatch = useDispatch();
    const isLoading = useSelector(isLoadingSelector);
    const currentHeadquarter = useSelector(currentHeadquarterSelector);

    useEffect(() => {
        if (!currentHeadquarter) {
            dispatch(getHeadquartersDetailAsync(name));
        }
    }, [currentHeadquarter, name, dispatch]);


  return isLoading || !currentHeadquarter ? (
    <Loader></Loader>
  ) : (
      <HeadquartersCreate isEditing={true} currentHeadquarter={currentHeadquarter} />
  );
}
