import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
} from '@mui/material';
import { useState } from 'react';

export const UpdateScoreDialog = ({ isOpen, onClose, onSave, matchResult }) => {
    const [scoreTeam1, setScoreTeam1] = useState('');
    const [scoreTeam2, setScoreTeam2] = useState('');

    const handleClose = () => {
        if (onClose) onClose();
    };

    const handleSave = () => {
        if (onSave) onSave(scoreTeam1, scoreTeam2);
    };

    useState(() => {
        if (matchResult !== '-') {
            const results = matchResult.split('-');
            setScoreTeam1(results[0]);
            setScoreTeam2(results[1]);
        }
    }, [matchResult]);

    return (
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>
                {matchResult !== '-' ? 'Marcador del partido' : 'Actualizar Marcador'}
            </DialogTitle>
            <DialogContent
                sx={{
                    '&': {
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '8px',
                    },
                }}
            >
                <TextField
                    placeholder='Resultado equipo 1'
                    variant='filled'
                    type='number'
                    value={scoreTeam1}
                    onChange={(e) => setScoreTeam1(e.target.value)}
                    sx={{
                        input: {
                            textAlign: 'center',
                        },
                    }}
                    InputProps={{
                        readOnly: matchResult !== '-',
                    }}
                ></TextField>
                <TextField
                    placeholder='Resultado equipo 2'
                    type='number'
                    variant='filled'
                    value={scoreTeam2}
                    onChange={(e) => setScoreTeam2(e.target.value)}
                    sx={{
                        input: {
                            textAlign: 'center',
                        },
                    }}
                    InputProps={{
                        readOnly: matchResult !== '-',
                    }}
                ></TextField>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cerrar</Button>
                {matchResult === '-' && (
                    <Button
                        onClick={handleSave}
                        autoFocus
                        disabled={!scoreTeam1 || !scoreTeam2}
                    >
                        Guardar
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};
