import { axiosClient } from './axiosInstance';

export const getAllMatches = (
    genderId,
    matchTypeId,
    pageSize,
    currentPage,
    locationId,
    onlyWithoutResult
) => {
    const params = getParams({genderId, matchTypeId, pageSize, currentPage, locationId, onlyWithoutResult});
    const url = `/Matches/GetAvailableMatches`;

    return axiosClient.get(url, { params });
};

export const getMatchById = (id) => {
    const url = `/Matches/GetMatchById?matchId=${id}`;

    return axiosClient.get(url);
};

export const getMatchDetails = (id) => {
    const url = `/Matches/GetMatchDetailsByMatchId?matchId=${id}`;

    return axiosClient.get(url);
};

export const joinMatch = (matchId, team) => {
    const url = `/Matches/JoinMatch?matchId=${matchId}&team=${team}`;

    return axiosClient.post(url);
};

export const exitMatch = (matchId) => {
    const url = `/Matches/RemoveMatch?matchId=${matchId}`;

    return axiosClient.delete(url);
};

export const getMyMatches = (pageSize, currentPage) => {
    const url = '/Matches/GetMyMatches';
    const params = getParams({pageSize, currentPage});

    return axiosClient.get(url, { params });
};

export const updateMatchStats = (matchId, goals, assists) => {
    const url = `/Matches/UpdateStatisticMatchDetail?matchId=${matchId}&goals=${goals}&assist=${assists}`;

    return axiosClient.put(url);
};

export const updateMatchScore = (matchId, scoreTeam1, scoreTeam2) => {
    const url = `/Matches/UpdateScoreToMatch?matchId=${matchId}&scoreTeam1=${scoreTeam1}&scoreTeam2=${scoreTeam2}`;
    
    return axiosClient.put(url);
};

export const cancelMatch = (matchId) => {
    const url = `/Matches/CancelMatch?matchId=${matchId}`;

    return axiosClient.delete(url);
};

export const createMatch = (data) => {
    const url = '/Matches/CreateMatch';

    return axiosClient.post(url, data);
}

export const updateMatch = (data) => {
    const url = '/Matches/UpdateMatch';

    return axiosClient.put(url, data);
}

export const getMatchStatsByPlayer = () => {
    const url = 'Matches/GetStatisticsByPlayer';

    return axiosClient.get(url);
}

function getParams({genderId, matchTypeId, pageSize, currentPage, locationId, onlyWithoutResult}) {
    const params = {};

    if (genderId !== undefined) {
        params.genderId = genderId;
    }

    if (matchTypeId !== undefined) {
        params.matchTypeId = matchTypeId;
    }

    if (pageSize !== undefined) {
        params.pageSize = pageSize;
    }

    if (currentPage !== undefined) {
        params.curPage = currentPage;
    }

    if (locationId !== undefined) {
        params.locationId = locationId;
    }

    if (onlyWithoutResult !== undefined) {
        params.onlyMatcheWithOutResult = onlyWithoutResult;
    }

    return params;
}
