import { useEffect } from "react";

import { Outlet } from "react-router-dom";
import { useDispatch } from "react-redux";

import {
    changeBackground,
    changeToqueMobileDisplayed,
} from "../../core/store/slices/ui";
import styles from "./Auth.module.scss";

export const Auth = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(
            changeBackground({
                desktop: "bg1-desktop.png",
                mobile: "bg1-mobile.png",
            })
        );

        dispatch(changeToqueMobileDisplayed(false));
    }, [dispatch]);

    return (
        <section className={styles.Auth}>
            <Outlet />
        </section>
    );
};
