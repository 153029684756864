import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    uploadFieldImage,
    deleteFieldImage,
} from '../../../services/fieldService';

export const uploadFieldImagesAsync = createAsyncThunk(
    'fields/uploadImages',
    async (payload) => {
        const { fieldId, images } = payload;
        const res = await uploadFieldImage(fieldId, images);

        return res.status;
    }
);

export const deleteFieldImagesAsync = createAsyncThunk(
    'fields/removeImages',
    async (payload) => {
        const { images } = payload;
        const res = await deleteFieldImage(images);

        return res.status;
    }
);
