import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getFieldByIdAsync } from '../../../core/store/slices/fields/getFieldByIdAsync';
import {
    currentFieldSelector,
} from '../../../core/store/slices/fields/selectors';
import { Loader } from '../../../shared/components/loader/Loader';
import { FieldsCreate } from '../FieldsCreate/FieldsCreate';

export const FieldsUpdate = () => {
    const dispatch = useDispatch();
    const { id } = useParams();

    const currentField = useSelector(currentFieldSelector);

    useEffect(() => {
        if (!currentField) {
            dispatch(getFieldByIdAsync({ fieldId: id }));
        }
    }, [id, currentField, dispatch]);

    return !currentField  ? (
        <Loader></Loader>
    ) : (
        <FieldsCreate isEditing={true} field={currentField}></FieldsCreate>
    );
};
