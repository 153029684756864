import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getFieldsAsync } from '../../../core/store/slices/fields/getFieldsAsync';
import {
    allFieldsSelector,
    isLoadingSelector,
    totalFieldsSelector,
} from '../../../core/store/slices/fields/selectors';
import { PagesHeader } from '../../../shared/components/PagesHeader/PagesHeader';
import styles from './FieldsList.module.scss';
import InfiniteScroll from 'react-infinite-scroll-component';
import { resetCurrentField } from '../../../core/store/slices/fields';
import { Loader } from '../../../shared/components/loader/Loader';

export const FieldsList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { locationId } = useParams();

    const isLoading = useSelector(isLoadingSelector);
    const fields = useSelector(allFieldsSelector);
    const totalFields = useSelector(totalFieldsSelector);

    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize] = useState(10);

    useEffect(() => {
        dispatch(getFieldsAsync({ locationId, currentPage, pageSize }));
    }, [dispatch, locationId, currentPage, pageSize]);

    useEffect(() => {
        dispatch(resetCurrentField());
    }, [dispatch]);

    const handleLoadMore = () => {
        setCurrentPage(currentPage + 1);
    };

    const handleFieldClick = (id) => {
        navigate(`/canchas/editar/${id}`);
    };
    const handleCreateClick = () => {
        navigate(`/canchas/crear/${locationId}`);
    };

    return (
        <section className={styles.FieldsList}>
            <PagesHeader>
                <h1>Canchas</h1>
            </PagesHeader>

            <section className={styles.content}>
                <div id='fieldsList' className={styles.fieldsContainer}>
                    <InfiniteScroll
                        dataLength={fields.length}
                        next={handleLoadMore}
                        hasMore={fields.length < totalFields}
                        scrollableTarget='fieldsList'
                        loader={
                            <div className={styles.loadingSmall}>
                                <Loader size='small' />
                            </div>
                        }
                    >
                        {isLoading && !fields.length && (
                            <div className={styles.loading}>
                                <Loader />
                            </div>
                        )}

                        {!isLoading && !fields.length && (
                            <div className={styles.emptyData}>
                                <h3>No se encontraron canchas</h3>
                            </div>
                        )}

                        {fields?.map((field) => (
                            <div
                                className={styles.field}
                                key={field.id}
                                onClick={() => handleFieldClick(field.id)}
                            >
                                <div className={styles.fieldImage}>
                                    <img
                                        src={field?.defaultImage}
                                        alt={field.fieldName}
                                    />
                                </div>
                                <span>{field.fieldName}</span>
                            </div>
                        ))}
                    </InfiniteScroll>
                </div>
                <Button
                    variant='contained'
                    onClick={handleCreateClick}
                    className={styles.createButton}
                    fullWidth
                >
                    Nueva cancha
                </Button>
            </section>
        </section>
    );
};
