import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { PagesHeader } from '../../../shared/components/PagesHeader/PagesHeader';

import {
    headquartersSelector,
    isLoadingSelector,
} from '../../../core/store/slices/headquarters/selectors';
import { getHeadquartersAsync } from '../../../core/store/slices/headquarters/getHeadquartersAsync';

import styles from './ListHeadquarters.module.scss';
import { isSuperAdminSelector } from '../../../core/store/slices/auth/selectors';
import { Button } from '@mui/material';
import { Loader } from '../../../shared/components/loader/Loader';

export const ListHeadquarters = () => {
    const dispatch = useDispatch();
    const isLoading = useSelector(isLoadingSelector);
    const headquarters = useSelector(headquartersSelector);
    const navigate = useNavigate();
    const isSuperAdmin = useSelector(isSuperAdminSelector);

    useEffect(() => {
        dispatch(getHeadquartersAsync());
    }, [dispatch]);

    const handleCreateClick = () => {
        navigate('/sedes/crear');
    };

    return isLoading ? (
        <Loader></Loader>
    ) : (
        <section className={styles.ListHeadquarters}>
            <PagesHeader>
                <h1>INSTALACIONES</h1>
            </PagesHeader>

            <section className={styles.container}>
                {headquarters?.map((item) => (
                    <div
                        onClick={() =>
                            navigate(`/sedes/detalles/${item.name}`, {
                                state: { id: item.id },
                            })
                        }
                        className={styles.card}
                        key={item.id}
                    >
                        <h2>{item.name}</h2>

                        <div className={styles.imageWrapper}>
                            <img src={item?.defaultImage} alt={item.name} />
                        </div>

                        <p>Ver Partidos</p>
                    </div>
                ))}
            </section>

            {isSuperAdmin && (
                <Button
                    variant='contained'
                    onClick={handleCreateClick}
                    className={styles.createButton}
                    fullWidth
                >
                    Nueva instalación
                </Button>
            )}
        </section>
    );
};
