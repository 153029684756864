import { IconApp } from "../IconApp/IconApp";
import styles from "./Footer.module.scss";

export const Footer = () => {
    return (
        <footer className={styles.footer}>
            <div className={styles.footerWrapper}>
                <p>CONTACTO</p>

                <div className={styles.socialNetworks}>
                    <div className={styles.socialNetworkItem}>
                        <IconApp
                            className={styles.iconApp}
                            iconName="instagram"
                            alt="Logo de instagram"
                        />
                        <a
                            href="https://www.instagram.com/untoque.co/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <p>@UNTOQUE.CO</p>
                        </a>
                    </div>
                    <div className={styles.socialNetworkItem}>
                        <IconApp
                            className={styles.iconApp}
                            iconName="mail"
                            alt="Logo de Mail"
                        />

                        <a href="mailTo:UNTOQUEMED@GMAIL.COM">
                            <p>UNTOQUEMED@GMAIL.COM</p>
                        </a>
                    </div>
                    <div className={styles.socialNetworkItem}>
                        <IconApp
                            className={styles.iconApp}
                            iconName="whatsapp"
                            alt="Logo de whatsapp"
                        />
                        <a
                            href="https://api.whatsapp.com/send?phone=3121231234"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <p>3121231234</p>
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    );
};
