import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getMatchByIdAsync } from '../../../core/store/slices/matches/getMatchByIdAsync';
import {
    currentMatchSelector,
    isLoadingMatchesSelector,
} from '../../../core/store/slices/matches/selectors';
import { Loader } from '../../../shared/components/loader/Loader';
import { CreateMatch } from '../CreateMatch/CreateMatch';

export const UpdateMatch = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const isLoading = useSelector(isLoadingMatchesSelector);
    const match = useSelector(currentMatchSelector);

    useEffect(() => {
        if (!match) {
            dispatch(getMatchByIdAsync(id));
        }
    }, [match, id, dispatch]);

    return isLoading || !match ? (
        <Loader></Loader>
    ) : (
        <CreateMatch isEditing={true} match={match} />
    );
};
