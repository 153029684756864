import { createAsyncThunk } from '@reduxjs/toolkit';
import { exitMatch } from '../../../services/matchesService';

export const exitMatchAsync = createAsyncThunk('matches/exitMatch', async (payload) => {
        const { matchId } = payload || {};

        try {
            const res = await exitMatch(matchId);
            
            return res.status;
        } catch (error) {
            return error?.response?.status || 500;
        }
    }
);
