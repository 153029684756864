import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { Button, TextField } from "@mui/material";

import { AuthHeader } from "../../../shared/components/AuthHeader/AuthHeader";
import { emailPattern, passPattern } from "../../../shared/constants/patterns";

import {
    getIsLoginInSelector,
    getUserSelector,
} from "../../../core/store/slices/auth/selectors";
import { loginUserAsync } from "../../../core/store/slices/auth/loginAsync";

import styles from "./AuthLogin.module.scss";
import { Loader } from '../../../shared/components/loader/Loader';

export const AuthLogin = () => {
    const { register, handleSubmit, formState } = useForm({ mode: "onChange" });
    const { isDirty, isValid, errors } = formState;

    const dispatch = useDispatch();
    const isLoginLoading = useSelector(getIsLoginInSelector);
    const user = useSelector(getUserSelector);
    const navigate = useNavigate();

    const onSubmit = (payload) => {
        dispatch(loginUserAsync(payload));
    };

    useEffect(() => {
        if (user) {
            navigate("/");
        }
    }, [user, navigate]);

    return isLoginLoading ? (
        <Loader></Loader>
    ) : (
        <section className={styles.AuthLogin}>
            <AuthHeader navigateTo="/auth" />

            <section className={styles.formWrapper}>
                <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
                    <div className={styles.singleField}>
                        <TextField
                            placeholder="CORREO"
                            variant="filled"
                            size="small"
                            fullWidth
                            error={!!errors?.email}
                            {...register("email", {
                                required: true,
                                pattern: emailPattern,
                            })}
                        />
                        {errors?.email &&
                            errors?.email?.type === "required" && (
                                <p className="fieldError">
                                    Este campo es requerido
                                </p>
                            )}
                        {errors?.email && errors?.email?.type === "pattern" && (
                            <p className="fieldError">
                                Debe ingresar un email valido
                            </p>
                        )}
                    </div>

                    <div className={styles.singleField}>
                        <TextField
                            placeholder="CONTRASEÑA"
                            variant="filled"
                            size="small"
                            fullWidth
                            error={!!errors?.password}
                            type="password"
                            {...register("password", {
                                required: true,
                                pattern: passPattern,
                            })}
                        />
                        {errors?.password &&
                            errors?.password?.type === "required" && (
                                <p className="fieldError">
                                    Este campo es requerido
                                </p>
                            )}
                        {errors?.password &&
                            errors?.password?.type === "pattern" && (
                                <p className="fieldError">
                                    Debe tener entre 6 y 16 caracteres, una
                                    mayuscula, una minuscula, un caracter
                                    especial y un numero.
                                </p>
                            )}
                    </div>

                    <Button
                        type="submit"
                        className="primary-button-gradient"
                        variant="contained"
                        disabled={!isDirty || !isValid || isLoginLoading}
                    >
                        INICIAR SESIÓN
                    </Button>
                </form>
            </section>
        </section>
    );
};
