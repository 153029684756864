import { createAsyncThunk } from '@reduxjs/toolkit';
import { joinMatch } from '../../../services/matchesService';

export const joinMatchAsync = createAsyncThunk('matches/joinMatch', async (payload) => {
        const { matchId, team } = payload || {};

        try {
            const res = await joinMatch(matchId, team);

            return res.status;
        } catch (error) {
            return error?.response?.status || 500;
        }
    }
);
