import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { App } from './App';
import { Auth } from './features/Auth/Auth';
import { AuthLogin } from './features/Auth/AuthLogin/AuthLogin';
import { AuthRegister } from './features/Auth/AuthRegister/AuthRegister';
import { AuthSelection } from './features/Auth/AuthSelection/AuthSelection';
import { CreateMatch } from './features/Matches/CreateMatch/CreateMatch';
import { Friends } from './features/Friends/Friends';
import { Headquarters } from './features/Headquarters/Headquarters';
import { HeadquartersDetails } from './features/Headquarters/HeadquartersDetails/HeadquartersDetails';
import { Home } from './features/Home/Home';
import { ListFriends } from './features/Friends/ListFriends/ListFriends';
import { ListHeadquarters } from './features/Headquarters/ListHeadquarters/ListHeadquarters';
import { ListMatches } from './features/Matches/ListMatches/ListMatches';
import { ListMyMatches } from './features/MyMatches/ListMyMatches/ListMyMatches';
import { Matches } from './features/Matches/Matches';
import { MatchDetails } from './features/Matches/MatchDetails/MatchDetails';
import { MyMatches } from './features/MyMatches/MyMatches';
import { NotFound } from './features/NotFound/NotFound';
import { Profile } from './features/Profile/Profile';
import { ProfileDetails } from './features/Profile/ProfileDetails/ProfileDetails';
import { Payment } from './features/Matches/Payment/Payment';
import { ProfileUpdate } from './features/Profile/ProfileUpdate/ProfileUpdate';
import { HeadquartersCreate } from './features/Headquarters/HeadquartersCreate/HeadquartersCreate';
import { HeadquarterUpdate } from './features/Headquarters/HeadquarterUpdate/HeadquarterUpdate';
import { UpdateMatch } from './features/Matches/UpdateMatch/UpdateMatch';
import { HeadquarterAdminDetails } from './features/Headquarters/HeadquarterAdminDetails/HeadquarterAdminDetails';
import { Fields } from './features/Fields/Fields';
import { FieldsList } from './features/Fields/FieldsList/FieldsList';
import { FieldsCreate } from './features/Fields/FieldsCreate/FieldsCreate';
import { FieldsUpdate } from './features/Fields/FieldsUpdate/FieldsUpdate';
import { Receipts } from './features/Receipts/Receipts';
import { ReceiptsList } from './features/Receipts/ReceiptsList/ReceiptsList';
import { AuthExternalLogin } from './features/Auth/AuthExternalLogin/AuthExternalLogin';
import { AuthGuard } from './core/hooks/AuthGuard';
import { useAuth } from './core/hooks/useAuth';

export const AppRouter = () => {
    const {isAuth, isSuperAdmin} = useAuth();

    return (
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<App />}>
                    <Route index element={<Home />} />

                    <Route path='auth' element={<Auth />}>
                        <Route index element={<AuthSelection />} />
                        <Route path='login' element={<AuthLogin />} />
                        <Route path='registro' element={<AuthRegister />} />
                    </Route>

                    <Route path='facebook_response' element={<AuthExternalLogin />} />
                    <Route path='google_response' element={<AuthExternalLogin />} />

                    <Route path='partidos' element={<Matches />}>
                        <Route index element={<ListMatches />} />
                        <Route path='detalles/:id' element={<MatchDetails />} />

                        <Route element={<AuthGuard isAllowed={isAuth} />}>
                            <Route path='pagar/:id' element={<Payment />} />
                            <Route path='cancelar/:id' element={<Payment />} />
                            <Route path='invitar/:matchId' element={<ListFriends />}/>
                        </Route>

                        <Route element={<AuthGuard isAllowed={isAuth && isSuperAdmin} />}>
                            <Route path='crear' element={<CreateMatch />} />
                            <Route path='editar/:id' element={<UpdateMatch />} />
                        </Route>
                    </Route>

                    <Route path='sedes' element={<Headquarters />}>
                        <Route index element={<ListHeadquarters />} />
                        <Route path='detalles/:name' element={<HeadquartersDetails />} />

                        <Route element={<AuthGuard isAllowed={isAuth && isSuperAdmin} />}>
                            <Route path='crear' element={<HeadquartersCreate />} />
                            <Route path='editar/:name' element={<HeadquarterUpdate />} />
                            <Route path='info/:name' element={<HeadquarterAdminDetails />} />
                        </Route>
                    </Route>

                    <Route element={<AuthGuard isAllowed={isAuth && isSuperAdmin} />}>
                        <Route path='canchas' element={<Fields />}>
                            <Route path='sede/:locationId' element={<FieldsList />} />
                            <Route path='crear/:locationId' element={<FieldsCreate />} />
                            <Route path='editar/:id' element={<FieldsUpdate />} />
                        </Route>
                    </Route>

                    <Route element={<AuthGuard isAllowed={isAuth} />}>
                        <Route path='perfil' element={<Profile />}>
                            <Route index element={<ProfileDetails />} />
                            <Route path='editar' element={<ProfileUpdate />} />
                        </Route>

                        <Route path='mis-partidos' element={<MyMatches />}>
                            <Route index element={<ListMyMatches />} />
                        </Route>

                        <Route path='amigos' element={<Friends />}>
                            <Route index element={<ListFriends />} />
                        </Route>

                        <Route path='comprobantes' element={<Receipts />}>
                            <Route index element={<ReceiptsList />} />
                        </Route>
                    </Route>
                </Route>

                <Route path='*' element={<NotFound />} />
            </Routes>
        </BrowserRouter>
    );
};
