import { IconButton, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import styles from './Counter.module.scss';

export const Counter = ({ value = 0, label, onChange, readonly }) => {
    const handleAddClick = () => {
        if (!value) {
            onChange(1);
        } else {
            onChange(value + 1);
        }
    };

    const handleReduceClick = () => {
        if (!value) return;

        onChange(value - 1);
    };

    return (
        <div className={styles.counter}>
            <label>{label}</label>
            <div className={styles.counter__form}>
                <IconButton
                    className={styles.counter__button}
                    color='primary'
                    onClick={handleReduceClick}
                    disabled={!value || readonly}
                >
                    <RemoveIcon />
                </IconButton>
                <TextField
                    className={styles.counter__input}
                    type='number'
                    value={value}
                    sx={{
                        input: {
                            textAlign: 'center',
                        },
                    }}
                    InputProps={{
                        readOnly: readonly,
                    }}
                ></TextField>
                <IconButton
                    className={styles.counter__button}
                    color='primary'
                    onClick={handleAddClick}
                    disabled={readonly}
                >
                    <AddIcon />
                </IconButton>
            </div>
        </div>
    );
};
