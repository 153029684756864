import { Outlet } from "react-router-dom";
import { TopNavigation } from '../../shared/components/TopNavigation/TopNavigation';

import { Navigation } from "./../../shared/components/navigation/Navigation";

import styles from "./Matches.module.scss";

export const Matches = () => {
    return (
        <section className={styles.Matches}>
            <TopNavigation />
            <Outlet />
            <Navigation />
        </section>
    );
};
