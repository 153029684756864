import { createAsyncThunk } from '@reduxjs/toolkit';
import { getMyMatches } from '../../../services/matchesService';

export const getMyMatchesAsync = createAsyncThunk(
    'matches/getMyMatches',
    async (payload) => {
        const { pageSize, currentPage } = payload;
        const res = await getMyMatches(pageSize, currentPage);
        
        return await res.data;
    }
);
