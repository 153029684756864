import { createAsyncThunk } from '@reduxjs/toolkit';
import { getReceipts } from '../../../services/receiptsService';

export const getReceiptsAsync = createAsyncThunk(
    'receipts/getReceipts',
    async (payload) => {
        const { pageSize, currentPage, startDate, endDate } = payload;
        const res = await getReceipts(pageSize, currentPage, startDate, endDate);

        return await res.data;
    }
);
