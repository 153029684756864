import { createAsyncThunk } from "@reduxjs/toolkit";

import { getHeadQuartersDetails } from "../../../services/headQuartersService";

export const getHeadquartersDetailAsync = createAsyncThunk(
    "headQuarters/getHeadquartersDetails",
    async (name) => {
        const res = await getHeadQuartersDetails(name);
        const data = await res.data;

        return data;
    }
);
