import { createAsyncThunk } from '@reduxjs/toolkit';
import { updateUser } from '../../../services/userService';

export const updateUserAsyc = createAsyncThunk(
    'auth/updateUser',
    async (payload) => {
        const res = await updateUser(payload);

        return res.status;
    }
);
