import { useDispatch } from 'react-redux';
import { addFavoriteFriendAsync, removeFavoriteFriendAsync } from '../../../core/store/slices/friends/friendsAsync';
import { IconApp } from '../../../shared/components/IconApp/IconApp';
import styles from './Friend.module.scss';

export function Friend({ id, name, position, isActive, isFavorite, hideBookmark, handleClick }) {
    const dispatch = useDispatch();

    const handleFavClick = () => {
        if (isFavorite) {
            dispatch(removeFavoriteFriendAsync({ playerId: id }));
            return;
        }

        dispatch(addFavoriteFriendAsync({ playerId: id }));
    };

    const handleFriendClick = (event) => {
        handleClick(event, id);
    }

    return (
        <div className={`${styles.friend} ${ isActive ? styles.active : ''}`} onClick={handleFriendClick}>
            <IconApp
                className={styles.avatar}
                width={30}
                height={30}
                iconName='player_team_1'
            />

            <span className={styles.name}>
                {name} - {position}
            </span>

            {!hideBookmark && isFavorite && (
                <button className={styles.favButton} onClick={handleFavClick}>
                    <IconApp
                        width={12}
                        height={15}
                        iconName='bookmark_filled'
                    />
                </button>
            )}

            {!hideBookmark && !isFavorite && (
                <button className={styles.favButton} onClick={handleFavClick}>
                    <IconApp width={12} height={15} iconName='bookmark' />
                </button>
            )}
        </div>
    );
}
