import { axiosClient } from "./axiosInstance";

export const getHeadQuarters = () => {
    const url = `/Locations/GetAllLocations`;

    return axiosClient.get(url);
};

export const getHeadQuartersDetails = (name) => {
    const url = `/Locations/GetLocationByName?locationName=${name}`;

    return axiosClient.get(url);
};

export const createHeadquarter = (data) => {
    const url = '/Locations/CreateLocation';

    return axiosClient.post(url, data);
};

export const updateHeadquarter = (data) => {
    const url = '/Locations/UpdateLocation';

    return axiosClient.put(url, data);
};

export const uploadImages = (id, images) => {
    const url = `/Locations/UploadLocationImagesToContainer?locationId=${id}`;
    const formData = new FormData();

    images.forEach(image => {
        formData.append('imagesPath', image);
    });

    return axiosClient.post(url, formData);
};

export const removeImages = (images) => {
    const url = '/Locations/DeleteImageToLocation';

    return axiosClient.delete(url, { data: images });
}
