import { createAsyncThunk } from "@reduxjs/toolkit";
import { getHeadQuarters } from "../../../services/headQuartersService";

export const getHeadquartersAsync = createAsyncThunk(
    "headQuarters/getHeadquarters",
    async () => {
        // if (players.player) {
        //     return players.player;
        // }
        const res = await getHeadQuarters();
        const data = await res.data;

        return data;
    }
);
