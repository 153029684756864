import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { getUserSelector } from "../store/slices/auth/selectors";

export const useIsAuth = () => {
    const user = useSelector(getUserSelector);
    const [isAuth, setIsAuth] = useState(!!user);

    useEffect(() => {
        setIsAuth(!!user);
    }, [user]);

    return { isAuth };
};
