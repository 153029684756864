import styles from './Loader.module.scss';
import globals from '../../constants/global.constant';

export const Loader = ({size}) => {
    return (
        <div className={styles.loader}>
            <img
                src={`${globals.logosBasePath}/partidosuntoque_color.svg`}
                alt='Un Toque Logo'
                className={`${styles.logoImage} ${size === 'small' ? styles.logoImageSmall : ''}`}
            ></img>
        </div>
    );
}
