import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    removeImages,
    uploadImages,
} from '../../../services/headQuartersService';

export const uploadHeadquarterImagesAsync = createAsyncThunk(
    'headquarter/uploadImages',
    async (payload) => {
        const { locationId, images } = payload;
        const res = await uploadImages(locationId, images);

        return res.status;
    }
);

export const removeHeadquarterImagesAsync = createAsyncThunk(
    'headquarter/removeImages',
    async (payload) => {
        const { images } = payload;
        const res = await removeImages(images);

        return res.status;
    }
);
