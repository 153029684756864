import { createSlice } from "@reduxjs/toolkit";

import { initialState } from "./initialState";

export const uiSlice = createSlice({
    name: "ui",
    initialState,
    reducers: {
        changeBackground: (state, action) => {
            state.currentBackground = action.payload;
        },
        changeToqueMobileDisplayed: (state, action) => {
            state.isToqueMobileDisplayed = action.payload;
        },
    },
});

export const { changeToqueMobileDisplayed, changeBackground } = uiSlice.actions;

export default uiSlice.reducer;
