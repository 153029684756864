import { createSlice } from '@reduxjs/toolkit';
import { approveReceiptAsync } from './approveReceiptAsync';
import { getReceiptsAsync } from './getReceiptsAsync';
import { initialState } from './initialState';
import { rejectReceiptAsync } from './rejectReceiptAsync';
import { uploadReceiptAsync } from './uploadReceiptAsync';

export const receiptsSlice = createSlice({
    name: 'receipts',
    initialState: initialState,
    reducers: {
        resetUploadStatus: (state) => {
            state.uploadStatus = null;
        },
        resetValidateStatus: (state) => {
            state.validateStatus = null;
        }
    },
    extraReducers: {
        // get receipts
        [getReceiptsAsync.pending]: (state) => {
            state.isLoading = true;
        },
        [getReceiptsAsync.fulfilled]: (state, action) => {
            if (action.payload.currentPage === 1) {
                state.allReceipts = action.payload.items;
            } else {
                state.allReceipts = [
                    ...state.allReceipts,
                    ...action.payload.items,
                ];
            }
            state.totalReceipts = action.payload.totalItems;
            state.isLoading = false;
        },
        [getReceiptsAsync.rejected]: (state) => {
            state.isLoading = false;
        },
        // upload receipt
        [uploadReceiptAsync.pending]: (state) => {
            state.isLoading = true;
        },
        [uploadReceiptAsync.fulfilled]: (state, action) => {
            state.uploadStatus = action.payload;
            state.isLoading = false;
        },
        [uploadReceiptAsync.rejected]: (state) => {
            state.isLoading = false;
        },
        // approve receipt
        [approveReceiptAsync.pending]: (state) => {
            state.isLoading = true;
        },
        [approveReceiptAsync.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.validateStatus = action.payload;
        },
        [approveReceiptAsync.rejected]: (state, action) => {
            state.isLoading = false;
            state.validateStatus = action.payload;
        },
        // reject receipt
        [rejectReceiptAsync.pending]: (state) => {
            state.isLoading = true;
        },
        [rejectReceiptAsync.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.validateStatus = action.payload;
        },
        [rejectReceiptAsync.rejected]: (state, action) => {
            state.isLoading = false;
            state.validateStatus = action.payload;
        },
    },
});

export const { resetUploadStatus, resetValidateStatus } = receiptsSlice.actions;
export default receiptsSlice.reducer;
