import { BackButton } from "../BackButton/BackButton";
import globals from "../../constants/global.constant";
import styles from "./InfoModal.module.scss";

export const InfoModal = ({ title, description, closeModal }) => {
    const bg = `url(${globals.imageBasePath}/bg3-mobile.png)`;

    return (
        <section
            className={styles.infoModal}
            style={{
                "--bgModalMobile": bg,
            }}
        >
            <section className={styles.container}>
                <div className={styles.backIcon}>
                    <BackButton onClick={closeModal} />
                </div>

                <h1 className={styles.title}>{title}</h1>

                <p className={styles.description}>{description}</p>
            </section>
        </section>
    );
};
