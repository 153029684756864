import { createAsyncThunk } from '@reduxjs/toolkit';
import { updateField } from '../../../services/fieldService';

export const updateFieldAsync = createAsyncThunk(
    'fields/update',
    async (payload) => {
        try {
            const res = await updateField(payload);
            const data = await res.data;
    
            return {
                status: res.status,
                data
            };
        } catch (error) {
            const res = error.response;

            return {
                status: res.status,
                message: res.data
            };
        }
    }
);
