import { Outlet } from "react-router-dom";
import { TopNavigation } from '../../shared/components/TopNavigation/TopNavigation';

import { Navigation } from "./../../shared/components/navigation/Navigation";
import styles from "./Friends.module.scss";

export const Friends = () => {
    return (
        <section className={styles.Friends}>
            <TopNavigation />
            <Outlet />
            <Navigation />
        </section>
    );
};
