import { useNavigate } from "react-router-dom";

import { BackButton } from "../BackButton/BackButton";

import styles from "./PagesHeader.module.scss";

export const PagesHeader = (props) => {
    const navigate = useNavigate();
    const back = props.navigateTo ? props.navigateTo : -1;

    return (
        <header className={styles.PagesHeader}>
            <div className={styles.backIcon}>
                <BackButton onClick={() => navigate(back)} />
            </div>
            <div className={styles.element}>{props.children}</div>
            <div className={styles.element}></div>
        </header>
    );
};
