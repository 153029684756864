import { createAsyncThunk } from '@reduxjs/toolkit';
import { updateHeadquarter } from '../../../services/headQuartersService';

export const updateHeadquarterAsync = createAsyncThunk(
    'headquarters/update',
    async (payload) => {
        try {
            const res = await updateHeadquarter(payload);
            const data = await res.data;
    
            return {
                status: res.status,
                data
            };
        } catch (error) {
            const res = error.response;

            return {
                status: res.status,
                message: res.data
            };
        }
    }
);