import appConstants from '../../../../shared/constants/global.constant';

export const getIsLoadingRegSettings = (state) => state?.auth?.isLoadingRegSettings;
export const getIsLoginInSelector = (state) => state?.auth?.isLoginIn;
export const getIsRegistering = (state) => state?.auth?.isRegistering;
export const getRegisterStatusSelector = (state) => state?.auth?.registerStatus;
export const getIsUpdating= (state) => state?.auth?.isUpdating;
export const getUpdateStatusSelector = (state) => state?.auth?.updateStatus;
export const getSettingsSelector = (state) => state?.auth?.settings;
export const getUserSelector = (state) => state?.auth?.user;
export const isSuperAdminSelector = (state) => state?.auth?.user?.roleName === appConstants.roles.superAdmin;
export const loginStatusSelector = (state) => state?.auth?.loginStatus;
export const externalLoginDataSelector = (state) => state?.auth?.externalLoginData;
export const isLoadingSelector = (state) => state?.auth?.isLoadingLogin;
export const isTokenExpiredSelector = (state) => state?.auth?.isTokenExpired;
