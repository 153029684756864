import { createAsyncThunk } from '@reduxjs/toolkit';
import { createHeadquarter } from '../../../services/headQuartersService';

export const createHeadquarterAsync = createAsyncThunk(
    'headquarters/create',
    async (payload) => {
        try {
            const res = await createHeadquarter(payload);
            const data = await res.data;
    
            return {
                status: res.status,
                data
            };
        } catch (error) {
            const res = error.response;

            return {
                status: res.status,
                message: res.data
            };
        }
    }
);
