import { Dialog } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import {
    facebookLoginAsync,
    googleLoginAsync,
} from '../../../core/store/slices/auth/loginAsync';
import { loginStatusSelector } from '../../../core/store/slices/auth/selectors';
import { InfoModal } from '../../../shared/components/InfoModal/InfoModal';
import { Loader } from '../../../shared/components/loader/Loader';
import styles from './AuthExternalLogin.module.scss';
import globals from '../../../shared/constants/global.constant';

export const AuthExternalLogin = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const [isModalOpen, setModalOpen] = useState(false);

    const loginStatus = useSelector(loginStatusSelector);

    useEffect(() => {
        if (location && location.pathname === globals.facebookRedirect) {
            const params = new URLSearchParams(location.hash.replace('#', ''));
            const token = params.get('access_token');

            if (token) {
                dispatch(facebookLoginAsync(token));
            } else {
                navigate('/auth');
            }
        }
        if (location && location.pathname === globals.googleRedirect) {
            const params = new URLSearchParams(location.search);
            const code = params.get('code');

            if (code) {
                dispatch(
                    googleLoginAsync({
                        code: encodeURIComponent(code),
                        redirectUrl: `${window.location.origin}${globals.googleRedirect}`,
                    })
                );
            } else {
                navigate('/auth');
            }
        }
    }, [dispatch, navigate, location]);

    useEffect(() => {
        if (loginStatus === 200) {
            navigate('/');
        }

        if (loginStatus !== null && loginStatus !== 200) {
            setModalOpen(true);
        }
    }, [navigate, loginStatus]);

    const handleModalClose = () => {
        navigate('/auth');
    };

    return (
        <section className={styles.AuthExternalLogin}>
            <Loader />

            <Dialog fullScreen open={isModalOpen} onClose={handleModalClose}>
                <InfoModal
                    title='Oh no!'
                    description='Hubo un error, por favor intenta mas tarde'
                    closeModal={handleModalClose}
                />
            </Dialog>
        </section>
    );
};
