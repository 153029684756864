import { createAsyncThunk } from "@reduxjs/toolkit";

import { getPlayer } from "../../../services/playersService";

export const getPlayerAsync = createAsyncThunk(
    "players/getPlayer",
    async (payload, { getState }) => {
        const { auth, players } = getState();

        if (players.player) {
            return players.player;
        }

        const res = await getPlayer(auth?.user?.userId);
        const data = await res.data;

        return data;
    }
);
