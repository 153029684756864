import { axiosClient } from './axiosInstance';

export const getReceipts = (pageSize, currentPage, startDate, endDate) => {
    const url = `/Receipts/GetReceipts`;
    const params = getParams({ pageSize, currentPage, startDate, endDate });

    return axiosClient.get(url, { params });
};

export const uploadReceipt = (matchId, locationId, dateReceipt, file) => {
    const url = `/Receipts/UploadReceipt?matchId=${matchId}&locationId=${locationId}&dateReceipt=${dateReceipt}`;
    const formData = new FormData();

    formData.append('imagesFile', file);

    return axiosClient.post(url, formData);
};

export const approveReceipt = (receiptId) => {
    const url = `/Receipts/ApproveRecipt?reciptId=${receiptId}`;

    return axiosClient.post(url);
}

export const rejectReceipt = (receiptId) => {
    const url = `/Receipts/RejectRecipt?reciptId=${receiptId}`;

    return axiosClient.post(url);
}

function getParams({ pageSize, currentPage, startDate, endDate }) {
    const params = {};

    if (pageSize !== undefined) {
        params.pageSize = pageSize;
    }

    if (currentPage !== undefined) {
        params.curPage = currentPage;
    }

    if (startDate !== undefined) {
        params.startDate = startDate;
    }

    if (endDate !== undefined) {
        params.endDate = endDate;
    }

    return params;
}
