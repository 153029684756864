import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { getHeadquartersDetailAsync } from '../../../core/store/slices/headquarters/getHeadquartersDetailAsync';
import {
    currentHeadquarterSelector,
    isLoadingSelector,
} from '../../../core/store/slices/headquarters/selectors';
import { getMatchesAsync } from '../../../core/store/slices/matches/getMatchesAsync';
import {
    matchesSelector,
    totalMatchesSelector,
} from '../../../core/store/slices/matches/selectors';

import { IconApp } from '../../../shared/components/IconApp/IconApp';
import { PagesHeader } from '../../../shared/components/PagesHeader/PagesHeader';
import Match from '../../Matches/Match/Match';

import styles from './HeadquartersDetails.module.scss';
import InfiniteScroll from 'react-infinite-scroll-component';
import { isSuperAdminSelector } from '../../../core/store/slices/auth/selectors';
import { Button } from '@mui/material';
import { currencyFormat } from '../../../shared/utils/utils';
import { Loader } from '../../../shared/components/loader/Loader';

export const HeadquartersDetails = () => {
    const dispatch = useDispatch();
    const { state } = useLocation();
    const isLoading = useSelector(isLoadingSelector);
    const { name } = useParams();
    const currentHeadquarter = useSelector(currentHeadquarterSelector);
    const matches = useSelector(matchesSelector);
    const totalMatches = useSelector(totalMatchesSelector);
    const [locationId, setLocationId] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize] = useState(5);
    const isSuperAdmin = useSelector(isSuperAdminSelector);
    const navigate = useNavigate();

    useEffect(() => {
        const { id } = state;

        setLocationId(id);
        dispatch(getMatchesAsync({ locationId: id, pageSize, currentPage }));
    }, [dispatch, state, currentPage, pageSize]);

    useEffect(() => {
        dispatch(getHeadquartersDetailAsync(name));
    }, [dispatch, name]);

    const handleLoadMore = () => {
        dispatch(
            getMatchesAsync({
                locationId,
                pageSize,
                currentPage: currentPage + 1,
            })
        );
        setCurrentPage(currentPage + 1);
    };

    const handleEditClick = () => {
        navigate(`/sedes/info/${name}`);
    };

    return isLoading ? (
        <Loader></Loader>
    ) : (
        <section className={styles.HeadquartersDetails}>
            <PagesHeader>
                <h1>INSTALACIONES</h1>
            </PagesHeader>

            <section className={styles.container}>
                <h2>{currentHeadquarter?.name}</h2>

                <div className={styles.address}>
                    <IconApp
                        width={20}
                        height={20}
                        iconName='location'
                        alt='Logo de un toque'
                    />

                    <a
                        href={currentHeadquarter?.address}
                        className={styles.addressLink}
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        {currentHeadquarter?.address}
                    </a>
                </div>

                <div className={styles.details}>
                    <span>
                        Parqueadero:{' '}
                        {currentHeadquarter?.hasParking
                            ? currencyFormat(currentHeadquarter?.parkingCost)
                            : 'No'}
                    </span>

                    <span>
                        Camerinos:{' '}
                        {currentHeadquarter?.hasChangingRooms ? 'Sí' : 'No'}
                    </span>

                    <span>
                        Tienda: {currentHeadquarter?.hasStore ? 'Sí' : 'No'}
                    </span>

                    {isSuperAdmin && (
                        <span>
                            Descuento:{' '}
                            {currentHeadquarter?.hasDiscount
                                ? currentHeadquarter?.discount + '%'
                                : 'No'}
                        </span>
                    )}
                </div>

                <div
                    id='headquartersDetails'
                    className={`${styles.matches} ${
                        isSuperAdmin ? styles.matchesAdmin : ''
                    }`}
                >
                    {currentHeadquarter?.images &&
                    currentHeadquarter?.images?.length ? (
                        <>
                            <section
                                className={`${styles.imagesWrapper} scrollable`}
                            >
                                <div className={styles.images}>
                                    {currentHeadquarter?.images.map(
                                        (image, index) => (
                                            <div
                                                key={index}
                                                className={
                                                    styles.imageContainer
                                                }
                                            >
                                                <img
                                                    src={image}
                                                    alt='Imagen de cancha'
                                                />
                                            </div>
                                        )
                                    )}
                                </div>
                            </section>
                        </>
                    ) : (
                        <p className={styles.noImages}>
                            No Hay imagenes para esta instalacion
                        </p>
                    )}

                    <div className={styles.infitineScrollWrapper}>
                        <InfiniteScroll
                            dataLength={matches.length}
                            next={handleLoadMore}
                            hasMore={matches.length < totalMatches}
                            scrollableTarget='headquartersDetails'
                            loader={
                                <div className={styles.loadingSmall}>
                                    <Loader size='small' />
                                </div>
                            }
                        >
                            {isLoading && !matches.length && (
                                <div className={styles.loading}>
                                    <Loader />
                                </div>
                            )}

                            {!isLoading && !matches.length && (
                                <div className={styles.emptyData}>
                                    <h3>No partidos en esta instalación</h3>
                                </div>
                            )}

                            {matches.map((match, index) => (
                                <Match
                                    key={`${match.matchId}/${index}`}
                                    data={match}
                                ></Match>
                            ))}
                        </InfiniteScroll>
                    </div>
                </div>

                {isSuperAdmin && (
                    <Button
                        variant='contained'
                        onClick={handleEditClick}
                        fullWidth
                        className={styles.editButton}
                    >
                        Más detalles
                    </Button>
                )}
            </section>
        </section>
    );
};
