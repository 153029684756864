import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAllMatches } from '../../../services/matchesService';

export const getMatchesAsync = createAsyncThunk(
    'matches/getMatches',
    async (payload) => {
        const { genderId, matchTypeId, pageSize, currentPage, locationId, onlyWithoutResult } =
            payload || {};
        const res = await getAllMatches(
            genderId,
            matchTypeId,
            pageSize,
            currentPage,
            locationId,
            onlyWithoutResult
        );

        return await res.data;
    }
);
