import { axiosClient } from "./axiosInstance";

export const getFriends = (name, pageSize, currentPage) => {
    const url = `/Players/GetFriends`;
    const params = getParams(name, pageSize, currentPage);

    return axiosClient.get(url, { params });
}

export const addFavoriteFriend = (playerId) => {
    const url = `/FavoriteFriends/AddToFavoriteFriend?playerId=${playerId}`;

    return axiosClient.post(url);
};

export const removeFavoriteFriend = (playerId) => {
    const url = `/FavoriteFriends/RemoveToFavoriteFriend?playerId=${playerId}`;

    return axiosClient.delete(url);
};

function getParams(name, pageSize, currentPage) {
    const params = {};

    if (name !== undefined) {
        params.name = name;
    }

    if (pageSize !== undefined) {
        params.pageSize = pageSize;
    }

    if (currentPage !== undefined) {
        params.curPage = currentPage;
    }

    return params;
}