import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

export const CancelMatchDialog = ({isOpen, onClose, onCancel}) => {
    const handleClose = () => {
        if (onClose) onClose();
    };
    const handleCancel = () => {
        if (onCancel) onCancel();
    };

    return (
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>Cancelar Partido</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Esta seguro de cancelar el partido?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cerrar</Button>
                <Button
                    onClick={handleCancel}
                    autoFocus
                >
                    Cancelar
                </Button>
            </DialogActions>
        </Dialog>
    );
};
