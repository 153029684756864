export const initialState = {
    isLoading: false,
    allFields: [],
    fieldsList: [],
    totalFields: null,
    currentField: null,
    createStatus: null,
    updateStatus: null,
    deleteStatus: null
};
