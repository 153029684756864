import { Button } from '@mui/material';
import { forwardRef } from 'react';
import styles from './ImageUpload.module.scss';

export const ImageUpload = forwardRef(
    ({ handleImagesChange, className, children }, ref) => {
        const onFileChange = (event) => {
            if (event.target.files && event.target.files.length) {
                handleImagesChange(Array.from(event.target.files));
            }
        };

        return (
            <div className={`${styles.ImageUpload} ${className}`}>
                <label htmlFor='contained-button-file' className={styles.label}>
                    <input
                        ref={ref}
                        className={styles.input}
                        accept='image/*'
                        id='contained-button-file'
                        multiple
                        type='file'
                        onChange={onFileChange}
                    />

                    <Button variant='contained' component='span' fullWidth>
                        {children}
                    </Button>
                </label>
            </div>
        );
    }
);
