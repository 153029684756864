import { createAsyncThunk } from '@reduxjs/toolkit';
import { getFieldById } from '../../../services/fieldService';

export const getFieldByIdAsync = createAsyncThunk('fields/getFieldById', async (payload) => {
    const { fieldId } = payload;
    const res = await getFieldById(fieldId);
    const data = await res.data;

    return data;
});
